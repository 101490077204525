import reducer, { initialState } from './page.reducer'
import types from './page.types'

import * as sagas from './page.sagas'
import * as actions from './page.actions'
import * as utils from './page.utils'
import * as selectors from './page.selectors'

export const page = {
  reducer,
  initialState,
  sagas,
  types,
  selectors,
  utils,
  actions,
}
