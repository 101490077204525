import { useEffect } from 'react'
import flatPageStyles from './flat.page.module.scss'
import Content from '../../components/content/content.component'
import { Helmet } from 'react-helmet-async'
import { connect } from 'react-redux'
import { createStructuredSelector } from 'reselect'
import { actions, selectors } from '../../state/modules'
import Loading from '../../components/loading/loading.component'

const FlatPage = ({
  pageName = 'home',
  pages,
  title,
  loadPage,
  loadingPage,
  style = {},
}) => {
  useEffect(() => {
    loadPage(pageName)
  }, [pageName])

  const data = pages[pageName]

  return (
    <>
      <Helmet>
        <meta name='robots' content='noindex' />
      </Helmet>
      <Content>
        {loadingPage ? (
          <Loading height='100vh' small showLogo />
        ) : (
          <div className={flatPageStyles.wrapper} style={{ ...style }}>
            {data && (
              <div
                className={flatPageStyles.content}
                dangerouslySetInnerHTML={{ __html: data.Content }}
              />
            )}
          </div>
        )}
      </Content>
    </>
  )
}

const mapStateToProps = createStructuredSelector({
  pages: selectors.page.selectPages,
  loadingPage: selectors.page.selectPageLoading,
})

const mapDispatchToProps = dispatch => ({
  loadPage: name => dispatch(actions.page.setPage(name)),
})
export default connect(mapStateToProps, mapDispatchToProps)(FlatPage)
