function removeUnSelected(array) {
  const result = []
  let pos = 0
  let elemPos = 0

  array.forEach(elem => {
    if (Array.isArray(elem.options)) {
      result.push({
        name: elem.name,
        options: [],
      })
      elem?.options.forEach(option => {
        if (option.isSelected) {
          result[pos]?.options.push({ value: option.value, children: [] })

          option?.children?.forEach(child => {
            if (child?.isSelected) {
              result[pos]?.options[elemPos]?.children?.push({
                value: child.value,
              })
            }
          })
          ++elemPos
        }
      })
      ++pos
    }
  })
  return result
}

function getSelectedFilters(filters) {
  const selected = []

  if (Array.isArray(filters)) {
    for (let filter of filters) {
      const selection = filter.options.filter(opt => opt.isSelected)
      if (selection.length > 0) selected.push(...selection)
    }
  }
  return selected
}
export { removeUnSelected, getSelectedFilters }
