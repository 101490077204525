import { BrowserRouter as Router, Switch, Route, Redirect } from 'react-router-dom'
import './root-style'
import GeneralContext from './contexts'
import Artworks from './pages/artworks-page/artworks.page.component'
import Artwork from './pages/artwork-page/artwork.page.component'
import Wrapper from './components/wrapper/wrapper.component'
import MainMenu from './components/main-menu/main-menu.component'
import HomePage from './pages/home-page/home-page.component'
import FlatPage from './pages/flat-page/flat.page.component'

export default function App() {
  return (
    <GeneralContext>
      <Router>
        <Wrapper>
          <Switch>
            <Route exact path='/' />
            <Route path='/journey' />
            <Route>
              <MainMenu mainLogo />
            </Route>
          </Switch>

          <Switch>
            <Route exact path='/'>
              <HomePage />
            </Route>

            <Route exact path='/artworks'>
              <Artworks />
            </Route>

            <Route path='/journey'>
              <MainMenu mainLogo alwaysSolid  = {window.innerWidth < 965} />
              <Artwork />
            </Route>

            <Route exact path='/about'>
              <FlatPage pageName='about' />
            </Route>

            <Route exact path='/partners'>
              <FlatPage
                pageName='partners'
                style={{ alignItems: 'flex-start', marginTop: '3rem' }}
              />
            </Route>

            <Route exact path='/textiles'>
              <FlatPage pageName='textiles' />
            </Route>
            <Route path='*'>
              <Redirect to='/'></Redirect>
            </Route>
          </Switch>
        </Wrapper>
      </Router>
    </GeneralContext>
  )
}
