import { createSelector } from 'reselect'
import { NAME } from './journey.types'

export const selectJourney = state => state[NAME.toLowerCase()]

export const selectJourneyLoading = createSelector(
  [selectJourney],
  journey => journey.isLoading
)

export const selectJourneyList = createSelector(
  [selectJourney],
  journey => journey.list
)
