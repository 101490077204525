const tabElementsSelector: string = 'a, button, [tabindex]:not([tabindex="-1"])';

export class TabArea {
    private backgroudElement: HTMLElement | null = null;
    private firstElement: HTMLElement;
    private lastElement: HTMLElement;

    constructor(focusedAreaElement: HTMLElement, backgroudElement?: HTMLElement) {
        const focusableElements = focusedAreaElement.querySelectorAll<HTMLElement>(tabElementsSelector);

        if (focusableElements.length === 0) {
            throw 'TabGroup: No focusable elements';
        }

        this.backgroudElement = backgroudElement || document.activeElement as HTMLElement || document.querySelectorAll<HTMLElement>(tabElementsSelector)[0] || null;
        this.firstElement = focusableElements[0];
        this.lastElement = focusableElements[focusableElements.length - 1];

        this.firstElementKeyboardEventHandler = this.firstElementKeyboardEventHandler.bind(this);
        this.lastElementKeyboardEventHandler = this.lastElementKeyboardEventHandler.bind(this);

        this.init();
    }

    private init() {
        this.firstElement.focus();
        this.firstElement.addEventListener('keydown', this.firstElementKeyboardEventHandler);
        this.lastElement.addEventListener('keydown', this.lastElementKeyboardEventHandler);
    }

    private firstElementKeyboardEventHandler(event: KeyboardEvent) {
        if (event.key === 'Tab' && event.shiftKey === true) {
            event.preventDefault();
            event.stopPropagation();

            if (this.onGoBack) {
                this.onGoBack(this.firstElement, this.lastElement);
            }
            else {
                this.lastElement.focus();
            }
        }
    }

    private lastElementKeyboardEventHandler(event: KeyboardEvent) {
        if (event.key === 'Tab' && event.shiftKey === false) {
            event.preventDefault();
            event.stopPropagation();
            
            if (this.onGoNext) {
                this.onGoNext(this.firstElement, this.lastElement);
            }
            else {
                this.firstElement.focus();
            }
        }
    }

    public onGoBack: ((firstElement: HTMLElement, lastElement: HTMLElement) => void) | null = null;
    public onGoNext: ((firstElement: HTMLElement, lastElement: HTMLElement) => void) | null = null;

    public restore() {
        this.firstElement.removeEventListener('keydown', this.firstElementKeyboardEventHandler);
        this.lastElement.removeEventListener('keydown', this.lastElementKeyboardEventHandler);
        if (this.backgroudElement) {
            this.backgroudElement.focus();
        }
    }
}
