import * as THREE from 'three'

const urlImageApi =
  'https://res.cloudinary.com/map-cloud/image/fetch/w_60,c_thumb,e_sharpen,dpr_3.0/'

const names = [
  'Zardosi',
  'Europe',
  'North America',
  '1951 -1987',
  'South Africa',
  'New Zealand',
  '1768-1876',
  'Asia',
  'North Europe',
  '1951 -1999',
  'South America',
  'Zuriyam',
  'France',
  'Spain',
  'America',
  '1941 -1997',
  'Central America',
  'India',
]

function removeDuplicates(array) {
  const result = []
  for (let i = 0; i < array.length; i++) {
    let exists = false
    for (let j = 0; j < result.length; j++) {
      if (array[i]?.id === result[j]?.id) {
        exists = true
        break
      }
    }
    if (!exists) {
      result.push(array[i])
    }
  }
  return result
}
function getAspectRatio(width, height, lim) {
  const val = width / height
  let lower = [0, 1]
  let upper = [1, 0]

  while (true) {
    const mediant = [lower[0] + upper[0], lower[1] + upper[1]]

    if (val * mediant[1] > mediant[0]) {
      if (lim < mediant[1]) {
        return upper
      }
      lower = mediant
    } else if (val * mediant[1] === mediant[0]) {
      if (lim >= mediant[1]) {
        return mediant
      }
      if (lower[1] < upper[1]) {
        return lower
      }
      return upper
    } else {
      if (lim < mediant[1]) {
        return lower
      }
      upper = mediant
    }
  }
}

function standarized(str) {
  if (str) {
    str = str.replace(/,/g, '')
    str = str.replace(/ /g, '')
    str = str.replace(/:/g, '')
    return str.toString().trim().toUpperCase()
  }
  return ''
}

function groupProperties(list, current, filters) {
  /*
   * Initial data
   */
  const properties = [
    //{ key: 'filter_geographic_region', groups: [] },
    { key: 'filter_occasions_functions', groups: [] },
    { key: 'filter_medium_process', groups: [] },
    { key: 'filter_classification_types', groups: [] },
    { key: 'filter_color_tone', groups: [] },
    { key: 'filter_geographic_subregion', groups: [] },
    { key: 'filter_patterns_motifs', groups: [] },
    { key: 'medium', groups: [] },
    { key: 'accent_color_space', groups: [] },
    { key: 'origin', groups: [] },
    {
      key: 'museum_id',
      groups: [
        //{ tag:'Silk damask', count:1, words:['SILK','DAMASK'], items:[]}
      ],
    },
    { key: 'dominant_foreground_color', groups: [] },
    { key: 'custom_vision_categories', groups: [] },
    { key: 'object_type', groups: [] },
  ]

  //for (let prop of properties) {
  //for (let filter of filters) {
  //}
  //}

  list = list.filter(elem => elem.id !== current.id)
  let total = 0
  const parents = []

  /*
   * First grouping
   */

  list.forEach(ele => {
    Object.keys(ele).forEach(key => {
      properties.forEach((property, index) => {
        if (key === property.key) {
          const propIndex = properties[index].groups.findIndex(g => {
            const tag = g.tag?.toLowerCase()
            const prop = ele[property.key]?.toLowerCase()
            return tag === prop || tag?.includes(prop)
          })
          let words = ele && property.key && ele[property.key]?.split(' ')
          words = words?.map(word => standarized(word))

          if (propIndex === -1) {
            properties[index].groups.push({
              tag: ele[property.key],
              words,
              items: [{ ...ele }],
              count: 1,
            })
            // validate the first selected is different
          } else if (filters[0]?.value !== ele[property.key]) {
            properties[index].groups[propIndex].count =
              properties[index].groups[propIndex].count + 1

            const itemIndex = properties[index].groups[
              propIndex
            ].items.findIndex(item => item.id === ele.id)

            if (itemIndex === -1) {
              properties[index].groups[propIndex].items.push(ele)
            }
          }
        }
      })
    })
  })

  /*
   * First sort data
   */
  properties.forEach((property, index) => {
    properties[index].groups = property.groups
      .sort((a, b) => b.count - a.count)
      .filter(ele => ele.tag)
    /*
     * Push data for all nodes
     */
    properties[index].groups.forEach((ele, key) => {
      if (ele.count >= 2) {
        //if (ele.count > 2) {
        const id = `${
          Number(current.id) * (Math.floor(Math.random() + 1) * ele.count)
        }`
        const items = ele.items.map(item => ({
          ...item,
          target: id,
        }))

        parents.push({
          key: properties[index].key,
          tag: ele.tag,
          id,
          count: ele.count,
          isParent: true,
          target: current.id,
          items,
        })
        parents.push(...items)
      }
      total += ele.count
    })
  })

  let top = removeDuplicates(parents)
  //let top = parents
  const targets = []
  const counts = []
  const withOutData = []

  /*
   * Added targets
   */
  top.forEach(ele => {
    top.forEach(ele2 => {
      if (ele.id === ele2.target && ele2.target !== current.id) {
        const index = targets.findIndex(target => target === ele2.target)
        if (index === -1) targets.push(ele2.target)
      }
    })
  })

  /*
   * Added counts of filtered data
   */
  targets.forEach(target => {
    const data = top.filter(elem => target === elem.target)
    counts.push({ target, length: data.length, data })
  })

  /*
   * Review all new parents (Active parents)
   */
  top.forEach((t, i) => {
    if (t.isParent) withOutData.push({ item: t, id: t.id, index: i })
  })

  /*
   * Splice parents with out data
   */
  counts.forEach(count => {
    const index = withOutData.findIndex(p => p.id === count.target)
    withOutData.splice(index, 1)
  })

  /*
   * Splice parents with no chils on top data
   */
  withOutData.forEach(data => {
    const index = top.findIndex(t => t.id === data.id)
    top.splice(index, 1)
  })

  return {
    properties,
    selected: { data: [current, ...top], counts, withOutData },
    total,
  }
}

const loadTexture = function (url, fn = () => {}) {
  const loader = new THREE.TextureLoader()

  return new Promise(function (resolve, reject) {
    const onDone = function (texture) {
      resolve(texture)
    }
    const onError = function (err) {
      reject(err)
    }
    loader.load(url, onDone, fn, onError)
  })
}

function debounce(fn, ms) {
  let timer
  return () => {
    clearTimeout(timer)
    timer = setTimeout(() => {
      timer = null
      fn.apply(this, arguments)
    }, ms)
  }
}

export {
  names,
  removeDuplicates,
  getAspectRatio,
  urlImageApi,
  groupProperties,
  loadTexture,
  debounce,
}
