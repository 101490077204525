import API from './api.config'

/**
 * POST
 **/

export default class API_POST extends API {
  constructor(baseURL = process.env.URL_API) {
    super(baseURL)
  }

  getRelatedArtworks(id = '141565&top=100', body = []) {
    return this.api.post(`/related/?id=${id}`, body)
  }

  getArtworks(body = [], top = 100, skip = 0) {
    return this.api.post(`/search?top=${top}&skip=${skip}`, body)
  }

  saveJourney(params) {
    return this.api.post('/curated-journeys', params)
  }
}
