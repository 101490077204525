import Slider from 'react-slick'
import { line } from '../../assets'
import Control from '../control/control.component'
import inmersiveViewStyles from './inmersiveView.style.module.scss'
import { rawData, replaceImageName } from './inmersiveView.utils'
import { forwardRef, useState, memo } from 'react'
import Card from '../card/card.component'
import './inmersiveView.style.css'

import SimpleBar from 'simplebar-react'
import 'simplebar/dist/simplebar.min.css'

const InmersiveView = forwardRef(
  (
    {
      onChangeIndex,
      currentJourney = rawData,
      className = '',
      onClickCard = () => {},
      showSlider = true,
      loading,
      settings = {
        fade: true,
        centerMode: true,
        centerPadding: '15%',
        dots: false,
        infinite: true,
        arrows: false,
        swipe: true,
        speed: 500,
        slidesToShow: 1,
        className: inmersiveViewStyles.slider,
        draggable: false,
        responsive: [
          {
            breakpoint: 1024,
            settings: {
              centerPadding: '20px',
            }
          }
        ]
      }
    },
    ref
  ) => {
    const artworks = currentJourney?.Artworks || []
    const [index, setIndex] = useState(0)
    const prevArrow = document.querySelector('.prevArrow')
    const nextArrow = document.querySelector('.nextArrow')

    if (prevArrow && index === 0) {
      prevArrow.style.display = 'none'
    } else if (prevArrow) {
      prevArrow.style.display = 'flex'
    }
    if (nextArrow && artworks.length === index) {
      nextArrow.style.display = 'none'
    } else if (nextArrow) {
      nextArrow.style.display = 'flex'
    }

    const handleChange = (_, newIndex) => {
      setIndex(newIndex)
      onChangeIndex && onChangeIndex(newIndex)
    }
    const handleSwipe = direction => {
      console.log(direction)
    }

    return (
      <div
        id={'slick-inmersive'}
        className={`${inmersiveViewStyles.container} ${className}`}
      >
        <div
          className={`${index ? inmersiveViewStyles.background : ''}`}
          style={{
            backgroundImage: `url(${
                index === 0
                    ? ''
                    : (
                        artworks[index - 2]
                            ? replaceImageName(artworks[index - 2].Image_url)
                            : ''
                    )
            }),url(${
                index === 0
                    ? ''
                    : (
                        artworks[index + 1]
                            ? replaceImageName(artworks[index + 1].Image_url)
                            : ''
                    )
            })`,
          }}
        />
        {showSlider && (
          <>
            <div className={`slick-arrow`}>
              <Control
                icon={`prevArrow slick-prev icon-left-arrow`}
                onClick={() => ref?.current.slickPrev()}
                disabled={loading}
              />

              <Control
                icon={`nextArrow slick-next icon-right-arrow`}
                onClick={() => ref?.current.slickNext()}
                disabled={loading}
              />
            </div>
            <Slider
              ref={ref}
              {...settings}
              beforeChange={handleChange}
              //onSwipe={handleSwipe}
            >
              <section
                className={`${inmersiveViewStyles.wrapper} ${inmersiveViewStyles.text}`}
              >
                <h1>{currentJourney?.Title}</h1>
                <p>
                  {currentJourney?.Description.replace(
                    /^(.{480}[^\s]*).*/,
                    '$1'
                  )}
                </p>
              </section>

              {artworks.map((el, i) => (
                <section key={i} className={inmersiveViewStyles.wrapper}>
                  <div
                    className={`${inmersiveViewStyles.bg} ${inmersiveViewStyles.left}`}
                    style={{
                      backgroundImage: `url(${line})`,
                    }}
                  >
                    <div className={`${inmersiveViewStyles.inner}`}>
                      <Card
                        {...el}
                        image_url={el.Image_url}
                        onClick={onClickCard}
                      />
                    </div>
                  </div>

                  <SimpleBar
                    className={inmersiveViewStyles.paragraph}
                    id='modal-scroll'
                    autoHide={false}
                  >
                    <p>{el.Description || '-| Empty description |-'}</p>
                  </SimpleBar>
                  <div
                    className={`${inmersiveViewStyles.bg} ${inmersiveViewStyles.right}`}
                    style={{
                      backgroundImage: artworks[i + 1]?.Image_url
                        ? `url(${line})`
                        : 'none',
                    }}
                  >
                    <div className={`${inmersiveViewStyles.inner}`}>
                      <Card
                        {...artworks[i + 1]}
                        image_url={artworks[i + 1]?.Image_url}
                        onClick={onClickCard}
                        imagePreviewStyles={{
                          minWidth: '300px',
                          backgroundColor: 'white',
                        }}
                        preventLoaderPlaceholder={ true }
                      />
                    </div>
                  </div>
                </section>
              ))}
            </Slider>
          </>
        )}
      </div>
    )
  }
)

export default memo(InmersiveView)
