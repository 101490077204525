import { media, style } from 'typestyle'

export const artworkNavConnectorClass = style({
  borderRadius: '50%',
})

export const clickableClass = style(
  {
    cursor: 'pointer',
    background: '#FFFFFF',
    border: 'solid 4px transparent',
    borderRadius: '50%',
    overflow: 'hidden',
    width: '70px',
    height: '70px',
    boxSizing: 'border-box',
    $nest: {
      img: {
        padding: 0,
        maxWidth: '80%',
        maxHeight: '85%',
        width: 'auto',
        height: 'auto',
      },
      '&:focus': {
        border: 'solid 4px #d1d1d1',
      },
    },
  },
  media(
    { maxWidth: 960 },
    {
      fontSize: '12px',
      margin: '0',
      padding: '0',
      width: '50px',
      height: '50px',
      $nest: {
        img: {
          // maxWidth: '32px',
          maxHeight: '34px',
          margin: '0',
          padding: '0',
          textAlign: 'center',
        },
      },
    }
  )
)

export const clickableActiveClass = style(
  {
    border: 'solid 4px #7dc9c1',
    width: '70px',
    height: '70px',
    $nest: {
      '&:focus': {
        border: 'solid 4px #7dc9c1',
      },
    },
  },
  media(
    { maxWidth: 960 },
    {
      border: 'solid 3px #7dc9c1',
      width: '48px',
      height: '48px',
      $nest: {
        '&:focus': {
          border: 'solid 4px #7dc9c1',
        },
      },
    }
  )
)
