import React, {
  createRef,
  MouseEvent as ReactMouseEvent,
  useEffect,
  useState,
} from 'react'
import { classes } from 'typestyle'
import { logo, poweredByMicrosoft, MAPCircleLogo } from '../../assets'
import {
  mainMenuClass,
  mainMenuSolidClass,
  sidesClass,
  leftClass,
  rightClass,
  logoListClass,
  navClass,
  navLinkClass,
  overAll,
  backgroundClass,
  hamburgerButtonClass,
  mainLogoClass,
  logoClass,
  activeMobileNavClass,
  MAIN_MENU_SOLID_BG_RGB,
} from './main-menu.style'

import { connect } from 'react-redux'
import { selectors } from '../../state/modules'
import { Link } from 'react-router-dom'
import LinkAndScroll from '../linkAndScroll/linkAndScroll.component'
import ShareJourney from '../shareScreen/shareScreen.component'
import ModalLarge from '../modalLarge/modalLarge.component'
import useModal from '../../hooks/useModal.hook'
import useMediaQuery from '../../hooks/useMediaQuery.hook'
import { createStructuredSelector } from 'reselect'

interface MainMenuProps {
  //clearCurrentSelected: () => {}
  mainLogo?: boolean
  alwaysSolid?: boolean
  show?: string
}

const MainMenu: React.FC<MainMenuProps> = ({
  mainLogo = true,
  //clearCurrentSelected,
  alwaysSolid = false,
  show = '',
}) => {
  const [isClicked, setIsClicked] = useState<boolean>(false)
  const isMobile = useMediaQuery(960)
  const { isShowing, toggle } = useModal()
  const navRef = createRef<HTMLDivElement>()
  const toggleMobileMenu = (event: ReactMouseEvent): void => {
    setIsClicked(!isClicked)
    let navElement = navRef.current

    if (navElement) {
      if (navElement.classList.contains(activeMobileNavClass)) {
        navElement.classList.remove(activeMobileNavClass)
      } else {
        navElement.classList.add(activeMobileNavClass)
      }
    }
  }

  const handleClick = (e: any) => {
    //clearCurrentSelected()
    isMobile && toggleMobileMenu(e)
    if (e.currentTarget.dataset.name === 'share') {
      toggle()
    }
  }
  let currentOpacity: number = 0

  useEffect(() => {
    const menuWrapperElement = document.querySelector<HTMLDivElement>(
      `.${mainMenuClass}`
    )
    const menuShadowElement = document.querySelector<HTMLDivElement>(
      `.${backgroundClass}`
    )

    const scrollEvent = () => {
      const currentScroll = window.scrollY
      const scrollLimit: number = 300
      const opacity: number =
        currentScroll === 0
          ? 0
          : currentScroll >= scrollLimit
          ? 1
          : (currentScroll * 100) / scrollLimit / 100

      if (currentOpacity !== opacity) {
        currentOpacity = opacity

        if (menuWrapperElement && menuShadowElement) {
          menuWrapperElement.style.backgroundColor = `rgba(${MAIN_MENU_SOLID_BG_RGB}, ${opacity})`
          menuShadowElement.style.opacity = String(1 - opacity)
        }
      }
    }

    if (!alwaysSolid && !isMobile) {
      window.addEventListener('scroll', scrollEvent)
    }

    return () => {
      if (!alwaysSolid && !isMobile) {
        window.removeEventListener('scroll', scrollEvent)
      }
    }
    //}
  }, [])

  //console.log('show', show)
  return (
    <div
      className={classes(
        mainMenuClass,
        alwaysSolid && mainMenuSolidClass,
        isClicked && overAll
      )}
      style={{
        backgroundColor:
          show === 'network' || show === 'grid'
            ? `rgba(${MAIN_MENU_SOLID_BG_RGB}, ${isMobile ? 0.97 : 0.3})`
            : 'transparent',
      }}
    >
      <div className={classes(sidesClass, leftClass)}>
        <div
          className={logoListClass}
          id='partner-links'
          role='navigation'
          aria-label='Partners links'
        >
          {mainLogo && (
            <Link
              className={mainLogoClass}
              to='/'
              aria-label="Link to Interwoven's homepage"
              tabIndex={1}
              onClick={handleClick}
            >
              <img
                height='15px'
                src={logo}
                alt='Interwoven'
                aria-label='This is the official logo of Interwoven'
              />
            </Link>
          )}
          <a
            className={logoClass}
            target='_blank'
            href='https://map-india.org/'
            tabIndex={2}
            aria-label="External link to MAP's website"
            rel='noreferrer'
          >
            <img
              src={MAPCircleLogo}
              height='40'
              alt='MAP'
              aria-label='This is the official logo of MAP'
            />
          </a>
          <a
            className={logoClass}
            target='_blank'
            href='https://www.microsoft.com/en-us/'
            tabIndex={3}
            aria-label="External link to Microsoft's website"
            rel='noreferrer'
          >
            <img
              src={poweredByMicrosoft}
              height='35'
              alt='Powered by Microsoft'
              aria-label='This is the official logo of Microsoft'
            />
          </a>
        </div>
      </div>
      <div className={classes(sidesClass, rightClass)}>
        <button
          className={`${hamburgerButtonClass} icon-${
            isClicked ? 'cancel' : 'hamburger'
          }`}
          onClick={toggleMobileMenu}
          tabIndex={4}
          aria-label="Open Interwoven's navigation menu"
          aria-haspopup='true'
          aria-controls='site-nav'
          aria-expanded={isClicked ? 'true' : 'false'}
        ></button>
        <nav
          className={navClass}
          ref={navRef}
          id='site-nav'
          role='navigation'
          aria-label="Interwoven's navigation menu"
        >
          <LinkAndScroll
            className={navLinkClass}
            tabIndex={5}
            onClick={handleClick}
            to='/'
            anchor='#curated-journeys-section'
          >
            Journeys
          </LinkAndScroll>
          <Link
            onClick={handleClick}
            className={navLinkClass}
            tabIndex={6}
            to='/about'
          >
            About
          </Link>
          <Link
            onClick={handleClick}
            className={navLinkClass}
            tabIndex={7}
            to='/partners'
          >
            Partners
          </Link>
          <Link
            onClick={handleClick}
            className={navLinkClass}
            tabIndex={8}
            to='/textiles'
          >
            Learn More
          </Link>
          <button
            data-name='share'
            onClick={handleClick}
            className={navLinkClass}
            tabIndex={9}
          >
            Share
          </button>
        </nav>
      </div>
      <div className={backgroundClass}></div>
      <ModalLarge id={2} isShowing={isShowing} hide={toggle}>
        <ShareJourney
          title='Share INTERWOVEN Platform'
          emailSubject='MAP and Microsoft present INTERWOVEN'
          emailText='INTERWOVEN uses AI and machine learning to reveal connections between MAP’s collection of South Asian textiles and artworks from different cultures, mediums, and time periods.%0d%0aThe platform was developed in collaboration with Microsoft.%0d%0a%0d%0a'
          url={`${process.env.REACT_APP_SHARE_URL}`}
        />
      </ModalLarge>
    </div>
  )
}
const mapStateToProps = createStructuredSelector({
  show: selectors.artwork.show,
})

export default connect(mapStateToProps, null)(MainMenu)
//export default MainMenu
