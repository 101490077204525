import { useEffect, useState } from 'react'
import { logo } from '../../assets'
import imagePlaceholderStyles from './imagePlaceholder.module.scss'

const ImagePlaceholder = ({ style = {} }) => {
  const [height, setHeight] = useState(200)

  useEffect(() => {
    setHeight(Math.floor(Math.random() * 300) + 100)
  }, [])

  return (
    <div
      className={imagePlaceholderStyles.wrapper}
      style={{ ...style, height: height }}
    >
      <img
        className={imagePlaceholderStyles.logo}
        src={logo}
        alt='Interwoven'
      />
      Loading...
    </div>
  )
}

export default ImagePlaceholder
