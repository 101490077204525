import { CuratedJourneyForSlider, HomeSliderImage } from './home-page.component'

export const padNumber = (
  number: number,
  width: number,
  character: string = '0'
): string => {
  const nString = String(number)
  return nString.length >= width
    ? nString
    : new Array(width - nString.length + 1).join(character) + nString
}

const parseSliderImages = (rawArtworks: any): HomeSliderImage[] => {
  const result: HomeSliderImage[] = []

  if (rawArtworks && Array.isArray(rawArtworks)) {
    rawArtworks.forEach(rawArtwork => {
      if (rawArtwork['Title'] && rawArtwork['Image_url']) {
        result.push({
          alt: String(rawArtwork['Title']),
          src: String(String(rawArtwork['Image_url'])),
        })
      }
    })
  }

  return result
}

export const parseSliderData = (dataArray: any): CuratedJourneyForSlider[] => {
  const result: CuratedJourneyForSlider[] = []

  if (Array.isArray(dataArray)) {
    dataArray.forEach(data => {
      const urlPos = String(data['journey_url'])
        .split('')
        .findIndex((letter: string) => letter === '?')

      const hasId = String(data['journey_url']).includes('id')

      const urlMain = String(data['journey_url']).substr(0, urlPos)
      const urlQuery = String(data['journey_url']).substr(
        hasId ? urlPos : urlPos + 3
      )
      const id = data.id

      result.push({
        id,
        title: String(data['Title']) || '',
        description: String(data['Description']) || '',
        short_description: String(data['short_description']) || '',
        journey_url:
          urlPos !== -1
            ? hasId
              ? `${urlMain}${urlQuery}`
              : `${urlMain}?id=${id}&q=${urlQuery}`
            : '#',
        images: parseSliderImages(data['Artworks']),
      })
    })
  }

  return result
}
