import type from './journey.types'
import * as utils from './journey.utils'

export const initialState = {
  list: [],
  errors: [],
  isLoading: false,
}

const journeyReducer = (state = initialState, action) => {
  switch (action.type) {
    case type.TRY:
      return { ...state, isLoading: true }

    case type.LIST_SUCCESS:
      const sortedList = utils.bubbleSort(action.payload, 'sort_order')
      return { ...state, isLoading: false, list: sortedList }

    case type.FAILED:
      const errors = utils.addError(state.errors, action)
      return { ...state, isLoading: false, errors }

    default:
      return state
  }
}

export default journeyReducer
