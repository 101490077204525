import reducer, { initialState } from './artwork.reducer'
import types from './artwork.types'

import * as sagas from './artwork.sagas'
import * as actions from './artwork.actions'
import * as utils from './artwork.utils'
import * as selectors from './artwork.selectors'

export const artwork = {
  reducer,
  initialState,
  sagas,
  types,
  selectors,
  utils,
  actions,
}
