import React, { memo, useEffect, useState } from 'react'
import modalStyles from './modal.module.scss'

import ModalLarge from '../modalLarge/modalLarge.component'
import useModal from '../../hooks/useModal.hook'
import Control from '../control/control.component'
import { setLogo } from './modal.utils'
import ImagePlaceholder from '../imagePlaceholder/imagePlaceholder.component'
import { replaceImageName } from '../inmersiveView/inmersiveView.utils'
import ListItem from '../listItem/listItem.component'
import SimpleBar from 'simplebar-react'
import 'simplebar/dist/simplebar.min.css'

const Modal = memo(
  React.forwardRef((props, ref) => {
    const {
      showImage,
      Medium,
      PrimaryTitle,
      primary_image,
      Dated,
      Dimensions,
      Accession,
      onClick = null,
      handleDrag = null,
      showLargeImageButton,
      showAddCustomJourneyButton,
      showLounchCustomJourneyButton,
      onClickAddCJ = () => {},
      onClickOpenLargeModal = () => {},
      onClickLounchCustomJourney = () => {},
      isMobile,
      node = {},
      isOpened,
      onlyImage = false,
      showAddButon,
      showSelectArtwork = false,
      style,
    } = props
    const [currentMuseum, setCurrentMuseum] = useState({
      logo: null,
      height: 0,
      width: 0,
      link: null,
    })
    const [isHidden, setIsHidden] = useState(false)
    const [fadeIn, setFadeIn] = useState(false)
    const { isShowing, toggle } = useModal()
    const [loaded, setLoaded] = useState(false)

    useEffect(() => {
      document.addEventListener('keydown', onKeyPressed)
      return () => {
        document.removeEventListener('keydown', onKeyPressed)
      }
    }, [])
    useEffect(() => {
      setLoaded(false)
      node?.museum_id && setCurrentMuseum(setLogo(node.museum_id))
    }, [node, isOpened])

    useEffect(() => {
      console.log('isOpened', node)
      if (node) {
        setIsHidden(false)
        setTimeout(() => setFadeIn(true), 300)
      }
    }, [node])

    const handleClickImageLarge = () => {
      toggle()
    }

    const handleClose = node => {
      setFadeIn(false)
      setTimeout(() => {
        setIsHidden(true)
        onClick(node)
      }, 500)
    }

    const handleLoad = e => {
      e.target.style.display = 'block'
      setTimeout(() => {
        setLoaded(true)
        e.target.style.opacity = 1
        e.target.style.transition = '1s ease'
      }, 100)
    }

    const onKeyPressed = e => {
      if (e.keyCode === 27) handleClose(node)
    }

    //console.log('isHidden', isHidden)
    return (
      <div
        ref={ref}
        className={`modal-component ${modalStyles.modal} ${
          fadeIn ? modalStyles.fadeIn : ''
        } ${isHidden || !node ? modalStyles.hidden : ''}`}
        style={{ ...style }}
      >
        {onlyImage ? (
          <div className={`${modalStyles.container} ${modalStyles.onlyImage}`}>
            <img
              loading='lazy'
              src={replaceImageName(node?.image_url)}
              alt={PrimaryTitle}
            />
          </div>
        ) : (
          <div className={`${modalStyles.container}`}>
            {handleDrag && (
              <div
                onMouseDown={handleDrag}
                style={{ backgroundColor: 'white', height: 30, cursor: 'move' }}
              ></div>
            )}

            {showImage && node && !isMobile && (
              <button
                onClick={handleClickImageLarge}
                className={modalStyles.imagePreview}
              >
                <img
                  className={modalStyles.image}
                  loading='lazy'
                  style={{
                    opacity: !loaded ? 0 : 1,
                    position: 'absolute',
                    left: 0,
                    top: 0,
                  }}
                  src={replaceImageName(node.image_url, 'mid')}
                  alt={PrimaryTitle}
                  onLoad={handleLoad}
                />
                <ImagePlaceholder
                  style={{
                    width: '300px',
                    opacity: loaded ? 0 : 1,
                  }}
                />
              </button>
            )}

            {onClick && (
              <button
                className={`${modalStyles.close} icon-close`}
                onClick={() => handleClose(node)}
              ></button>
            )}

            {node && !isHidden && (
              <>
                <header>
                  <a
                    target='_blank'
                    rel='noreferrer'
                    alt={node.museum}
                    href={currentMuseum.link}
                    className={`${modalStyles.logo} ${
                      !currentMuseum.logo ? 'icon-map-circle-logo' : ''
                    }`}
                  >
                    {currentMuseum.logo && (
                      <img
                        height={currentMuseum.height}
                        width={currentMuseum.width}
                        alt={node.museum_id}
                        src={currentMuseum.logo}
                      />
                    )}
                  </a>

                  {isMobile && showImage && (
                    <div className={modalStyles.imageContainer}>
                      <button
                        onClick={handleClickImageLarge}
                        className={modalStyles.imageMobile}
                      >
                        <img
                          loading='lazy'
                          src={replaceImageName(node.image_url)}
                          alt={PrimaryTitle}
                        />
                      </button>
                      <Control
                        className={`${modalStyles.icon}`}
                        icon={` icon-add`}
                        withBorder
                        onClick={handleClickImageLarge}
                      />
                    </div>
                  )}
                  {onClickOpenLargeModal && showSelectArtwork && node && (
                    <button
                      type='button'
                      onClick={() => onClickOpenLargeModal(node)}
                      className={`button ${modalStyles.mainButton}`}
                    >
                      SELECT ARTWORK FOR JOURNEY
                    </button>
                  )}
                  {(showAddCustomJourneyButton || showAddButon) && node && (
                    <button
                      type='button'
                      onClick={() => onClickAddCJ(node)}
                      className={`button ${modalStyles.mainButton}`}
                    >
                      add to custom journey thread
                    </button>
                  )}
                  {showLargeImageButton && (
                    <button
                      type='button'
                      onClick={handleClickImageLarge}
                      className={`${modalStyles.button}`}
                    >
                      ENLARGE ARTWORK
                    </button>
                  )}
                </header>

                <SimpleBar
                  className={modalStyles.list}
                  id='modal-scroll'
                  autoHide={false}
                >
                  <dl tabIndex={0}>
                    <ListItem name='Title' value={PrimaryTitle || node.title} />

                    <ListItem name='Accession No.' value={node.object_number} />

                    <ListItem name='Maker' value={node.artist} />

                    <ListItem name='Date' value={Dated || node.date} />

                    <ListItem name='Period' value={node.period} />

                    <ListItem name='Origin' value={node.origin} />

                    <ListItem name='Medium' value={Medium || node.medium} />

                    <ListItem
                      name='Classification'
                      value={node.filter_classification_types}
                    />

                    <ListItem
                      name='Dimensions'
                      value={Dimensions || node.dimensions}
                    />

                    <ListItem
                      name='Accession'
                      value={Accession || node.accession}
                    />
                    <ListItem
                      name='Technique'
                      value={node.filter_medium_process}
                    />

                    <ListItem name='Technique' value={null} />

                    <ListItem
                      name='Pattern / motif'
                      value={node.filter_patterns_motifs}
                    />

                    <ListItem name='Description' value={node.description} />

                    <ListItem name='Tags' value={node.semantic_keywords} />
                  </dl>
                </SimpleBar>
                <footer>
                  {showLounchCustomJourneyButton && (
                    <>
                      <hr />
                      <button
                        className={`button ${modalStyles.button}`}
                        type='button'
                        onClick={() => onClickLounchCustomJourney(node)}
                      >
                        Launch custom journey from this artwork
                      </button>
                    </>
                  )}
                </footer>
                <ModalLarge
                  isShowing={isShowing}
                  hide={toggle}
                  image={primary_image || node.image_url}
                  {...node}
                  showLoading
                  onlyImage
                />
              </>
            )}
          </div>
        )}
        {
          //<div className={`${modalStyles.container}`}>
          //Please try again, no data loaded...
          //<button
          //className={`${modalStyles.close} icon-close`}
          //onClick={() => handleClose(node)}
          //></button>
          //</div>
        }
      </div>
    )
  })
)

export default Modal
