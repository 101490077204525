import { MouseEvent, FC, ChangeEvent } from 'react'
import filterItemStyles from './filterItem.module.scss'
import { FilterOption } from '../filters/filters.types'

interface IChildList {
  children?: FilterOption[]
  filterParent: any
  handleChange: () => {}
  disabled?: boolean
}

const ChildList = ({
  children,
  filterParent,
  handleChange,
  disabled,
}: IChildList) => {
  return (
    <ul className={filterItemStyles.list}>
      {children?.map((child: FilterOption, key: number) => (
        <li key={key}>
          <FilterItem
            is3dView={false}
            filterParent={filterParent}
            label={child.label}
            value={child.value}
            isSelected={child.isSelected}
            children={child.children}
            onChange={handleChange}
            disabled={disabled}
          />
        </li>
      ))}
    </ul>
  )
}

const FilterItem: FC<FilterOption> = ({
  itemId,
  label,
  value,
  amount,
  count,
  filterParent,
  isSelected = false,
  children,
  onChange,
  is3dView,
  disabled,
}) => {
  //console.log('disabled', disabled)
  const handleChange = (
    e: MouseEvent<HTMLButtonElement> | ChangeEvent<HTMLInputElement>,
    value?: string,
    label?: string
  ): void => {
    e.preventDefault()
    const val = value || label
    //setIsSelected(!isSelected)

    onChange && onChange({ value: val, currentId: itemId, ...filterParent })
  }
  const name = label || value
  return (
    <>
      <button
        className={filterItemStyles.button}
        onClick={e => handleChange(e, value, label)}
        aria-label={name + (count && !is3dView ? '| ' + count : '')}
        disabled={disabled}
        style={ (count === 0) ? {pointerEvents: 'none', opacity: .5} : {} }
      >
        <label className={`${filterItemStyles.checkbox}`} tabIndex={-1}>
          {name} {count && !is3dView ? '| ' + count : ''}
          <input
            type='checkbox'
            checked={isSelected}
            onChange={e => handleChange(e, value, label)}
            disabled={disabled}
            tabIndex={-1}
          />
          <span className={filterItemStyles.checkmark}></span>
        </label>
      </button>
      {isSelected && children && (
        <ChildList
          children={children}
          filterParent={{ head: name, headId: itemId, ...filterParent }}
          handleChange={onChange}
          disabled={disabled}
        />
      )}
    </>
  )
}

export default FilterItem
