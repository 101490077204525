import { style } from 'typestyle';
import { NestedCSSProperties } from 'typestyle/lib/types';

const formFont: string = 'Roboto, Arial, sans-serif';

export const savePopupHead = style({
    textAlign: 'center',
    fontFamily: formFont,
    fontSize: '16px',
    padding: '0 0 15px 0',
    borderBottom: 'solid 1px gainsboro',
    color: 'gray'
});

export const saveWrapperClass = style({
    display: 'flex',
    position: 'fixed',
    left: 0,
    top: 0,
    right: 0,
    bottom: 0,
    background: 'rgba(0, 0, 0, .7)',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    zIndex: 99999,
    backdropFilter: 'blur(10px)'
});

export const savePopupClass = style({
    display: 'block',
    position: 'relative',
    background: 'white',
    padding: '20px',
    margin: '15px',
    borderRadius: '5px',
    minWidth: '1000px',
    maxWidth: '1920px',

    $nest: {
        'hr': {
            margin: '30px 0',
            borderColor: 'gainsboro',
            borderBottom: 'none'
        }
    }
});

export const closeSaveFormButtonClass = style({
    display: 'block',
    width: '40px',
    height: '40px',
    position: 'absolute',
    top: '7.5px',
    right: '7.5px',
    cursor: 'pointer',

    $nest: {
        'span': {
            display: 'block',
            position: 'absolute',
            width: '20px',
            height: '1px',
            background: 'gray',
            left: '10px',
            top: '20px',

            $nest: {
                '&:first-child': {
                    transform: 'rotate(45deg)'
                },
                '&:last-child': {
                    transform: 'rotate(-45deg)'
                }
            }
        }
    }
});

export const saveForm = style({
    margin: '30px 0 0 0'
});

const fieldStyle: NestedCSSProperties = {
    fontSize: '18px',
    color: 'gray',
    width: 'calc(100% - 200px)',
    border: 'solid 1px #c3c3c3',
    borderRadius: '1px',
    padding: '5px 10px',
    fontFamily: formFont,
    minHeight: '33px',

    $nest: {
        '&:focus': {
            borderColor: 'rgb(125, 198, 193)'
        },
        '&:disabled': {
            background: 'gainsboro',
            cursor: 'not-allowed'
        },
        '&:read-only': {
            background: 'gainsboro'
        }
    }
};

export const saveFormField = style({
    margin: '10px 0',
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'top',

    $nest: {
        'label': {
            textTransform: 'uppercase',
            width: '200px',
            fontSize: '14px',
            fontFamily: formFont,
            color: 'gray',
            display: 'inline-block',
            overflow: 'hidden',
            textOverflow: 'ellipsis',
            whiteSpace: 'nowrap',
            height: '33px',
            lineHeight: '33px'
        },
        'input': fieldStyle,
        'textarea': fieldStyle
    }
});

export const saveFormImages = style({
    margin: '30px 0'
});

export const saveFormImagesTitle = style({
    margin: '15px 0',
    textTransform: 'uppercase',
    color: 'gray',
    textAlign: 'left',
    fontSize: '14px',
    fontFamily: formFont
});

export const saveFormImagesLine = style({
    textAlign: 'left',
    maxWidth: '900px',
    overflow: 'hidden'
});

export const saveFormImage = style({
    overflow: 'hidden',
    borderRadius: '50%',
    width: '80px',
    height: '80px',
    margin: '5px',
    display: 'inline-block',
    backgroundRepeat: 'no-repeat',
    backgroundPosition: 'center',
    backgroundSize: 'cover',
    border: '4px solid transparent',
    boxSizing: 'border-box',
    cursor: 'pointer'
});

export const saveFormImageSelected = style({
    borderColor: 'rgb(125, 198, 193)',
});

export const submitButtonContainer = style({
    display: 'block',
    position: 'relative',
    textAlign: 'center'
});

export const submitButton = style({
    display: 'inline-block',
    position: 'relative',
    width: '100%',
    maxWidth: '400px',
    height: '33px',
    border: 'none',
    background: 'rgb(125, 198, 193)',
    color: 'white',
    fontWeight: 600,
    textTransform: 'uppercase',
    cursor: 'pointer',
    fontFamily: formFont,
    fontSize: '14px',

    $nest: {
        '&:disabled': {
            background: 'gainsboro',
            cursor: 'not-allowed'
        }
    }
});
