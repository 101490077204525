import reducer, { initialState } from './filter.reducer'
import types from './filter.types'

import * as sagas from './filter.sagas'
import * as actions from './filter.actions'
import * as utils from './filter.utils'
import * as selectors from './filter.selectors'

export const filter = {
  reducer,
  initialState,
  sagas,
  types,
  selectors,
  utils,
  actions,
}
