import { homeBG } from '../../assets'
import sharedCardsStyle from './shareCards.module.scss'

export default function ShareCards({ data = [], onClickJourney = () => {} }) {
  return (
    <ul className={sharedCardsStyle.wrapper}>
      {data.map((ele, i) => {
        return (
          i < 3 && (
            <li key={i} className={sharedCardsStyle.card}>
              <button type='button' onClick={() => onClickJourney(ele)}>
                <div
                  className={sharedCardsStyle.images}
                  style={{ backgroundImage: `url(${homeBG})` }}
                >
                  {ele.images.map((artwork, key) => {
                    if (key < 3) {
                      return (
                        <img
                          className={sharedCardsStyle.smallImage}
                          key={key}
                          {...artwork}
                        />
                      )
                    }
                  })}
                </div>
                <h3>{ele.title}</h3>
                <p className={sharedCardsStyle.paragraph}>{ele.description}</p>
              </button>
            </li>
          )
        )
      })}
    </ul>
  )
}
