import { createContext, useState } from 'react'

const FilterContext = createContext({
  /* Filters */
  currentFilters: [],
  addFilter: () => {},
  removeFilter: () => {},
  setContextFilters: () => {},
  clearFilters: () => {},
})

function removeDuplicates(array) {
  const result = []
  for (let i = 0; i < array.length; i++) {
    let exists = false
    for (let j = 0; j < result.length; j++) {
      if (array[i]?.value === result[j]?.value) {
        exists = true
        break
      }
    }
    if (!exists) {
      result.push(array[i])
    }
  }
  return result
}

const FilterProvider = ({ children }) => {
  const [currentFilters, setCurrentFilters] = useState([])

  const addFilter = element => {
    if (element.value) {
      if (!element.current.isRange) {
        setCurrentFilters(oldArray => {
          const filterd = removeDuplicates([...oldArray, element])
          return filterd
        })
      } else {
        const index = currentFilters.findIndex(
          filters => filters.current.parentId === element.current.parentId
        )

        if (index > -1) {
          currentFilters[index].value = element.value
          setCurrentFilters([...currentFilters])
        } else {
          setCurrentFilters(oldArray => {
            const filterd = removeDuplicates([...oldArray, element])
            return filterd
          })
        }
      }
    }
  }

  const removeFilter = value => {
    const index = currentFilters.findIndex(filter => filter.value === value)

    if (index > -1) {
      currentFilters[index].isSelected = false
      currentFilters.splice(index, 1)
      setContextFilters(currentFilters)
    }
  }

  const setContextFilters = filters => {
    setCurrentFilters(removeDuplicates(filters))
  }

  const clearFilters = () => setCurrentFilters([])

  return (
    <FilterContext.Provider
      value={{
        currentFilters,
        setContextFilters,
        removeFilter,
        addFilter,
        clearFilters,
      }}
    >
      {children}
    </FilterContext.Provider>
  )
}

const withFilters = Child => props =>
  (
    <FilterContext.Consumer>
      {context => <Child {...props} context={context} />}
    </FilterContext.Consumer>
  )

export { FilterProvider, withFilters, FilterContext }
