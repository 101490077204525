import type from './filter.types'
import * as utils from './filter.utils'

export const initialState = {
  list: [],
  selected: [],
  errors: [],
  isLoading: false,
}

const filterReducer = (state = initialState, action) => {
  switch (action.type) {
    case type.TRY:
      return { ...state, isLoading: true }

    case type.LIST_SUCCESS: {
      const list = utils.addedList(action)
      return { ...state, isLoading: false, selected: [], list }
    }

    case type.UPDATE_LIST: {
      const updated = utils.updateFilters(state.list, state.selected, action)
      return { ...state, isLoading: false, ...updated }
    }

    case type.UPDATE_LIST_COUNTS: {
      const updated = utils.updateFiltersCounts(
        state.list,
        state.selected,
        action
      )
      return { ...state, isLoading: false, ...updated }
    }

    case type.CLEAN_LIST: {
      const updated = utils.onClean(state.list, action)
      return { ...state, ...updated }
    }

    case type.FAILED: {
      const errors = utils.addError(state.errors, action)
      return { ...state, errors, isLoading: false }
    }

    default:
      return state
  }
}

export default filterReducer
