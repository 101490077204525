import { CSSProperties } from 'react';
import { ArrowPosition, HighlightPosition } from './helpScreen.canvas';
import { ContentPosition, HighlightMargin, StepConfig } from './helpScreen.steps';

const contentMargin: number = 20;
export const safeAreaTop: number = 300;

export const getContentPosition = (
    highlightPosition: HighlightPosition,
    contentPosition: ContentPosition,
    contentWidth: number,
    height: number,
    width: number
): CSSProperties => {
    switch(contentPosition) {
        case 'top':
            return {
                bottom: `${height - highlightPosition[1] + contentMargin}px`,
                left: `${highlightPosition[0] + contentMargin}px`,
                width: `${contentWidth}px`
            };

        case 'left':
            return {
                top: `${Math.max(highlightPosition[1], safeAreaTop)}px`,
                right: `${width - highlightPosition[0] + contentMargin}px`,
                width: `${contentWidth}px`
            };

        case 'right':
            return {
                top: `${Math.max(highlightPosition[1], safeAreaTop)}px`,
                left: `${highlightPosition[0] + highlightPosition[2] + contentMargin}px`,
                width: `${contentWidth}px`
            };

        case 'bottom':
            return {
                top: `${highlightPosition[1] + highlightPosition[3] + contentMargin}px`,
                left: `${highlightPosition[0]}px`,
                width: `${contentWidth}px`
            };

        default:
            return {};
    }
};

export const applyMarginsToSquarePosition = (
    squarePosition: HighlightPosition,
    margin: HighlightMargin
): HighlightPosition => {


    if (margin !== 0) {
        if (Array.isArray(margin)) {
            let marginTop: number = 0;
            let marginRight: number = 0;
            let marginBottom: number = 0;
            let marginLeft: number = 0;

            switch (margin.length) {
                case 2:
                    marginTop = margin[0];
                    marginRight = margin[1];
                    marginBottom = margin[0];
                    marginLeft = margin[1];
                    break;

                case 3:
                    marginTop = margin[0];
                    marginRight = margin[1];
                    marginBottom = margin[2];
                    marginLeft = margin[1];
                    break;

                case 4:
                    marginTop = margin[0];
                    marginRight = margin[1];
                    marginBottom = margin[2];
                    marginLeft = margin[3];
                    break;
            }

            squarePosition[0] -= marginLeft;
            squarePosition[1] -= marginTop;
            squarePosition[2] += (marginLeft + marginRight);
            squarePosition[3] += (marginTop + marginBottom);
        }
        else {
            const doubleMargin = margin * 2;

            squarePosition[0] -= margin;
            squarePosition[1] -= margin;
            squarePosition[2] += doubleMargin;
            squarePosition[3] += doubleMargin;
        }
    }

    return squarePosition;
};

export const getArrowPosition = (contentElement: HTMLElement, currentStep: StepConfig): ArrowPosition | null => {
    const windowCenterX = window.innerWidth / 2;
    const contentClientRect = contentElement.getBoundingClientRect();
    const topMargin = 20;
    const sideMargin = 20;
    
    if ((contentClientRect.left + contentClientRect.width) < windowCenterX) {
        return [
            contentClientRect.left + contentClientRect.width + sideMargin,
            contentClientRect.top + topMargin
        ] as ArrowPosition;
    }
    else if (contentClientRect.left > windowCenterX) {
        return [
            contentClientRect.left - sideMargin,
            contentClientRect.top + topMargin
        ] as ArrowPosition;
    }

    return null;
};

export default () => {};
