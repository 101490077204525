import Control from '../control/control.component'
import controlsStyles from './controls.styles.module.scss'

interface ICurrentProps {
  onClick?: (name: string) => string
}

const Controls: React.FC<ICurrentProps> = ({ onClick }) => {
  const handleClick = (name: string) => {
    onClick && onClick(name)
  }

  return (
    <ul className={controlsStyles.wrapper}>
      <li>
        <Control
            showLabel
            name='Zoom In'
            onClick={handleClick}
        />
      </li>
      <li>
        <Control
          showLabel
          name='Zoom Out'
          onClick={handleClick}
          icon='icon-minus'
        />
      </li>
      <li className={controlsStyles.spaceTop}>
        <Control
          showLabel
          name='Pan Up'
          icon={`icon-up-arrow`}
          onClick={handleClick}
        />
      </li>
      <li>
        <Control
          showLabel
          name='Pan Down'
          icon={`icon-down-arrow`}
          onClick={handleClick}
        />
      </li>
      <li>
        <Control
          showLabel
          name='Pan Left'
          icon={`icon-left-arrow`}
          onClick={handleClick}
        />
      </li>
      <li>
        <Control
          showLabel
          name='Pan Right'
          icon={`icon-right-arrow`}
          onClick={handleClick}
        />
      </li>
      <li>
        <Control
          showLabel
          name='Rotation'
          icon={`icon-cube`}
          onClick={handleClick}
        />
      </li>
      <li className={controlsStyles.spaceTop}>
        <Control
          showLabel
          name='Reset'
          icon={`icon-circular-arrow`}
          onClick={handleClick}
        />
      </li>
    </ul>
  )
}

export default Controls
