import { useRef } from 'react'
import Card from '../card/card.component'
import Masonry from 'react-masonry-css'
import useMousePosition from '../../hooks/useMousePosition.hook'

import './cardList.css'
import cardListStyles from './cardList.style.module.scss'

export default function CardList({
  artworks,
  paddingTop = '0px',
  breakpointColumns = {
    default: 3,
    960: 3,
    500: 2,
  },
  onClickCard,
}) {
  const containerRef = useRef(null)
  const { getPos } = useMousePosition(20)

  //const elements = filterBy(data, filtered) || data
  const elements = artworks

  const handleMouseClick = (curr, card) => {
    const currentCard = card.currentTarget.parentElement.parentElement

    const currentCardPosition = getPos(currentCard)

    const minWidth =
      window.innerWidth - window.innerWidth / breakpointColumns.default

    const verticalPos =
      containerRef.current.scrollTop -
      currentCard.scrollHeight +
      currentCard.offsetHeight

    let direction = currentCardPosition.x > minWidth ? -1 : 1 // - left / + right

    onClickCard &&
      onClickCard(curr, {
        current: currentCard,
        position: currentCardPosition,
        vertical: verticalPos,
        minWidth,
        direction,
      })
  }

  return (
    <div
      ref={containerRef}
      style={{ paddingTop }}
      className={cardListStyles.container}
    >
      <Masonry
        breakpointCols={breakpointColumns}
        className='my-masonry-grid'
        columnClassName='my-masonry-grid_column'
      >
        {elements.map((artwork, key) => (
          <div key={artwork.id + '-' + key} className={`card id-${artwork.id}`}>
            <Card
              {...artwork}
              showShadow
              onClick={(curr, event) => handleMouseClick(curr, event)}
            />
          </div>
        ))}
      </Masonry>
    </div>
  )
}
