import React, { useEffect, useState, createRef } from 'react'
import ReactDOM from 'react-dom'
import modalLarge from './modalLarge.module.scss'
import { encodeIds } from '../../utils'
import Loading from '../loading/loading.component'
import { replaceImageName } from '../inmersiveView/inmersiveView.utils'
import { TabArea } from '../../tabArea.service'

interface IProps {
  image_url?: string
}

interface PropertiesProps extends IProps {
  id: number | string
  isShowing: boolean
  image?: string
  onlyImage?: boolean
  hide?: () => any
  showLoading?: boolean
  onClick?: (encodeIds: string) => {}
  onClose?: (id: string | number) => any
}

const ModalLarge: React.FC<PropertiesProps> = ({
  id = '',
  isShowing = false,
  image = '',
  onlyImage,
  hide = () => {},
  children,
  showLoading,
  onClick = () => {},
  onClose = () => {},
  ...props
}) => {
  const [loaded, setLoaded] = useState(false)
  const [fade, setFade] = useState(false)
  const wrapperRef = createRef<HTMLDivElement>()

  useEffect(() => {
    document.addEventListener('keydown', onKeyPressed)
    return () => {
      document.removeEventListener('keydown', onKeyPressed)
    }
  }, [])

  useEffect(() => {
    if (isShowing) {
      setTimeout(() => setFade(true), 100)
    }
  }, [isShowing])

  useEffect(() => {
    if (isShowing && wrapperRef.current) {
      const tabArea = new TabArea(wrapperRef.current)

      return () => {
        tabArea.restore()
      }
    }
  }, [isShowing])

  const onKeyPressed = (e: any) => {
    if (e.keyCode === 27) handleClose()
  }

  const handleClose = () => {
    setFade(false)

    setTimeout(() => {
      onClose(id)
      hide()
    }, 500)
  }

  const handleClick = () => {
    onClick(encodeIds(String(id)))
  }
  const loadImage = (e: any) => {
    setLoaded(true)
    e.target.style.display = 'block'

    setTimeout(() => {
      e.target.style.transition = '.8s ease'
      e.target.style.opacity = 1
    }, 10)
  }

  return isShowing
    ? ReactDOM.createPortal(
        <div
          ref={wrapperRef}
          className={`${modalLarge.container} ${fade ? modalLarge.fade : ''}`}
        >
          <div className={modalLarge.overlay} />
          <div
            className={`${modalLarge.wrapper} `}
            aria-modal
            aria-hidden
            tabIndex={-1}
            role='dialog'
          >
            <div
              id='large-modal'
              className={`${modalLarge.modal} ${
                onlyImage ? modalLarge.onlyImage : ''
              }`}
            >
              <button
                type='button'
                className={modalLarge.closeButton}
                data-dismiss='modal'
                aria-label='Close'
                onClick={handleClose}
              >
                <span aria-hidden='true' className='icon-cancel'></span>
              </button>

              {!onlyImage && !children && (
                <>
                  <h1>Launch Custom Journey</h1>
                  <p>
                    You have selected the artwork below to begin a Custom
                    Journey
                  </p>
                </>
              )}

              {props.image_url && image && (
                <img
                  style={{ opacity: 0, display: 'none' }}
                  className={modalLarge.img}
                  src={`${replaceImageName(props.image_url, 'mid')}`}
                  alt={'name'}
                  onLoad={loadImage}
                />
              )}

              {!loaded && showLoading && (
                <Loading backgroundColor='#ffffff' showLogo small />
              )}
              {!onlyImage && !children && (
                <>
                  <button
                    className={modalLarge.button}
                    onClick={handleClick}
                    type='button'
                  >
                    Let's go
                  </button>
                  <button
                    className={modalLarge.results}
                    onClick={hide}
                    type='button'
                  >
                    Return to Search Results
                  </button>
                </>
              )}

              {children}
            </div>
          </div>
        </div>,
        document.body
      )
    : null
}

export default ModalLarge
