const time = () => {
  const time = new Date()
  return time.toLocaleString('en-US', {
    timeZone: process.env.REACT_APP_TIME_ZONE,
  })
}

export const addError = (errors, action) => {
  const payload = action.payload

  const errorData = {
    name: payload.name,
    message: { error: payload.error, time: time() },
  }

  const errorId = errors.findIndex(error => error.message.time === time())

  if (errorId === -1) errors.push(errorData)
  else errors[errorId] = errorData
  return errors
}
