import React from 'react'
import {
  Link,
  withRouter,
  RouteComponentProps,
  LinkProps,
} from 'react-router-dom'
import { animateScroll as scroll } from 'react-scroll'
import { DESKTOP_MAIN_MENU_HEIGHT_PX } from '../../constants'
import { constfilterBadProps } from './linkAndScroll.utils'

interface LinkAndScrollProps {
  anchor: string
}

type LinkAndScrollRouteProps = RouteComponentProps<{}> &
  LinkAndScrollProps &
  LinkProps

const LinkAndScroll: React.FC<LinkAndScrollRouteProps> = ({
  anchor,
  onClick,
  history,
  to = '/',
  ...props
}) => {
  const findTarget = (
    selector: string,
    successCallback: (targetElement: HTMLElement) => void,
    timeout: number = 1000
  ): void => {
    let currentTime: number = 0
    const checkLatency: number = 100
    const interval = setInterval(() => {
      const targetElement = document.querySelector<HTMLElement>(selector)

      currentTime += checkLatency

      if (targetElement || currentTime >= timeout) {
        clearInterval(interval)

        if (targetElement) {
          successCallback(targetElement)
        }
      }
    }, checkLatency)
  }

  const onClickHandle = (
    event: React.MouseEvent<HTMLAnchorElement, MouseEvent>
  ): void => {
    onClick && onClick(event)

    const unlistenHistory = history.listen(location => {
      if (location.pathname === to) {
        setTimeout(() => {
          findTarget(anchor, targetElement => {
            setTimeout(() => {
              scroll.scrollTo(
                targetElement.offsetTop - DESKTOP_MAIN_MENU_HEIGHT_PX
              )
            }, 150)
          })
        })
        unlistenHistory()
      }
    })
  }

  return (
    <Link {...constfilterBadProps(props)} to={to} onClick={onClickHandle} />
  )
}

export default withRouter(LinkAndScroll)
