export const NAME = String('journey').toUpperCase() // same as the  global state

const types = {
  TRY: `[${NAME}]/TRY`,

  LIST_SUCCESS: `[${NAME}]/LIST_SUCCESS`,
  LIST_SUCCESS_REQUESTED: `[${NAME}]/LIST_SUCCESS_REQUESTED`,

  FAILED: `[${NAME}]/FAILED`,
}

export default types
