import filterStyles from './filters.module.scss'
import FilterItem from '../filterItem/filterItem.component'
import { useEffect, useRef, useState } from 'react'
import CustomRange from '../customRange/customRange.component'

import { connect } from 'react-redux'
import { createStructuredSelector } from 'reselect'
import { actions, selectors } from '../../state/modules'
import Control from '../control/control.component'
import { debounce } from '../graph/graph.utils'

const Filters = ({
  updateListFilters,
  filtersList,
  loadFiltersList,
  is3dView,
  onClose,
  onChange,
  count,
  loading,
  onClickVisibility,
  isMobile,
  show,
}) => {
  const [update, setUpdate] = useState(false)
  // only for mobile
  let clicked = useRef(false)
  const [isOpen, setIsOpen] = useState(false)

  useEffect(() => {
    if (is3dView) loadFiltersList(true)
    else loadFiltersList()
  }, [])

  /*
   * Event handler for handleChange
   */
  const handleChange = current => {
    if (!loading && !clicked.current) {
      debounce(delayUpdate(current), 500)
    }
  }

  const delayUpdate = current => {
    updateListFilters(current)
    setUpdate(state => !state)
    onChange && onChange(current)

    setTimeout(() => {
      clicked.current = false
    }, 500)
    clicked.current = true
  }

  /*
   * Event handler for customRange component
   */
  const handleCustomRange = range => {
    const current = {
      isRange: range.isRange,
      currentId: 0,
      name: range.head,
      parentId: range.headId,
      value: range.value,
      count: [range.min, range.max],
      defaultSelected: range.defaultSelected,
    }
    updateListFilters(current)
    setUpdate(state => !state)
    onChange && onChange(current)
  }

  const openCloseFilter = key => {
    updateListFilters(key)
    setUpdate(state => !state)
  }

  const handleHeaderClick = name => {
    if (name === 'visibility' && onClickVisibility) onClickVisibility()
    else setIsOpen(!isOpen)
  }
  const handleCloseClick = () => {
    onClose && onClose()
  }
  return (
    <div
      data-help-step='2'
      className={`${filterStyles.wrapper} ${
        is3dView && isMobile ? filterStyles.filters3dview : ''
      } ${
        is3dView && isMobile
          ? (isOpen && filterStyles.filters3dviewOpen) || ''
          : ''
      }`}
    >
      <header className={filterStyles.header}>
        <div
          className={`${filterStyles.countArtworks} ${
            !is3dView ? filterStyles.hiddenCount : ''
          }`}
        >
          <span>
            <b>{!loading ? count : count === 0 ? 0 : 'loading'}</b> CONNECTED
            ARTWORKS
          </span>
          {isMobile && (
            <button
              onClick={handleCloseClick}
              className={`${filterStyles.close} icon-close`}
            />
          )}
          <div className={filterStyles.visibility}>
            <Control
              withBorder
              name='filter'
              icon={`icon-filter`}
              onClick={handleHeaderClick}
            />
            {show === 'network' && (
              <Control
                withBorder
                name='visibility'
                icon={`icon-visibility`}
                onClick={handleHeaderClick}
              />
            )}
          </div>
        </div>
        {isMobile ? (
          <button
            type='button'
            onClick={handleHeaderClick}
            aria-label='Show or hide filters'
            className={`${filterStyles.accordionHead} ${
              is3dView ? (!isOpen && filterStyles.hidden) || '' : ''
            }`}
          >
            <h1>
              Select filters to explore <br /> Connectors & Differentiators
            </h1>
            {isMobile && (
              <span
                className={
                  isOpen
                    ? `${filterStyles.accordionMinus} icon-${
                        is3dView ? 'close' : 'minus'
                      }`
                    : `${filterStyles.accordionPlus} icon-add`
                }
              ></span>
            )}
          </button>
        ) : (
          <h1 className={filterStyles.connectors}>
            Select filters to explore <br /> Connectors & Differentiators
          </h1>
        )}
      </header>

      <div
        className={`${filterStyles.content} ${
          is3dView ? filterStyles.content3d : ''
        } ${isMobile ? (!isOpen && filterStyles.hidden) || '' : ''}`}
      >
        <div className={filterStyles.accordion}>
          {filtersList?.map((filter, key) => (
            <div
              key={key}
              className={`${filterStyles.accordionItem} ${
                filter.isOpen ? filterStyles.accordionOpen : ''
              }`}
            >
              <button
                type='button'
                className={filterStyles.accordionHead}
                aria-label={filter.name}
                onClick={event => {
                  event.preventDefault()
                  openCloseFilter(key)
                }}
              >
                {filter.name}
                <span
                  className={
                    filter.isOpen
                      ? `${filterStyles.accordionMinus} icon-minus`
                      : `${filterStyles.accordionPlus} icon-add`
                  }
                ></span>
              </button>

              {filter.isOpen && (
                <div className={`${filterStyles.selectedFilter}`}>
                  {!filter.isRange ? (
                    filter.options?.map((option, id) => {
                      return (
                        <FilterItem
                          key={id}
                          itemId={id}
                          is3dView={false}
                          {...option}
                          filterParent={{
                            parentId: key,
                            name: filter.name,
                          }}
                          onChange={handleChange}
                          disabled={loading}
                        />
                      )
                    })
                  ) : (
                    <CustomRange
                      min={0}
                      max={(filter?.options?.length || 2) - 1}
                      key={key}
                      itemId={key}
                      {...filter}
                      options={filter.options}
                      onClick={e => handleCustomRange(e)}
                    />
                  )}
                </div>
              )}
            </div>
          ))}
        </div>
      </div>
      {isMobile && !is3dView && <hr className={filterStyles.separator} />}
    </div>
  )
}

const mapStateToProps = createStructuredSelector({
  filtersList: selectors.filter.selectFilterList,
  show: selectors.artwork.show,
})

const mapDispatchToProps = dispatch => ({
  updateListFilters: currentSelected =>
    dispatch(actions.filter.updateListFilters(currentSelected)),
  loadFiltersList: (all = false) =>
    dispatch(actions.filter.setListFilters(all)),
})

export default connect(mapStateToProps, mapDispatchToProps)(Filters)
