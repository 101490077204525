export const replaceImageName = (name, size = 'small') => {
  if (name) {
    if (name.includes('_small')) {
      return size === 'small' ? name : name.replace('_small.jpg', '_mid.jpg')
    } else if (name.includes('_mid')) {
      return size === 'mid' ? name : name.replace('_mid.jpg', '_small.jpg')
    }
    return name.replace('.jpg', size === 'small' ? '_small.jpg' : '_mid.jpg')
  } else return 'error element is null'
}

export const rawData = {
  id: 34,
  Title: 'A journey from lorem ipsum',
  Description:
    'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.',
  curator_name: 'Michael Fromm',
  curator_email: 'michaelfromm@pastpresentfuture.com',
  journey_url:
    '/journey?q=MTc0ODczLDE3NDI1MSwxNDYxMDEsMTYwMDU5LDE0MTM1NSwxNjM0NzAsMTQ2NDc2',
  Artworks: [
    {
      id: 180,
      Title: 'Kashmir Shawl Fragment',
      ArtworkID: '174873',
      Image_url:
        'https://interwovenprodstorage.blob.core.windows.net/images/MAP_1_334_TXT.00158.jpg',
      Description:
        '#1 Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.',
    },
    {
      id: 181,
      Title: 'Victim of a Charmer',
      ArtworkID: '174251',
      Image_url:
        'https://interwovenprodstorage.blob.core.windows.net/images/MAP_553_PTG.00215_small.jpg',
      Description:
        '#2 Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.',
    },
    {
      id: 182,
      Title: 'The Virgin and Child',
      ArtworkID: '146101',
      Image_url:
        'https://interwovenprodstorage.blob.core.windows.net/images/HARVARD_228872_urn-3:HUAM:DDC245090_dynmc_small.jpg',
      Description:
        '#3 Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.',
    },
    {
      id: 183,
      Title: 'Ambassadeurs: Aristide Bruant in his Cabaret',
      ArtworkID: '160059',
      Image_url:
        'https://interwovenprodstorage.blob.core.windows.net/images/HARVARD_352,052_urn-3:HUAM:764421_small.jpg',
      Description:
        '#4 Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.',
    },
    {
      id: 184,
      Title: 'Annie, Seated',
      ArtworkID: '141355',
      Image_url:
        'https://interwovenprodstorage.blob.core.windows.net/images/RISD_757071_205418_small.jpg',
      Description:
        '#5 Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.',
    },
    {
      id: 185,
      Title: 'An Interlude',
      ArtworkID: '163470',
      Image_url:
        'https://interwovenprodstorage.blob.core.windows.net/images/SMITHSONIAN_edanmdm-saam_1909.7_small.jpg',
      Description:
        '#6 Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.',
    },
    {
      id: 186,
      Title:
        'Study of Drapery, from a Picture by Paul Veronese, in the Uffizi Gallery at Florence',
      ArtworkID: '146476',
      Image_url:
        'https://interwovenprodstorage.blob.core.windows.net/images/HARVARD_306230_urn-3:HUAM:74046_dynmc_small.jpg',
      Description:
        '#7 Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.',
    },
  ],
}
