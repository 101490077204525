import * as graphStyles from './description.style'
import { classes } from 'typestyle'
import { useState } from 'react'
import Control from '../control/control.component'
import { decodeHTMLString } from '../../utils'
import './description.style.css'

const Description = ({
  children,
  onClickShare,
  onClickInfo,
  onClickFilter,
  onClickGroup,
  onClickViewMetadata,
  isJourney,
  onSlide,
  show,
  item,
  isMobile,
}) => {
  const [collapsed, setCollapsed] = useState(true)
  const { PrimaryTitle } = item

  const onToggleDescriptionHandle = () => {
    setCollapsed(!collapsed)
  }
  const onClickGroupBtn = (name, e) => {
    onClickGroup && onClickGroup(name, e)
  }

  return (
    <div className={`${graphStyles.bottomContentClass}`} data-help-step='0'>
      <div className={graphStyles.journeyInfoClass}>
        <div className={graphStyles.artworkTitleClass}>
          {item.curator_name
            ? `${item.curator_name}: ${item.Title}`
            : 'Custom Journey'}

          <ul className={graphStyles.controls} data-help-step='1'>
            <li className={graphStyles.group}>
              <Control
                withBorder
                name={`network`}
                icon={`group-btns ${
                  show === 'network' ? 'active' : ''
                } icon-network`}
                onClick={onClickGroupBtn}
                title='Network View'
              />
            </li>

            <li className={graphStyles.group}>
              <Control
                withBorder
                name={`grid`}
                icon={`group-btns ${show === 'grid' ? 'active' : ''} icon-grid`}
                onClick={onClickGroupBtn}
                title='Grid View'
              />
            </li>

            {isJourney && (
              <li className={graphStyles.group}>
                <Control
                  withBorder
                  name={`fullscreen`}
                  icon={`group-btns ${
                    show === 'fullscreen' ? 'active' : ''
                  }  icon-immersive`}
                  onClick={onClickGroupBtn}
                  title='Immersive View'
                />
              </li>
            )}

            <li className={graphStyles.line}>
              <Control
                disabled={onSlide}
                withBorder
                name='filter'
                icon='icon-filter'
                onClick={onClickFilter}
                title='Show/Hide Filters'
              />
            </li>
            <li>
              <Control
                withBorder
                name='info'
                icon='icon-info'
                disabled={show === 'network' ? false : true}
                onClick={onClickInfo}
                title='Info Screens'
              />
            </li>
            <li
              className={`${graphStyles.group} ${
                isMobile ? graphStyles.line : ''
              }`}
            >
              <Control
                withBorder
                name='share'
                icon='icon-share'
                onClick={onClickShare}
                title='Share'
              />
            </li>
          </ul>
        </div>

        {onSlide && <hr className={graphStyles.onSlide} />}

        {!onSlide && (
          <div className={graphStyles.artworkDescriptionClass}>
            <div className={graphStyles.artworkDescriptionToggleClass}>
              <button
                className={graphStyles.artworkDescriptionToggleButtonClass}
                onClick={onToggleDescriptionHandle}
              >
                <span
                  className={`${graphStyles.icon} icon-${
                    collapsed ? 'add' : 'minus'
                  }`}
                />
                <div
                  className={`${graphStyles.header} ${
                    collapsed ? graphStyles.headerCollapsed : ''
                  }`}
                >
                  <h1>
                    {item.curator_name
                      ? 'Curators Notes'
                      : 'Artwork Description'}
                  </h1>
                  <div>
                    <h2>
                      {decodeHTMLString(
                        PrimaryTitle || item?.title || 'Empty title'
                      )}
                      <span>
                        {item.artist ? ' by ' : ''}
                        <span className={''}>
                          {item.artist ? item.artist : ''}
                        </span>
                      </span>
                    </h2>

                    {!collapsed && item?.description && (
                      <div
                        className={classes(
                          graphStyles.artworkDescriptionDetailClass,
                          collapsed &&
                            graphStyles.artworkDescriptionDetailCollapsedClass
                        )}
                        dangerouslySetInnerHTML={{
                          __html: item.description
                            ? decodeHTMLString(item.description)
                            : '',
                        }}
                      ></div>
                    )}
                  </div>
                </div>
              </button>
            </div>
            {!collapsed && isMobile && (
              <button
                onClick={onClickViewMetadata}
                className={graphStyles.button}
              >
                VIEW METADATA
              </button>
            )}
          </div>
        )}
      </div>
      {children}
    </div>
  )
}

export default Description
