import { style, media } from 'typestyle'
import {
  MEDIA_BREAKPOINT_TABLET,
  DESKTOP_MAIN_MENU_HEIGHT_PX,
  DESKTOP_PAGE_LEFT_RIGHT_MARGINS_PX,
  FONT_FAMILY_GEOMETRIA,
  FOCUS_BACKGROUND_COLOR,
} from '../../constants'

const LOGO_MARGIN_PX = 20
const LINK_MARGIN_PX = 20
export const MAIN_MENU_SOLID_BG_RGB: string = '236, 236, 236'

export const mainMenuClass = style(
  {
    $debugName: 'MAIN_MENU',
    height: `${DESKTOP_MAIN_MENU_HEIGHT_PX}px`,
    width: '100%',
    position: 'fixed',
    left: 0,
    top: 0,
    padding: `0 ${DESKTOP_PAGE_LEFT_RIGHT_MARGINS_PX}px`,
    display: 'flex',
    flexDirection: 'row',
    flexWrap: 'nowrap',
    alignItems: 'center',
    zIndex: 1000,
    // backdropFilter: 'blur(5px)'
  },
  media(
    { maxWidth: MEDIA_BREAKPOINT_TABLET },
    {
      padding: '0 1.2rem 0 1.8rem',
      height: '51px',
    }
  )
)

export const overAll = style({
  $debugName: 'MAIN_MENU_OVER',
  zIndex: 1001,
})

export const mobileBorderContainerClass = style(
  {},
  media(
    { maxWidth: MEDIA_BREAKPOINT_TABLET },
    {
      width: '100%',
      marginLeft: '20px',
      marginRight: '20px',
    }
  )
)

export const sidesClass = style({
  flexShrink: 0,
  display: 'block',
})

export const leftClass = style({
  flexGrow: 1,
})

export const rightClass = style({
  flexGrow: 0,
})

export const hamburgerButtonClass = style(
  {
    position: 'relative',
    top: 0,
    right: '-0.5rem',
    zIndex: 1001,
    fontSize: '1.2rem',
    cursor: 'pointer',
    border: 'none',
    background: 'none',
    height: '21px',
    paddingRight: 0,
    display: 'block',
    color: 'black',
  },
  media(
    { minWidth: MEDIA_BREAKPOINT_TABLET },
    {
      display: 'none',
    }
  )
)

export const logoListClass = style({
  margin: `0 -${LOGO_MARGIN_PX / 2}px`,
  display: 'flex',
  justifyContent: 'flex-start',
  alignItems: 'center',
  flexDirection: 'row',
  flexWrap: 'nowrap',
})

export const logoClass = style(
  {
    margin: `0 ${LOGO_MARGIN_PX / 2}px`,
    display: 'none',

    $nest: {
      '&:focus': {
        outline: 'none',
        background: FOCUS_BACKGROUND_COLOR,
      },
    },
  },
  media(
    { minWidth: MEDIA_BREAKPOINT_TABLET },
    {
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      height: '35px',
      margin: '0 5px',
      padding: '0 15px',
    }
  )
)

export const mainLogoClass = style(
  {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    margin: `0 ${LOGO_MARGIN_PX / 2}px 0 0`,
    height: '35px',

    $nest: {
      '&:focus': {
        outline: 'none',
        background: FOCUS_BACKGROUND_COLOR,
      },
    },
  },
  media(
    { minWidth: MEDIA_BREAKPOINT_TABLET },
    {
      margin: '0 5px 0 -15px',
      padding: '0 15px',
    }
  )
)

export const navClass = style(
  {
    display: 'none',
    position: 'fixed',
    background: '#ffffff',
    width: '100%',
    left: 0,
    top: 0,
    height: '100%',
    padding: '30px 0',
  },
  media(
    { minWidth: MEDIA_BREAKPOINT_TABLET },
    {
      position: 'relative',
      width: 'auto',
      height: 'auto',
      left: 'auto',
      top: 'auto',
      display: 'block',
      textAlign: 'right',
      background: 'transparent',
      margin: `0 -${LINK_MARGIN_PX}px`,
      padding: 0,
    }
  )
)

export const navLinkClass = style(
  {
    textTransform: 'uppercase',
    fontFamily: FONT_FAMILY_GEOMETRIA,
    textDecoration: 'none',
    color: '#6B6B6B',
    cursor: 'pointer',
    display: 'block',
    padding: '10px 15px',
    textAlign: 'center',
    fontSize: '20px',

    $nest: {
      '&:focus-visible': {
        outline: 'none',
        background: FOCUS_BACKGROUND_COLOR,
      },
      '&:focus': {
        outline: 'none',
      },
      '&:hover': {
        textDecoration: 'underline',
      },
    },
  },
  media(
    { minWidth: MEDIA_BREAKPOINT_TABLET },
    {
      margin: `0 5px`,
      fontSize: '12px',
      height: '35px',
      lineHeight: '35px',
      display: 'inline-block',
      padding: '0 15px',
    }
  )
)

export const activeMobileNavClass = style({
  display: 'flex !important',
  flexDirection: 'column',
  justifyContent: 'space-evenly',
})

export const backgroundClass = style(
  {
    position: 'absolute',
    left: 0,
    top: 0,
    height: '70x',
    width: '100%',
    pointerEvents: 'none',
    background:
      'linear-gradient(180deg, rgba(236, 236, 236, 0.8) 48.32%, rgba(236, 236, 236, 0) 92.22%)',
    zIndex: -1,
  },
  media(
    { maxWidth: MEDIA_BREAKPOINT_TABLET },
    {
      height: '70px',
    }
  )
)

export const mainMenuSolidClass = style({
  backgroundColor: `rgb(${MAIN_MENU_SOLID_BG_RGB})`,
})
