import { memo, useEffect, useState } from 'react'
import artworkNavStyles from './artworkNav.module.scss'
import HScroll from '../hScroll/hScroll.component'

import { connect } from 'react-redux'
import { createStructuredSelector } from 'reselect'
import { actions, selectors } from '../../state/modules'

import { clickableActiveClass, clickableClass } from './artworkNav.style'
import { classes } from 'typestyle'
import { replaceImageName } from '../inmersiveView/inmersiveView.utils'

const ArtworkNav = ({
  current,
  isMobile,
  artworksSelected,
  onSelect,
  onDelete,
  isJourney,
  handleScrollClick,
  artworkIsLoading,
}) => {
  const [item, setItem] = useState({ position: 0, width: 0 })
  const isCurrent = (id, currentId) => currentId?.toString() === id?.toString()
  const itemContainer = document.querySelector(
    `.${artworkNavStyles.artworkNavItemContainer}`
  )

  // eslint-disable-next-line
  useEffect(() => {
    if (itemContainer) {
      artworksSelected.forEach((art, key) => {
        art.id === current.id &&
          setItem({ position: key, width: itemContainer.offsetWidth })
      })
    }
  }, [current.id, itemContainer])

  const handleButton = (e, row, key) => {
    e.preventDefault()

    const currentPos = { mouseX: e.clientX, mouseY: e.clientY - 20 }
    onSelect && onSelect(row, { ...e, currentPos }, key)
  }

  const handleClickArrow = (direction, nextPosition) => {
    handleScrollClick(
      artworksSelected[nextPosition],
      { type: 'click' },
      nextPosition
    )
  }

  //console.log('artworkIsLoading', artworkIsLoading)

  return (
    <div className={artworkNavStyles.artworkNavWrapper}>
      <div className={artworkNavStyles.artworkNavDesc}>
        <span>
          <b>{isJourney ? item.position + 1 : artworksSelected.length}</b>
          {' - '}
          {isJourney
            ? artworksSelected.length
            : process.env.REACT_APP_TOTAL_SELECTED}{' '}
          Artworks
        </span>
      </div>
      <HScroll
        item={item}
        isMobile={isMobile}
        quantity={artworksSelected.length}
        onClickArrow={handleClickArrow}
      >
        <div className={artworkNavStyles.artworkNavLine}>
          {artworksSelected.map((row, key) => {
            return (
              <div
                key={key}
                className={artworkNavStyles.artworkNavItemContainer}
              >
                {onDelete && (
                  <button
                    type='button'
                    onClick={() => onDelete(key, artworksSelected.length)}
                    className={`${artworkNavStyles.delete} icon-close`}
                  ></button>
                )}
                <div
                  className={`${artworkNavStyles.artworkNavItem} ${
                    isCurrent(row.id, current.id) ? artworkNavStyles.noLine : ''
                  }`}
                >
                  <button
                    type='button'
                    className={classes(
                      clickableClass,
                      isCurrent(row.id, current.id) ? clickableActiveClass : ''
                    )}
                    disabled={artworkIsLoading}
                    onClick={e => handleButton(e, row, key)}
                    onMouseOver={e => handleButton(e, row, key)}
                    onMouseLeave={e => handleButton(e, row, key)}
                    aria-label={row.title}
                  >
                    <img
                      className={`nav-image`}
                      src={replaceImageName(row.img || row.image_url)}
                      alt={row.title}
                    />
                  </button>
                </div>
              </div>
            )
          })}
        </div>
      </HScroll>
    </div>
  )
}

const mapStateToProps = createStructuredSelector({
  artworksSelected: selectors.artwork.selectArtworkSelected,
})

const mapDispatchToProps = dispatch => ({
  addSelectedArtwork: artwork => dispatch(actions.artwork.addArtwork(artwork)),
})

export default memo(connect(mapStateToProps, mapDispatchToProps)(ArtworkNav))
