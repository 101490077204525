import {
  MAPCircleLogo as logo,
  chicago,
  cleveland,
  cooper,
  harvard,
  met,
  mia,
  paris,
  rietberg,
  risd,
  rom,
  smithsonian,
  va,
  weavers,
} from '../../assets'

export const setLogo = museumId => {
  const data = { logo, heigth: 12, width: 100, link: null }

  switch (museumId) {
    case 'CHICAGO':
      data.logo = chicago
      data.link = 'https://mcachicago.org/'
      data.width = 65

      break
    case 'CLEVELAND':
      data.logo = cleveland
      data.link = 'https://www.clevelandart.org/'
      data.width = 50
      break
    case 'COOPERHEWITT':
      data.logo = cooper
      data.link = 'https://www.cooperhewitt.org/'
      data.width = 60
      break
    case 'HARVARD':
      data.logo = harvard
      data.link = 'https://harvardartmuseums.org/'
      data.width = 50
      break
    case 'MET':
      data.logo = met
      data.link = 'https://www.metmuseum.org/'
      data.width = 40
      break
    case 'MIA':
      data.logo = mia
      data.link = 'https://new.artsmia.org/'
      data.width = 70
      break
    case 'PARIS':
      data.logo = paris
      data.link = 'http://www.parismusees.paris.fr/en'
      data.width = 40
      break
    case 'RIETBERG':
      data.logo = rietberg
      data.link = 'https://rietberg.ch/en/'
      data.width = 120
      break
    case 'RISD':
      data.logo = risd
      data.link = 'https://risdmuseum.org/'
      data.width = 85
      break
    case 'ROM':
      data.logo = rom
      data.link = 'https://www.rom.on.ca/en'
      data.width = 50
      break
    case 'SMITHSONIAN':
      data.logo = smithsonian
      data.link = 'https://www.si.edu/'
      data.width = 135
      break
    case 'VA':
      data.logo = va
      data.link = 'https://www.vam.ac.uk/'
      data.width = 50
      break
    case 'WEAVERS':
      data.logo = weavers
      data.link = 'https://weaversstudio.in/'
      data.width = 100
      break
    //MAP LOGO
    default:
      data.logo = logo
      data.link = 'https://map-india.org/'
      break
  }
  return data
}

export const empty = val => {
  // test results
  //---------------
  // []        true, empty array
  // {}        true, empty object
  // null      true
  // undefined true
  // ""        true, empty string
  // ''        true, empty string
  // 0         false, number
  // true      false, boolean
  // false     false, boolean
  // Date      false
  // function  false

  if (val === undefined) return true

  if (
    typeof val == 'function' ||
    typeof val == 'number' ||
    typeof val == 'boolean' ||
    Object.prototype.toString.call(val) === '[object Date]'
  )
    return false

  if (val == null || val === 'null' || val.length === 0)
    // null or 0 length array
    return true

  if (typeof val == 'object') {
    // empty object

    let r = true

    for (let f in val) r = false

    return r
  }

  return false
}

export const capitalizeFirstLetter = string => {
  return string.charAt(0).toUpperCase() + string.slice(1)
}
