import { useEffect, useRef, useState } from 'react'
import { Helmet } from 'react-helmet-async'
import CardList from '../../components/cardList/cardList.component'
import Filters from '../../components/filters/filters.component'
import pageStyles from './artworks.page.module.scss'
import Feautures from '../../components/feautures/feautures.component'
import Properties from '../../components/properties/properties.component'
import { useHistory } from 'react-router'
import Modal from '../../components/modal/modal.component'
import { connect } from 'react-redux'
import { createStructuredSelector } from 'reselect'
import { actions, selectors } from '../../state/modules'
import Loading from '../../components/loading/loading.component'
import useMediaQuery from '../../hooks/useMediaQuery.hook'
import { useIntersectionObserver } from '../../hooks/useInteactionObserver.hook'
import ModalLarge from '../../components/modalLarge/modalLarge.component'
import useModal from '../../hooks/useModal.hook'
import { TabArea } from '../../tabArea.service'

import Service from '../../services'
import { removeUnSelected } from '../../components/filters/filters.utils'
import { shuffle } from '../../state/modules/artwork/artwork.utils'

const mapMuseum = [
  {
    name: 'Museum',
    options: [{ value: 'MAP' }],
  },
]

const Ser = Service()
const apiPost = new Ser.API_POST()

const artsPerPage = Number(process.env.REACT_APP_ITEMS_PER_PAGE) || 20

const getData = ({ body, top, skip }) => {
  return apiPost.getArtworks(body, top, skip)
}

let tabGroup
let focusedBackgroundElement

const initialState = {
  count: 1,
  arts: [],
  currentLoaded: [],
  current: null,
  total: 0,
  pagination: {
    totalPages: 0,
    skip: 0,
    top: artsPerPage,
  },
  isLoading: false,
}

const Artworks = ({
  filtersList,
  selectedFilters,
  updateListFilters,
  cleanFilters,
}) => {
  const { isShowing, toggle } = useModal()
  //const isMobile = useMediaQuery(`(max-width: 960px`)
  const isMobile = useMediaQuery(960)

  const [state, setState] = useState({
    ...initialState,
  })
  const loadingRef = useRef(null)
  const history = useHistory()
  const modalRef = useRef(null)

  const isBottomVisible = useIntersectionObserver(
    loadingRef,
    {
      threshold: 0,
    },
    false
  )
  useEffect(() => {
    cleanFilters()
  }, [])
  useEffect(() => {
    const modal = modalRef?.current
    if (modal) {
      //modal.style.opacity = 0
      window.scrollTo(0, 0)
    }
  }, [modalRef])

  useEffect(() => {
    isBottomVisible && handleObserv({ ...state.pagination, filtered: false })
  }, [isBottomVisible])

  const handleClose = () => {
    //const modal = modalRef.current || { style: {} }
    //modal.style.opacity = 0

    setState({ ...state, current: null })

    //setTimeout(() => {
    //modal.style.display = 'none'
    //}, 50)

    if (tabGroup) {
      tabGroup.restore()
      tabGroup = null
    }
  }

  const handleModalLargeClick = encodedId => {
    history.push(`/journey?q=${encodedId}`)
    cleanFilters && cleanFilters(true)
  }

  const handleObserv = () => {
    if (!state.isLoading) {
      setState({ ...state, isLoading: true })
      try {
        getData({
          body: [...mapMuseum, ...removeUnSelected(filtersList)],
          top: artsPerPage,
          skip: state.pagination.skip,
        })
          .then(response => response.data)
          .then(data => {
            if (data) {
              const total = data.stats['@odata.count']
              const totalPages = Math.ceil(total / data.stats.top)
              const skip = state.count * artsPerPage

              setState({
                ...state,
                count: state.count + 1,
                isLoading: false,
                arts: [...state.arts, ...shuffle(data.items)],
                currentLoaded: data,
                pagination: {
                  totalPages,
                  skip,
                  top: artsPerPage,
                },
                total,
              })
            } else {
              throw new Error('empty')
            }
          })
          .catch(error => {
            throw new Error(error)
          })
      } catch (error) {
        console.log(error)
      }
    }
  }

  const handleFilterChange = current => {
    window.scrollTo(0, 0)
    setState({ ...initialState })
  }

  const handleTagsClick = ({ name, current }) => {
    updateListFilters(current)
    setState({ ...initialState })
    window.scrollTo(0, 0)
  }

  const handleClickCard = (curr, card) => {
    setState({ ...state, current: curr })

    const modal = modalRef.current || { style: {} }

    //modal.style.display = 'block'
    modal.style.position = isMobile ? 'fixed' : 'absolute'
    //modal.style.zIndex = 100

    setTimeout(() => {
      const dl = modal.querySelector('#modal-scroll')
      if (isMobile && dl) {
        dl.style.maxHeight = '35vh'
      }
      modal.style.width = isMobile
        ? '100%'
        : card.current.offsetWidth - 20 + 'px'

      modal.style.top = isMobile
        ? '2.5rem'
        : card.position.y - card.vertical + 'px'
      modal.style.left = isMobile
        ? 0
        : card.position.x +
          modal.clientWidth * card.direction +
          (card.direction === -1 ? -10 : 30) +
          'px'
    }, 400)

    // code for navigation using TAB key
    setTimeout(() => {
      if (modalRef.current && document.activeElement) {
        focusedBackgroundElement = document.activeElement
        tabGroup = new TabArea(modalRef.current, document.activeElement)

        tabGroup.onGoBack = handleClose
        tabGroup.onGoNext = handleClose
      }
    }, 1000)
  }

  return (
    <>
      <Helmet>
        <meta name='robots' content='noindex' />
      </Helmet>
      <div className={pageStyles.main}>
        <div className={pageStyles.left}>
          <Filters
            loading={state.isLoading}
            isMobile={isMobile}
            onChange={handleFilterChange}
          />
        </div>
        <div className={pageStyles.right}>
          <div className={pageStyles.wrapperFeautures}>
            <Feautures amount={state.total} loading={state.isLoading}>
              <div
                className={
                  selectedFilters.length > 0 ? pageStyles.childrens : ''
                }
              >
                {selectedFilters?.map((filter, key) => (
                  <Properties
                    key={key}
                    current={filter.current}
                    name={filter.value}
                    count={filter.count}
                    onClick={handleTagsClick}
                  />
                ))}
              </div>
            </Feautures>
          </div>
          <div
            className={`${pageStyles.wrapperList} ${
              selectedFilters.length > 0 ? pageStyles.wrapperTop : ''
            }`}
          >
            <CardList
              artworks={state.arts}
              cleanFilters={cleanFilters}
              breakpointColumns={{
                default: 4,
                960: 3,
                500: 2,
              }}
              paddingTop={
                isMobile ? 0 : selectedFilters.length > 0 ? '11.6rem' : '8.1rem'
              }
              onClickCard={handleClickCard}
            />

            <div
              ref={loadingRef}
              id='observable'
              style={{
                width: '100%',
                height: '30vh',
                textAlign: 'center',
                paddingTop:
                  state.arts.length < 1 ? (isMobile ? '3rem' : '25vh') : '3rem',
              }}
            >
              {state.isLoading && (
                <Loading
                  height={isMobile ? '65vh' : '20vh'}
                  showLogo
                  small
                  showMicrosoftMessage
                />
              )}
            </div>
          </div>

          <Modal
            ref={modalRef}
            node={state.current}
            onClick={handleClose}
            showSelectArtwork={true}
            showImage={isMobile}
            isMobile={isMobile}
            onClickOpenLargeModal={node => {
              //setCurrent(node)
              setState({ ...state, current: node })
              toggle()

              // CODE FOR PAGE NAVIGATION USING TAB KEY
              setTimeout(() => {
                if (tabGroup) {
                  tabGroup.restore()
                }

                const largeModalElement = document.getElementById('large-modal')

                if (largeModalElement && focusedBackgroundElement) {
                  tabGroup = new TabArea(
                    largeModalElement,
                    focusedBackgroundElement
                  )
                }
              }, 400)
            }}
            showLargeImageButton
          />

          {state.current && (
            <ModalLarge
              isShowing={isShowing}
              showLoading
              hide={toggle}
              id={state.current.id}
              image={state.current.primary_image || state.current.image_url}
              onClick={handleModalLargeClick}
              {...state.current}
            />
          )}
        </div>
      </div>
    </>
  )
}

const mapStateToProps = createStructuredSelector({
  selectedFilters: selectors.filter.selectFiltersSelected,
  filtersList: selectors.filter.selectFilterList,
})

const mapDispatchToProps = dispatch => ({
  loadArtworksList: params => dispatch(actions.artwork.setListArtwork(params)),
  updateListFilters: currentSelected =>
    dispatch(actions.filter.updateListFilters(currentSelected)),
  updateListFiltersCounts: filters =>
    dispatch(actions.filter.updateListFiltersCounts(filters)),
  addNewPage: data => dispatch(actions.artwork.addListPage(data)),
  resetPagination: () => dispatch(actions.artwork.resetPagination()),
  cleanFilters: cleanAll => dispatch(actions.filter.cleanFilters(cleanAll)),
})

export default connect(mapStateToProps, mapDispatchToProps)(Artworks)
