import React from 'react';
import { CursorPosition, HighlightPosition } from './helpScreen.canvas';
import { safeAreaTop } from './helpScreen.utils';

type highlightPositionFunction = () => HighlightPosition;
type CursorPositionFunction = () => CursorPosition;

export type ContentPosition = 'top' | 'left' | 'right' | 'bottom';
export type HighlightMargin = (
    number
    | [verticalMargin: number, horizontalMargin: number]
    | [topMargin: number, horizontalMargin: number, bottomMargin: number]
    | [topMargin: number, rightMargin: number, bottomMargin: number, leftMargin: number]
);

export type StepConfig = {
    highlightPosition: highlightPositionFunction,
    highlightMargin: HighlightMargin,
    topContentBackground?: boolean,
    contentPosition: ContentPosition,
    contentWidth: number,
    content: React.ReactElement,
    hasArrow: boolean,
    cursor: CursorPosition | CursorPositionFunction,
    title: string
};

const elementPosition = (
    selector: string,
    processCallback?: (result: [number, number, number, number]) => [number, number, number, number])
: highlightPositionFunction => {
    return () => {
        const elements = document.querySelectorAll<HTMLElement>(selector);

        if (elements.length) {
            const result: HighlightPosition = [window.innerWidth, window.innerHeight, 0, 0];
            let maxLeft: number = 0;
            let maxTop: number = 0;

            Array.prototype.forEach.call(elements, (element: HTMLElement) => {
                const elementRect = element.getBoundingClientRect();

                result[0] = Math.min(result[0], elementRect.left);
                result[1] = Math.min(result[1], elementRect.top);
                maxLeft = Math.max(maxLeft, elementRect.left + elementRect.width);
                maxTop = Math.max(maxTop, elementRect.top + elementRect.height);
            })

            result[2] = maxLeft - result[0];
            result[3] = maxTop - result[1];

            return processCallback ? processCallback(result) : result;
        }

        return [0, 0, 0, 0];
    };
};

const elementCenter = (selector: string): CursorPositionFunction => {
    return () => {
        const element = document.querySelector(selector);

        if (element) {
            const rect = element.getBoundingClientRect();

            return [
                rect.left + (rect.width / 2),
                rect.top + (rect.height / 2),
                true
            ];
        }

        return [20, 20];
    }
};

const elementRectValue = (selector: string, rectKey: keyof DOMRect): number => {
    const element = document.querySelector(selector);

    if (element) {
        return Number(element.getBoundingClientRect()[rectKey]);
    }

    return 0;
};

const centeredPosition = (): highlightPositionFunction => {
    return () => {
        const maxWidth = window.innerWidth;
        const squareWidth = .4 * maxWidth;

        return [
            (maxWidth - squareWidth) / 2, safeAreaTop, squareWidth, 300];
    };
};

export const helpSteps: StepConfig[] = [
    {
        highlightPosition: elementPosition('[data-help-step="0"]'),
        highlightMargin: -10,
        contentPosition: 'top',
        contentWidth: 300,
        hasArrow: true,
        cursor: elementCenter('img.nav-image'),
        title: 'Select an artwork',
        content: (
            <>
                <p>Select an artwork from the thread to view connected artworks</p>
                <p>Hit tab to select next artwork in thread, or shift tab to reverse</p>
            </>
        )
    },
    {
        highlightPosition: elementPosition('[data-help-step="1"]'),
        highlightMargin: [5, 8],
        contentPosition: 'left',
        contentWidth: 200,
        hasArrow: true,
        cursor: [178, 20],
        title: 'Open Filters Menu',
        content: (
            <p>Click or tap on the filter icon to view Connectors and Differentiators menu.</p>
        )
    },
    {
        highlightPosition: elementPosition('[data-help-step="2"]', (position) => {
            const topFix = elementRectValue('[data-help-step="0"]', 'top');
            const verticalArea = position[1] + position[3];

            if (verticalArea > topFix) {
                position[3] = window.innerHeight - (window.innerHeight - topFix) - position[1];
            }

            return position;
        }),
        highlightMargin: 0,
        contentPosition: 'right',
        contentWidth: 200,
        hasArrow: true,
        cursor: elementCenter('[data-help-step="2"] > div > div'),
        title: 'Select Filters',
        content: (
            <>
                <p>Click or tap filters to highlight clusters of connected artworks.</p>
                <p>Press [TAB] on your keyboard and hit [ENTER] to select.</p>
            </>
        )
    },
    {
        highlightPosition: elementPosition('.scene-container', (position) => {
            position[3] = elementRectValue('[data-help-step="0"]', 'top');
            return position;
        }),
        highlightMargin: [-10, -10, -10, -10],
        topContentBackground: true,
        contentPosition: 'bottom',
        contentWidth: 500,
        hasArrow: false,
        cursor: elementCenter('.scene-container'),
        title: 'Explore using your mouse',
        content: (
            <>
                <p>Scroll or pinch to zoom. Click or tap to move around the network. Dragging your finger or cursor pans and rotates.</p>
                <p>Keyboard users: Use [+] and [-] to zoom, arrows to pan, [r] to rotate.</p>
            </>
        )
    },
    /*{
        highlightPosition: elementPosition('[data-help-step="1"]'),
        contentPosition: 'left',
        contentWidth: 300,
        hasArrow: true,
        cursor: [80, 20],
        title: 'Explore using your keyboard',
        content: (
            <p>Alternately you can explore the 3D network by opening the 360 navigation tools.</p>
        )
    },*/
    {
        highlightPosition: (() => {
            const wrapperSelector = 'ul.controls_styles_wrapper__2E_2n';
            return elementPosition(
                wrapperSelector + ' > li:nth-child(1), ' +
                wrapperSelector + ' > li:nth-child(2), ' +
                wrapperSelector + ' > li:nth-child(3), ' +
                wrapperSelector + ' > li:nth-child(4), ' +
                wrapperSelector + ' > li:nth-child(5), ' +
                wrapperSelector + ' > li:nth-child(6), ' +
                wrapperSelector + ' > li:nth-child(7) '
            );
        })(),
        highlightMargin: [8, 5],
        contentPosition: 'left',
        contentWidth: 200,
        hasArrow: true,
        cursor: [20, 120],
        title: 'Explore using your keyboard',
        content: (
            <p>Use the 360 navigation tools to explore the network in 3D</p>
        )
    },
    {
        highlightPosition: elementPosition('[data-help-step="1"]'),
        highlightMargin: [5, 8],
        contentPosition: 'left',
        contentWidth: 240,
        hasArrow: true,
        cursor: [65, 20],
        title: 'Select alternate views',
        content: (
            <p>View connected artworks in 3D or in a grid view</p>
        )
    }
];
