import { style, media } from 'typestyle'
import mediaSizes from '../../scss/modules/sizes.module.scss'
import {
  DESKTOP_MAIN_MENU_HEIGHT_PX,
  DESKTOP_PAGE_LEFT_RIGHT_MARGINS_PX,
  //MEDIA_BREAKPOINT_TABLET,
} from '../../constants'

export const gridViewContainerClass = style(
  {
    padding: '0 0 35vh',
    margin: '0 .8rem 0 22%',
    display: 'flex',
  },
  media(
    { maxWidth: 1280 },
    {
      marginLeft: '33%',
    }
  ),
  media(
    { maxWidth: mediaSizes.tabletScreen }, // 900
    {
      marginLeft: 0,
      marginRight: 0,
    }
  )
)

export const closed = style({
  margin: '0 .8rem 0 1.4%',
})

export const group = style(
  {
    margin: 0,
  },
  media(
    { maxWidth: mediaSizes.tabletScreen },
    {
      display: 'block',
    }
  )
)

export const current = style({
  marginTop: '1rem',
  border: '4px solid #8CC6C0',
  padding: 0,
  backgroundColor: 'white',
  borderRadius: '0',
})

export const currentMobile = style({
  border: '4px solid #8CC6C0',
  padding: 0,
  backgroundColor: 'white',
  borderRadius: '0',
  margin: '7rem auto .5rem',
  width: '60%',
})
export const gridViewLeft = style({
  zIndex: 1,
  overflow: 'auto',
  flex: '1 1 25%',
  paddingTop: '64px',
  //$nest: {
  //img: {
  //zIndex: 2,
  //position: 'fixed',
  //},
  //},
})

export const gridViewRight = style(
  {
    flex: '1 1 75%',
  },

  media(
    { maxWidth: 1280 },
    {
      flex: '1 1 68%',
    }
  ),
  media(
    //{ maxWidth: 900 },
    { maxWidth: mediaSizes.tabletScreen },
    {
      //marginTop: '13rem',
      flex: '1 1 100%',
    }
  )
)

export const artworkItemClass = style(
  {
    display: 'inline-block',
    boxSizing: 'border-box',
    padding: '0 2%',
    width: '100%',
    verticalAlign: 'middle',
    $nest: {
      img: { maxHeight: '300px' },
      button: {
        cursor: 'pointer',
        border: 'none',
        background: 'transparent',
      },
    },
  },
  media(
    { minWidth: 600 },
    {
      width: '50%',
    }
  ),
  media(
    { minWidth: 800 },
    {
      width: '25%',
    }
  ),
  media(
    { minWidth: 1000 },
    {
      width: '20%',
    }
  ),
  media(
    { minWidth: 1200 },
    {
      width: '10%',
    }
  )
)

export const artworkImageClass = style({
  margin: '15px auto',
  padding: 0,
  border: 0,
  display: 'block',
  position: 'relative',
  boxShadow: '0px 15px 20px rgba(0, 0, 0, 0.15)',
})

export const gridViewInfoContainerClass = style({
  display: 'none',
  position: 'fixed',
  top: `${DESKTOP_MAIN_MENU_HEIGHT_PX + 15}px`,
  zIndex: 100,
  left: `${DESKTOP_PAGE_LEFT_RIGHT_MARGINS_PX}px`,
  pointerEvents: 'none',
  //transform: 'scale(.9)',
  //opacity: .8,
  transition: '800ms',
})

export const gridViewInfoHoveredContainerClass = style({
  display: 'block',
})

export const gridViewInfoSelectedContainerClass = style({
  display: 'block',
  opacity: 1,
  transform: 'scale(1)',
  pointerEvents: 'auto',
})
