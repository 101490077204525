import Graph3d from '../../components/graph3d/graph3d.component'
import ArtworkNav from '../../components/artworkNav/artworkNav.component'
import { useCallback, useEffect, useRef, useState } from 'react'
import Content from '../../components/content/content.component'
import Modal from '../../components/modal/modal.component'

import InmersiveView from '../../components/inmersiveView/inmersiveView.component'
import { mapAcademyOne } from '../../assets'
import Loading from '../../components/loading/loading.component'
import Filters from '../../components/filters/filters.component'
import { removeUnSelected } from '../../components/filters/filters.utils'
import { connect } from 'react-redux'
import { createStructuredSelector } from 'reselect'
import { actions, selectors } from '../../state/modules'
import { useQuery } from './artwork.page.utils'
import { encodeIds, decodeIds } from '../../utils'
import { Helmet } from 'react-helmet-async'
import SaveJourneyAdminOnly from '../../components/saveJourneyAdminOnly/saveJourneyAdminOnly.component'
import Description from '../../components/description/description.component'
import { useHistory } from 'react-router'

import ShareJourney from '../../components/shareScreen/shareScreen.component'
import { createBrowserHistory } from 'history'
import artworkPageStyles from './artwork.page.module.scss'
import useMediaQuery from '../../hooks/useMediaQuery.hook'
import HelpScreen from '../../components/helpScreen/helpScreen.component'
import ModalLarge from '../../components/modalLarge/modalLarge.component'
import useModal from '../../hooks/useModal.hook'
import Service from '../../services'
import ShareCards from '../../components/shareCards/shareCards.component'
import { parseSliderData } from '../home-page/home-page.utils'
import { replaceImageName } from '../../components/inmersiveView/inmersiveView.utils'
import { TabArea } from '../../tabArea.service'
//
const Ser = Service()
const apiPost = new Ser.API_POST()

export const getJourneyPath = () => {
  const history = createBrowserHistory()
  return history.location.pathname + history.location.search
}

const initialModalState = {
  congrats: false,
  remove: false,
  share: false,
  curated: false,
  data: { name: '', current: { img: '' } },
}

const helpScreenLocalStorageName = 'interwovenSkipHelpSreen'
let tabAreaArtworkPage = null

const restoreFocus = () => {
  if (tabAreaArtworkPage) {
    tabAreaArtworkPage.restore()
    tabAreaArtworkPage = null
  }
}

const Artwork = ({
  currentRelated,
  currentRelatedLoading,
  loadCurrentRelated,
  clearCurrentRelated,
  clearCurrentSelected,
  addSelectedArtwork,
  setSelectedArtworks,
  deleteSelected,
  selectedArtworks,
  filtersList,
  changeView,
  show,
  journeyList,
  cleanFilters,
}) => {
  const modalRef = useRef(null)
  const innerViewRef = useRef(null)
  const isMobile = useMediaQuery(960)
  const { isShowing, toggle } = useModal()
  const loadingRef = useRef(null)
  const totalSelected = process.env.REACT_APP_TOTAL_SELECTED

  const [modalImage, setModalImage] = useState(true)
  const [onlyImage, setOnlyImage] = useState(false)
  const [currentNode, setCurrentNode] = useState(null)
  const [preview, setPreview] = useState(null)
  const [curatedModal, setCuratedModal] = useState({
    curator_name: '',
    Description: '',
    short_description: '',
    Artworks: [],
  })
  const [currentJourney, setCurrentJourney] = useState(null)
  const [onSlide, setOnSlide] = useState(false)
  const [lounched, setLounched] = useState(null)
  const [isFilterChanged, setIsFilterChanged] = useState(false)

  const [modal, setModal] = useState({ ...initialModalState })

  const [closeFilters, setCloseFilters] = useState(false)
  const [closedControls, setClosedControls] = useState(false)
  const [showHelpScreen, setShowHelpScreen] = useState(false)

  const history = useHistory()
  const query = useQuery()
  const queryIds = query.get('q')
  const curatedId = query.get('id')
  const ids = queryIds ? decodeIds(queryIds) : []
  const currentId = query.get('c') || ids[0]

  const currentIds = selectedArtworks.map(artwork => artwork.id)

  useEffect(() => {
    if (selectedArtworks.length > 0) updateIds()
  }, [selectedArtworks.length])

  useEffect(() => {
    /*clean current data*/
    clearCurrentSelected()
    clearCurrentRelated()
    cleanFilters()

    onSelectHandle(currentId, [...removeUnSelected(filtersList)])

    setSelectedArtworks(ids)
    showLoading()
    setShowHelpScreen(localStorage.getItem(helpScreenLocalStorageName) !== '1')
  }, [])

  useEffect(() => {
    if (curatedId) {
      const curated = journeyList.find(
        el => String(el.id) === String(curatedId)
      )
      //console.log('curated', curated)
      if (curated?.Artworks[0]) {
        setCurrentJourney({ ...curated })
        setCuratedModal({
          ...curated,
          title: curated.Artworks[0].Title,
          description: curated.Artworks[0].Description,
          short_description: curated.Artworks[0].short_description,
        })
        setModal({ ...modal, curated: true })
      } else {
        history.push('/')
      }
    }
  }, [curatedId])

  useEffect(() => {
    const tooltip = document.querySelector('.scene-tooltip')

    if (tooltip && !currentRelatedLoading) {
      let timer = 100
      const interval = setInterval(() => {
        timer--
        if (timer > 1) {
          tooltip.style.top = '2000rem'
        }
        if (timer <= 1) {
          clearInterval(interval)
        }
      }, 20)
    }
  }, [currentRelatedLoading])

  const showLoading = () => {
    const loading = loadingRef?.current

    if (loading && show !== 'fullscreen') {
      loading.style.transition = '.5s ease'
      loading.style.position = 'fixed'
      loading.style.width = '100vw'
      loading.style.top = 0
      loading.style.left = 0
      loading.style.zIndex = 20
      loading.style.display = 'block'
      loading.style.opacity = 1
    }
  }

  const hiddenLoading = () => {
    const loading = loadingRef?.current
    if (loading) {
      setTimeout(() => {
        loading.style.zIndex = 1
        loading.style.opacity = 0
        setTimeout(() => {
          loading.style.display = 'none'
        }, 300)
      }, 1800)
    }
  }

  useEffect(() => {
    setClosedControls(isMobile ? true : true)
  }, [isMobile])

  useEffect(() => {
    if (currentRelatedLoading && modalRef?.current) {
      modalRef.current.style.top = '-300vh'
      modalRef.current.style.position = 'fixed'
    }
    if (!currentRelatedLoading) {
      setIsFilterChanged(state => !state)
    }
  }, [modalRef, currentRelatedLoading, curatedId])

  const validateIsFinalModal = useCallback(() => {
    return selectedArtworks.length >= totalSelected ? true : false
  }, [selectedArtworks.length, totalSelected])

  const onSelectHandle = (row, data, key) => {
    const modal = modalRef?.current

    if (!currentRelatedLoading) {
      if (modal) {
        if (data?.type === 'click' || data?.type === 'slide') {
          showLoading()
          let clicked = true
          setCurrentNode(row)
          setPreview(row)

          curatedModal.Artworks.length > 0 &&
            setCuratedModal({
              ...curatedModal,
              title: curatedModal.Artworks[key].Title,
              description: curatedModal.Artworks[key].Description,
              short_description: curatedModal.Artworks[key].short_description,
            })

          if (key !== null) {
            const position = key + 1
            innerViewRef?.current?.slickGoTo(position, true)
          }

          if (clicked) {
            loadCurrentRelated(
              `${row?.id ? row.id : row}&top=${
                process.env.REACT_APP_TOTAL_RELATED
              }`,
              [...removeUnSelected(filtersList)]
            )
            clicked = false
          }
        } else if (data?.type === 'mouseover' && !isMobile) {
          //modal.style.display = 'block'
          modal.style.width = 'auto'

          setCurrentNode({ ...row, image_url: row.img || row.image_url })
          setOnlyImage(true)
          //
          if (data.currentPos) {
            modal.style.position = 'fixed'

            modal.style.left = data.currentPos.mouseX - 150 + 'px'
            modal.style.top =
              data.currentPos.mouseY -
              (data.target.naturalHeight + data.target.height) +
              'px'
          }
        } else if (data?.type === 'mouseleave') {
          //modal.style.display = 'none'
          setCurrentNode(null)
          setOnlyImage(false)
        }
      }

      if (Array.isArray(data) && !data?.type) {
        setCurrentNode(row)
        loadCurrentRelated(
          `${row?.id ? row.id : row}&top=${
            process.env.REACT_APP_TOTAL_RELATED
          }`,
          data
        )
      }
    }
  }

  const onDeleteHandle = (id, total) => {
    const howmany = Number(total) - Number(id)

    const current = selectedArtworks[id]
    //console.log('onDeleteHandle', current)
    setModal({
      ...modal,
      remove: true,
      data: { name: 'remove', id, howmany, current },
    })
  }

  const handleDeletedElement = () => {
    const { id, howmany } = modal.data
    const index = Number(id)
    //setDelteAction(!deleteAction)
    deleteSelected({ index, howmany })

    // redirect on delete timeline
    if (index > 0) {
      onSelectHandle(selectedArtworks[index - 1], { type: 'click' })
      setModal({ ...initialModalState })
    } else history.push('/artworks')
  }

  const handleFilterChange = filters => {
    onSelectHandle(currentRelated.item, [...removeUnSelected(filtersList)])
  }

  const handleFilterClose = () => {
    setCloseFilters(!closeFilters)
  }

  const updateIds = () => {
    if (curatedId) {
      history.push(`/journey?id=${curatedId}&q=${encodeIds(currentIds)}`)
    } else {
      history.push(`/journey?q=${encodeIds(currentIds)}`)
    }
  }

  //this is disable because the rotation
  const handleClickAddCustomJourney = node => {
    showLoading()
    addSelectedArtwork(node)
    onSelectHandle(node, [])
    setPreview(node)
    handleClose()

    if (selectedArtworks.length >= totalSelected) {
      setModal({ ...modal, congrats: true })
    }
  }

  const handleClose = () => {
    const tooltip = document.querySelector('.scene-tooltip')

    if (tooltip) {
      setTimeout(() => {
        tooltip.style.display = 'none'
        setCurrentNode(null)
        tooltip.style.display = isMobile ? 'none' : 'block'
      }, 100)
    }

    restoreFocus()
  }
  //

  const handleNodeClick = node => {
    setOnlyImage(false)
    setModalImage(true)

    const modal = modalRef?.current || { style: {} }

    const tooltip = document.querySelector('.scene-tooltip') || {
      style: { display: '' },
    }

    tooltip.style.display = 'none'

    setTimeout(() => {
      modal.style.position = 'fixed'
      modal.style.width = isMobile ? '100%' : '350px'

      modal.style.left = isMobile ? '0' : window.innerWidth / 2.5 + 'px'
      modal.style.top = isMobile ? '5rem' : window.innerHeight / 80 + '%'
    }, 10)

    if (!node.isParent && modal) {
      setCurrentNode(node)
    }
  }

  const handleMouseEventsGridView = mouseEvent => {
    const modal = modalRef?.current

    if (modal) {
      if (mouseEvent.event.type === 'mouseenter') {
        setOnlyImage(true)
      } else if (mouseEvent.event.type === 'click') {
        setCurrentNode(mouseEvent.node)
        setOnlyImage(false)
      } else if (mouseEvent.event.type === 'mouseout') {
        setCurrentNode(null)
      }

      if (onlyImage) {
        modal.style.position = 'fixed'
        modal.style.left = mouseEvent.currentPos.mouseX - 150 + 'px'
        modal.style.top = mouseEvent.currentPos.mouseY + 'px'
      }
    }
  }

  const handleSkipHelpScreen = () => {
    localStorage.setItem(helpScreenLocalStorageName, '1')
    setShowHelpScreen(false)
  }

  const handleCloseHelpScreen = () => {
    setShowHelpScreen(false)
  }

  const showAddButtonValidation = useCallback(() => {
    return (
      currentNode?.id !== currentRelated?.item?.id &&
      selectedArtworks[selectedArtworks.length - 1]?.id ===
        currentRelated?.item?.id &&
      !curatedId &&
      !validateIsFinalModal()
    )
  }, [
    currentRelated.item.id,
    currentNode?.id,
    curatedId,
    selectedArtworks,
    validateIsFinalModal,
  ])

  const handleChangeIndex = index => {
    //console.log('----index', index)
    //
    const position = index <= 0 ? 0 : index - 1
    const value = position < selectedArtworks.length
    if (selectedArtworks[position] && !currentRelatedLoading && value) {
      return onSelectHandle(
        selectedArtworks[position],
        { type: 'slide' },
        position
      )
    }
  }

  let clicked = false

  const handleClickCardInmersive = (curr, card) => {
    if (!clicked && curr.ArtworkID) {
      clicked = true
      apiPost
        .getRelatedArtworks(curr.ArtworkID, [])
        .then(response => response.data)
        .then(data => {
          setCurrentNode(data.item)
          setModalImage(isMobile)

          const modal = modalRef?.current

          if (modal) {
            const tooltip = document.querySelector('.scene-tooltip') || {
              style: { display: '' },
            }
            tooltip.style.display = 'none'

            setTimeout(() => {
              modal.style.position = 'fixed'
              modal.style.width = isMobile ? '100%' : '350px'
              modal.style.left = isMobile ? '0' : window.innerWidth / 2.5 + 'px'
              modal.style.top = isMobile
                ? '2.7rem'
                : window.innerHeight / 80 + '%'
              clicked = false
            }, 100)
          }
        })
        .catch(err => alert('No data: ' + err))
    }
  }

  const handleClickCard = (curr, card) => {
    setOnlyImage(false)
    setCurrentNode(curr)
    setModalImage(isMobile)

    const modal = modalRef?.current

    if (modal) {
      modal.style.position = isMobile ? 'fixed' : 'absolute'
      //curren element width
      modal.style.width = isMobile
        ? '100%'
        : card.current.offsetWidth - 20 + 'px'

      modal.style.top = isMobile
        ? '5rem'
        : card.position.y - card.vertical + 'px'

      modal.style.left = isMobile
        ? 0
        : card.position.x +
          modal.offsetWidth * card.direction +
          (card.direction === -1 ? -10 : 30) +
          'px'

      if (isMobile) {
        //modal.style.marginTop = '1.8rem'
      }

      setTimeout(() => {
        const dl = modal.querySelector('#modal-scroll')

        if (isMobile && dl) {
          dl.style.maxHeight = '30vh'
        }
      }, 200)
      // variant for new card outside the grid
      const interval = setInterval(() => {
        const modalWidth = modal.clientWidth
        if (modalWidth === modal.offsetWidth) {
          modal.style.left = isMobile
            ? 0
            : card.position.x +
              modal.offsetWidth * card.direction +
              (card.direction === -1 ? -10 : 30) +
              (closeFilters && card.columnCardWidth !== undefined ? 28 : 0) +
              (!closeFilters && card.columnCardWidth > 0 ? 5 : 0) +
              'px'
          // new width
          modal.style.width = isMobile
            ? '100%'
            : (card.columnCardWidth !== undefined
                ? card.columnCardWidth
                : card.current.offsetWidth - 20) + 'px'
          clearInterval(interval)
        }
      }, 600)

      setTimeout(() => {
        if (modalRef) {
          tabAreaArtworkPage = new TabArea(modalRef.current)
        }
      }, 1000)
    }
  }

  const handleClickGroup = (name, e) => {
    changeView(name)
    setCurrentNode(null)

    if (name === 'fullscreen' && loadingRef?.current) {
      window.scrollTo(0, 0)
      setCloseFilters(true)
      setOnSlide(true)
      setModalImage(true)

      loadingRef.current.style.opacity = 1
      loadingRef.current.style.zIndex = 100
      loadingRef.current.style.backgroundColor = '#ECECEC'
      loadingRef.current.style.display = 'flex'
      loadingRef.current.style.transition = 'none'

      setTimeout(() => {
        loadingRef.current.style.opacity = 0
        loadingRef.current.style.display = 'none'
      }, 250)
    } else {
      setCloseFilters(false)
      setOnSlide(false)
    }
  }

  const handleClickLounchModalCustomJourney = node => {
    toggle()
    setLounched(node)
  }

  const handleClickLounchCustomJorney = () => {
    if (lounched) {
      history.push(`/journey?q=${encodeIds(String(lounched.id))}`)
      cleanFilters && cleanFilters(true)
      setLounched(null)
      window.location.reload()
    }
  }

  const handleClickJourney = node => {
    history.push(`${node.journey_url}`)
    window.location.reload()
  }

  const handleClickFinalModal = e => {
    const { name } = e.target.dataset

    if (name === 'share') {
      setModal({ ...modal, congrats: false, share: true })
    }

    if (name === 'journey-slider') {
      history.push(`/#${name}`)
    }
  }

  const handleClickShareIcon = () => {
    setModal({ ...modal, share: true })
  }

  const handleModalLargeClose = id => {
    setModal({ ...modal, [id]: false, data: { ...initialModalState.data } })
  }
  const handleOnEngineStop = () => {
    hiddenLoading()
  }

  const handleScrollClick = (row, type, key) => {
    onSelectHandle(row, type, key)
  }

  return (
    <>
      <Helmet>
        <meta name='robots' content='noindex' />
      </Helmet>

      <Content fullScreen={true} className={artworkPageStyles.content}>
        {!closeFilters && (
          <Filters
            is3dView
            count={currentRelated.related.results.length}
            loading={currentRelatedLoading}
            onClose={handleFilterClose}
            onChange={handleFilterChange}
            isMobile={isMobile}
            onClickVisibility={() => setClosedControls(!closedControls)}
          />
        )}

        <Loading
          height={isMobile ? '80vh' : '90vh'}
          ref={loadingRef}
          showLogo
          small
          preview={preview}
        />
        <div
          className={`${
            show === 'fullscreen' ? artworkPageStyles.hidden : ''
          } artwork-graph-container`}
        >
          <Graph3d
            data={currentRelated.related.results}
            current={currentRelated.item}
            handleNodeClick={node => handleNodeClick(node)}
            //onNodeHover={node => setCurrentNode(node)}
            closedControls={closedControls}
            handleMouseEventsGridView={handleMouseEventsGridView}
            show={show}
            isFilterChanged={isFilterChanged}
            onEngineStop={handleOnEngineStop}
            filtersList={filtersList}
            onClickCard={handleClickCard}
            isMobile={isMobile}
            closeFilters={closeFilters}
          />
        </div>

        <InmersiveView
          ref={innerViewRef}
          showSlider={curatedId}
          className={show !== 'fullscreen' ? artworkPageStyles.hidden : ''}
          onChangeIndex={handleChangeIndex}
          currentJourney={currentJourney}
          loading={currentRelatedLoading}
          onClickCard={handleClickCardInmersive}
        />

        <Description
          item={curatedModal.curator_name ? curatedModal : currentRelated.item}
          show={show}
          isMobile={isMobile}
          isJourney={Boolean(curatedId)}
          onSlide={onSlide}
          onClickShare={handleClickShareIcon}
          onClickInfo={() => {
            setCloseFilters(false);
            setShowHelpScreen(true);

          }}
          onClickFilter={() => setCloseFilters(!closeFilters)}
          onClickGroup={handleClickGroup}
          onClickViewMetadata={() => handleNodeClick(currentRelated.item)}
        >
          <ArtworkNav
            isMobile={isMobile}
            current={currentRelated.item}
            onSelect={(row, type, key) => onSelectHandle(row, type, key)}
            onDelete={curatedId ? false : onDeleteHandle}
            isJourney={Boolean(curatedId)}
            handleScrollClick={handleScrollClick}
            artworkIsLoading={currentRelatedLoading}
          />
        </Description>
        <SaveJourneyAdminOnly />
      </Content>

      <Modal
        ref={modalRef}
        onlyImage={onlyImage}
        showLargeImageButton
        showImage={modalImage}
        isMobile={isMobile}
        // show button if is not the center item and if si the last selected
        style={{
          width: isMobile ? '75%' : '350px',
        }}
        showLounchCustomJourneyButton={curatedId}
        showAddButon={showAddButtonValidation()}
        node={currentNode}
        onClickLounchCustomJourney={handleClickLounchModalCustomJourney}
        onClickAddCJ={handleClickAddCustomJourney}
        onClick={handleClose}
      />

      {!lounched && (
        <ModalLarge
          id='curated'
          isShowing={modal['curated']}
          onClose={handleModalLargeClose}
        >
          <h1>
            {curatedModal.curator_name}: {curatedModal.Title}
          </h1>
          <p>
            {(curatedModal.short_description
              ? curatedModal.short_description
              : curatedModal.Description
            ).replace(/^(.{480}[^\s]*).*/, '$1')}
          </p>
          <h4>Edited by</h4>
          <img src={mapAcademyOne} width={200} alt='Map - Museum' />
        </ModalLarge>
      )}

      {lounched && (
        <ModalLarge isShowing={isShowing} hide={toggle}>
          <h1>Leave The Curated Journey?</h1>
          <p>
            Don't worry, you'll be able to return to the same spot later in the
            session.
          </p>
          <button
            type='button'
            className={`button`}
            onClick={handleClickLounchCustomJorney}
          >
            Launch custom journey
          </button>

          <button type='button' className={`button no-line`} onClick={toggle}>
            Go back
          </button>
        </ModalLarge>
      )}

      {!curatedId && (
        <ModalLarge
          id='congrats'
          isShowing={modal['congrats']}
          onClose={handleModalLargeClose}
        >
          <h1>Congratulations!</h1>
          <p>
            You’ve traced the influence of South Asian textiles across 20
            artworks. We hope you’ll share the Interwoven platform and your
            insights with your community.
          </p>
          <button
            className={`button no-line`}
            data-name='share'
            onClick={handleClickFinalModal}
          >
            share this journey
          </button>
          <hr />
          <h2>Explore more connections</h2>
          <p>Select a Journey Below or</p>
          <button
            className={`button no-line`}
            data-name='journey-slider'
            onClick={handleClickFinalModal}
          >
            RETURN TO THE JOURNEY MENU
          </button>

          {!isMobile && (
            <ShareCards
              data={parseSliderData(journeyList)}
              onClickJourney={handleClickJourney}
            />
          )}
        </ModalLarge>
      )}

      <ModalLarge
        id='remove'
        isShowing={modal['remove']}
        onClose={handleModalLargeClose}
      >
        <h1>Remove Artwork?</h1>
        <p>This artwork will be removed from your custom journey</p>
        <img
          src={replaceImageName(
            modal.data.current.img || modal.data.current.image_url
          )}
          alt=''
        />

        <button
          type='button'
          className={`button`}
          onClick={handleDeletedElement}
        >
          Confirm
        </button>
        <button
          className={`button no-line`}
          data-name='journey-slider'
          onClick={() => setModal({ ...modal, remove: false })}
        >
          Cancel
        </button>
      </ModalLarge>

      <ModalLarge
        id='share'
        isShowing={modal['share']}
        onClose={handleModalLargeClose}
      >
        {Boolean(curatedId) ? (
          <ShareJourney
            title='Share Your Curated Journey'
            emailSubject='MAP and Microsoft present INTERWOVEN'
            emailText='INTERWOVEN uses AI and machine learning to reveal connections between MAP’s collection of South Asian textiles and artworks from different cultures, mediums, and time periods.%0d%0aThe platform was developed in collaboration with Microsoft.%0d%0a%0d%0aTake a look at this curated journey:%0d%0a'
            url={`${process.env.REACT_APP_SHARE_URL}${getJourneyPath()}`}
          />
        ) : (
          <ShareJourney
            title='Share Your Custom Journey'
            emailSubject='MAP and Microsoft present INTERWOVEN'
            emailText='INTERWOVEN uses AI and machine learning to reveal connections between MAP’s collection of South Asian textiles and artworks from different cultures, mediums, and time periods.%0d%0aThe platform was developed in collaboration with Microsoft.%0d%0a%0d%0aTake a look at this custom journey:%0d%0a'
            url={`${process.env.REACT_APP_SHARE_URL}${getJourneyPath()}`}
            emailForm
          />
        )}
      </ModalLarge>

      {!isMobile && showHelpScreen && (
        <HelpScreen
          onSkip={handleSkipHelpScreen}
          onClose={handleCloseHelpScreen}
        />
      )}
    </>
  )
}

const mapStateToProps = createStructuredSelector({
  currentRelated: selectors.artwork.selectCurrentRelated,
  currentRelatedLoading: selectors.artwork.selectArtworkLoading,
  selectedArtworks: selectors.artwork.selectArtworkSelected,
  filtersList: selectors.filter.selectFilterList,
  journeyList: selectors.journey.selectJourneyList,
  show: selectors.artwork.show,
})

const mapDispatchToProps = dispatch => ({
  clearCurrentRelated: () => dispatch(actions.artwork.clearRelated()),
  clearCurrentSelected: () => dispatch(actions.artwork.clearSelected()),
  loadCurrentRelated: (id, body) =>
    dispatch(actions.artwork.setRelatedArtworks(id, body)),
  addSelectedArtwork: artwork => {
    return dispatch(actions.artwork.addArtwork(artwork))
  },
  setSelectedArtworks: ids =>
    dispatch(actions.artwork.setSelectedArtworks(ids)),
  deleteSelected: fromTo => dispatch(actions.artwork.deleteSelected(fromTo)),
  cleanFilters: cleanAll => dispatch(actions.filter.cleanFilters(cleanAll)),
  changeView: name => dispatch(actions.artwork.changeView(name)),
})

export default connect(mapStateToProps, mapDispatchToProps)(Artwork)
