import { artwork } from './artwork'
import { filter } from './filter'
import { journey } from './journey'
import { page } from './page'

/**
 * Root states.
 */
export const States = {
  artwork: artwork.initialState,
  filter: filter.initialState,
  journey: journey.initialState,
  page: page.initialState,
}

/**
 * Root reducers.
 */
export const reducers = {
  artwork: artwork.reducer,
  filter: filter.reducer,
  journey: journey.reducer,
  page: page.reducer,
}

/**
 * Root types
 */

export const types = {
  artwork: artwork.types,
  filter: filter.types,
  journey: journey.types,
  page: page.types,
}

/**
 * Root sagas
 */

export const sagas = {
  artwork: artwork.sagas,
  filter: filter.sagas,
  journey: journey.sagas,
  page: page.sagas,
}

/**
 * Root utils.
 */
export const utils = {
  artwork: artwork.utils,
  filter: filter.utils,
  journey: journey.utils,
  page: page.utils,
}

/**
 * Root selectors
 */
export const selectors = {
  artwork: artwork.selectors,
  filter: filter.selectors,
  journey: journey.selectors,
  page: page.selectors,
}

/**
 * Root actions.
 */
export const actions = {
  artwork: artwork.actions,
  filter: filter.actions,
  journey: journey.actions,
  page: page.actions,
}

// can you access to especific action
export { artwork, filter, journey, page }
