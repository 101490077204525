import React, { MouseEvent } from 'react'
import propertiesStyles from './properties.module.scss'

interface PropertiesProps {
  name?: string
  count?: number | string
  current: any
  onClick?: any
}

const Properties: React.FC<PropertiesProps> = ({
  name = '',
  count = 0,
  current,
  onClick,
}) => (
  <div className={propertiesStyles.wrapper}>
    {name}
    {count > 0 && (
      <span className={propertiesStyles.gray}>
        <span className={propertiesStyles.separator}>|</span> {count}
      </span>
    )}
    <button
      onClick={(e: MouseEvent) => {
        e.preventDefault()
        onClick && onClick({ name, current })
      }}
      className='icon-cancel'
    ></button>
  </div>
)

export default Properties
