import { all, put, takeLatest } from 'redux-saga/effects'
import type from './page.types'
import Service from '../../../services'

const Ser = Service()
const apiGet = new Ser.API_GET(process.env.REACT_APP_URL_API_STRAPI)

export function* tryPage(action) {
  try {
    yield put({ type: type.TRY })

    const response = yield apiGet.getPage(action.payload)

    if (!response.ok) throw new Error(response.problem)

    const data = yield response.data

    yield put({
      type: type.PAGE_SUCCESS,
      payload: { key: action.payload, data },
    })
  } catch (error) {
    yield put({
      type: type.FAILED,
      payload: { name: type.PAGE_SUCCESS, error: error.message },
    })
  }
}

export function* watchPage() {
  yield takeLatest(type.PAGE_SUCCESS_REQUESTED, tryPage)
}

export function* pageSagas() {
  yield all([watchPage()])
}
