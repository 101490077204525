export const NAME = String('page').toUpperCase() // same as the  global state

const types = {
  TRY: `[${NAME}]/TRY`,

  PAGE_SUCCESS: `[${NAME}]/PAGE_SUCCESS`,
  PAGE_SUCCESS_REQUESTED: `[${NAME}]/PAGE_SUCCESS_REQUESTED`,

  FAILED: `[${NAME}]/FAILED`,
}

export default types
