const SPECIAL_PROPS = ['key', 'children', 'dangerouslySetInnerHTML']
const defaultTester = document.createElement('div')

export const constfilterBadProps = (
  props: any,
  tester: HTMLElement = defaultTester
) => {
  // filter out any keys which don't exist in reacts special props, or the tester.
  const out: any = {}
  Object.keys(props)
    .filter(
      propName =>
        propName in tester ||
        propName.toLowerCase() in tester ||
        SPECIAL_PROPS.includes(propName)
    )
    .forEach(key => (out[key] = props[key]))

  return out
}
