import type from './page.types'
import * as utils from './page.utils'

export const initialState = {
  list: { home: {}, about: {}, partners: {}, textiles: {} },
  errors: [],
  isLoading: false,
}

const pageReducer = (state = initialState, action) => {
  switch (action.type) {
    case type.TRY:
      return { ...state, isLoading: true }

    case type.PAGE_SUCCESS:
      return {
        ...state,
        isLoading: false,
        list: { ...state.list, [action.payload.key]: action.payload.data },
      }

    case type.FAILED:
      const errors = utils.addError(state.errors, action)
      return { ...state, isLoading: false, errors }

    default:
      return state
  }
}

export default pageReducer
