import { media, style } from 'typestyle'
import { FONT_FAMILY_GEOMETRIA, FONT_FAMILY_LEITURA } from '../../constants'

export const shareJourneyContainerClass = style(
  {
    width: '1060px',
    padding: '5% 0',
  },
  media(
    { maxWidth: 960 },
    {
      width: '100%',
    }
  )
)

export const shareJourneyBodyClass = style(
  {
    margin: '0 auto',
    width: '640px',
  },
  media(
    { maxWidth: 960 },
    {
      width: '100%',
    }
  )
)

export const shareJourneyTitleClass = style({
  textAlign: 'center',
  fontWeight: 400,
  fontSize: '24px',
  fontFamily: FONT_FAMILY_LEITURA,
  margin: '0 0 35px 0',
  color: '#000000',
})

export const shareJourneyRowClass = style({
  display: 'flex',
  flexDirection: 'row',
  justifyContent: 'center',
})

export const shareJourneyLeftClass = style(
  {
    //width: '50%',
    //float: 'left',
    maxWidth: '260px',
    padding: '0 20px',
  },
  media(
    { maxWidth: 960 },
    {
      maxWidth: '50%',
      padding: '0 10px',
    }
  )
)

export const shareJourneyRightClass = style({
  width: '50%',
  float: 'left',
  padding: '0 20px',
})

export const shareJourneyPreviewWrapperClass = style({
  display: 'block',
  width: '100%',
  height: 0,
  position: 'relative',
  boxSizing: 'border-box',
  padding: '0 0 100% 0',
  background: 'gray',
  overflow: 'hidden',
})

export const shareJourneyPreviewClass = style({
  position: 'absolute',
  left: 0,
  top: 0,
  right: 0,
  bottom: 0,
  width: '100%',
  height: '100%',
  overflow: 'hidden',
  background: '#e4e4e4',
  // mixBlendMode: 'multiply'
})

export const shareJourneyTextClass = style({
  textAlign: 'center',
  fontFamily: FONT_FAMILY_GEOMETRIA,
  fontSize: '14px',
  color: '#323232',
  lineHeight: '120%',
  fontWeight: 'normal',
  margin: '20px 0',
})

export const shareJourneyMapLogoClass = style({
  position: 'absolute',
  right: 0,
  bottom: '-20px',
  pointerEvents: 'none',
})

export const shareJourneySocialLinksClass = style({
  display: 'flex',
  listStyle: 'none',
  padding: '0 20px',
  margin: '20px 0',
  flexWrap: 'nowrap',
  justifyContent: 'center',

  $nest: {
    li: {
      display: 'block',
      margin: 0,
      padding: 0,
      flexGrow: 1,
      textAlign: 'center',
      $nest: {
        a: {
          display: 'block',
        },
      },
    },
  },
})

export const shareJourneyInputErrorClass = style();
export const shareJourneySubmitDisabledClass = style();

export const shareJourneyFormClass = style({
  padding: '10px 0',

  $nest: {
    input: {
      display: 'block',
      background: 'transparent',
      width: '100%',
      border: 'none',
      borderBottom: '1px solid rgba(0, 0, 0, 0.3)',
      fontFamily: FONT_FAMILY_GEOMETRIA,
      fontSize: '12px',
      color: '#828282',
      textAlign: 'center',
      textTransform: 'uppercase',
      height: '24px',
      margin: '5px 0',

      $nest: {
        ['&.' + shareJourneyInputErrorClass] : {
          borderBottomColor: 'rgb(223, 0, 0)',
          backgroundColor: 'rgba(223, 0, 0, .1)'
        }
      }
    },
    button: {
      display: 'inline-block',
      background: 'transparent',
      border: 'none',
      textDecoration: 'underline',
      fontFamily: FONT_FAMILY_GEOMETRIA,
      fontSize: '12px',
      color: '#000000',
      textAlign: 'center',
      textTransform: 'uppercase',
      height: '24px',
      margin: '5px 0',
      cursor: 'pointer',

      $nest: {
        ['&.' + shareJourneySubmitDisabledClass]: {
          cursor: 'not-allowed',
          opacity: '.5'
        }
      }
    },
  },
})

export const shareJourneyFormErrorMessageClass = style({
  fontSize: '12px',
  color: 'rgb(223, 0, 0)',
  margin: '10px 0 0 0'
});

export const shareJourneyFormSuccessMessageClass = style({
  fontSize: '12px',
  color: 'green',
  margin: '10px 0 0 0'
});