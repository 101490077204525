import React, { createRef, useEffect } from 'react'
import Control from '../control/control.component'

import {
  hScrollClass,
  leftAreaClass,
  rightAreaClass,
  hScrollContentClass,
  navButtonClass,
} from './hScroll.style'

interface HScrollProps {
  isMobile?: boolean
  stepSize?: number
  item?: { position: number; width: number }
  quantity?: number
  onClickArrow: (direction: string, nextPosition: number) => {}
}

const HScroll: React.FC<HScrollProps> = ({
  children,
  stepSize = 100,
  item = { position: 0, width: 0 },
  quantity = 0,
  isMobile = false,
  onClickArrow = () => {},
}) => {
  const hScrollRef = createRef<HTMLDivElement>()

  useEffect(() => {
    if (hScrollRef.current && item.width) {
      const groups = []
      const eleWidth = item.width
      const barWidth = hScrollRef.current.offsetWidth - eleWidth
      const numPerGroup = Math.round(barWidth / eleWidth) - 1
      const steps = Math.floor(quantity / numPerGroup) + 1 // start at 1
      const current = item.position + 1
      //const totalWidth = barWidth * steps
      let groupPosition = 0

      for (let i = 1; i <= steps; i++) {
        const arr = new Array(numPerGroup).fill(i)
        groups.push(
          arr.map((_, index) => numPerGroup * i - index).sort((a, b) => a - b)
        )
      }

      groups.forEach((group, groupPos) => {
        const indexGroup = group.findIndex(el => String(el) === String(current))
        if (indexGroup !== -1) {
          groupPosition = groupPos
        }
      })

      refElement(element => {
        element.scroll({
          left: isMobile
            ? eleWidth * item.position - eleWidth
            : barWidth * groupPosition + 1,
          behavior: 'smooth',
        })
      })
    }
  }, [item.position, item.width, quantity])

  const refElement = (fn: (element: HTMLDivElement) => void) => {
    if (hScrollRef.current) fn(hScrollRef.current)
  }

  const onClickLeftFn: React.MouseEventHandler = (event: React.MouseEvent) => {
    if (item.position - 1 > -1) {
      onClickArrow('left', item.position - 1)
    }
  }

  const onClickRightFn: React.MouseEventHandler = (event: React.MouseEvent) => {
    if (item.position + 1 < quantity) {
      onClickArrow('right', item.position + 1)
    }
  }

  return (
    <div className={hScrollClass}>
      <div className={leftAreaClass} onClick={onClickLeftFn}>
        <Control
          className={navButtonClass}
          onClick={onClickLeftFn}
          name='Scroll to left'
          icon={'icon-left-arrow'}
          hidden={item.position - 1 > -1 ? false : true}
        />
      </div>
      <div className={hScrollContentClass} ref={hScrollRef}>
        {children}
      </div>
      <div className={rightAreaClass}>
        <Control
          className={navButtonClass}
          onClick={onClickRightFn}
          name='Scroll to right'
          icon={'icon-right-arrow'}
          hidden={item.position + 1 < quantity ? false : true}
        />
      </div>
    </div>
  )
}

export default HScroll
