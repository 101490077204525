import React from 'react'
import { scrollToId } from './anchor.utils'

interface AnchorProps {
  to: string
  ariaLabel?: string
  className?: string
}

const Anchor: React.FC<AnchorProps> = ({
  to,
  ariaLabel,
  children,
  className,
}) => {
  const onClickFn = (event: React.MouseEvent) => {
    event.preventDefault()
    event.stopPropagation()
    scrollToId(to)
  }

  return (
    <a
      href={`#${to}`}
      onClick={onClickFn}
      className={className}
      aria-label={ariaLabel}
    >
      {children}
    </a>
  )
}

export default Anchor
