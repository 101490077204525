export const NAME = String('artwork').toUpperCase() // same as the  global state

const types = {
  TRY: `[${NAME}]/TRY`,

  SET_RELATED: `[${NAME}]/SET_RELATED`,
  SET_RELATED_REQUESTED: `[${NAME}]/SET_RELATED_REQUESTED`,
  CLEAR_RELATED: `[${NAME}]/CLEAR_RELATED`,
  CHANGE_VIEW: `[${NAME}]/CHANGE_VIEW`,

  LIST_SUCCESS: `[${NAME}]/LIST_SUCCESS`,
  LIST_SUCCESS_REQUESTED: `[${NAME}]/LIST_SUCCESS_REQUESTED`,
  LIST_ADD_PAGE: `[${NAME}]/LIST_ADD_PAGE`,
  LIST_ADD_PAGE_REQUESTED: `[${NAME}]/LIST_ADD_PAGE_REQUESTED`,
  LIST_RESET_PAGE: `[${NAME}]/LIST_RESET_PAGE`,

  ADD_SELECTED: `[${NAME}]/ADD_SELECTED`,
  SET_SELECTED: `[${NAME}]/SET_SELECTED`,
  SET_SELECTED_REQUESTED: `[${NAME}]/SET_SELECTED_REQUESTED`,
  DELETE_SELECTED: `[${NAME}/DELETE_SELECTED]`,
  CLEAR_SELECTED: `[${NAME}]/CLEAR_SELECTED`,

  FAILED: `[${NAME}]/FAILED`,
}

export default types
