import { style } from 'typestyle';
import { NestedCSSProperties } from 'typestyle/lib/types';

export const hScrollClass = style({
    display: 'flex',
    overflow: 'hidden',
    position: 'relative',
    width: '100%',
    flexWrap: 'nowrap',
    flexDirection: 'row'
});

const leftAndRightAreaStyles: NestedCSSProperties = {
    top: 0,
    height: '100%',
    minWidth: '40px',
    flexShrink: 0,
    flexGrow: 0,
    position: 'relative'
};

export const leftAreaClass = style({
    ...leftAndRightAreaStyles,
    left: 0
});

export const rightAreaClass = style({
    ...leftAndRightAreaStyles,
    right: 0
});

export const navButtonClass = style({
    display: 'inline-block',
    cursor: 'pointer',
    position: 'absolute',
    left: '50%',
    top: '50%',
    width: '28px',
    transform: 'translate(-50%, -50%)',
    $nest: {
        img: {
            maxWidth: 'unset',
            width: '28px',
            height: 'auto',
        }
    }
});

export const hScrollContentClass = style({
    display: 'block',
    flexShrink: 1,
    flexGrow: 1,
    position: 'relative',
    overflowX: 'auto',
    overflowY: 'hidden',
    '-ms-overflow-style': 'none',

    $nest: {
        '&::-webkit-scrollbar': {
            display: 'none'
        }
    }
});
