import { fontFace, cssRule } from 'typestyle';
import { fontGeometria, fontLeitura } from './assets';
import { FONT_FAMILY_GEOMETRIA, FONT_FAMILY_LEITURA } from './constants';

const cssUrl = (url: string): string => `url("${url}")`;

fontFace({
    fontFamily: FONT_FAMILY_GEOMETRIA,
    fontStyle: 'normal',
    fontWeight: 400,
    src: cssUrl(fontGeometria)
});

fontFace({
    fontFamily: FONT_FAMILY_LEITURA,
    fontStyle: 'normal',
    fontWeight: '400',
    src: cssUrl(fontLeitura)
});

cssRule('*', {
    outline: 'none',
    boxSizing: 'border-box'
});

cssRule('html, body', {
    display: 'block',
    margin: 0,
    padding: 0,
    minHeight: '100vh'
});

cssRule('img', {
    display: 'inline-block',
    border: 'none',
    margin: 0,
    padding: 0,
    maxWidth: '100%'
});

cssRule('h1', {
    fontFamily: FONT_FAMILY_LEITURA,
    fontSize: '40px',
    fontWeight: 'normal',
    textAlign: 'center'
});

cssRule('p', {
    color: '#323232'
});

cssRule('a', {
    textTransform: 'uppercase',
    textDecoration: 'none',
    color: '#000000',
    cursor: 'pointer',
    fontFamily: FONT_FAMILY_GEOMETRIA,
    fontWeight: 400
});

cssRule('.scene-tooltip', {
    zIndex: 200
});

cssRule('a:focus-visible, button:focus-visible, input:focus-visible', {
    outlineColor: '#878787',
    outlineWidth: '2px',
    outlineStyle: 'solid'
});

cssRule('.rc-slider-handle:focus-visible', {
    outlineColor: '#878787',
    outlineWidth: '2px',
    outlineStyle: 'solid'
});
