import React from 'react'
import ReactDOM from 'react-dom'
import './index.css'
import App from './App'
import reportWebVitals from './reportWebVitals'
import { HelmetProvider } from 'react-helmet-async'

import { Provider } from 'react-redux'
import { PersistGate } from 'redux-persist/integration/react'
import store, { persistor } from '../src/state/store'
import Loading from './components/loading/loading.component'
import Protect from 'react-app-protect'
import 'react-app-protect/dist/index.css'

// replace console.* for disable log on production
if (process.env.NODE_ENV === 'production') {
  console.log = () => {}
  console.error = () => {}
  console.debug = () => {}
}

console.info('Version: ', process.env.REACT_APP_VERSION)

ReactDOM.render(
  <Provider store={store}>
    <React.StrictMode>
      <PersistGate
        loading={<Loading height='100vh' showLogo showBackground />}
        persistor={persistor}
      >
          <HelmetProvider>
            <App />
          </HelmetProvider>
      </PersistGate>
    </React.StrictMode>
  </Provider>,
  document.getElementById('root')
)

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals()
