import API from './api.config'

/**
 * PUT
 **/

export default class API_PUT extends API {
  constructor(baseURL = process.env.URL_API) {
    super(baseURL)
  }
}
