export const DESKTOP_MAIN_MENU_HEIGHT_PX: number = 70
export const DESKTOP_PAGE_LEFT_RIGHT_MARGINS_PX: number = 40
export const FONT_FAMILY_GEOMETRIA: string = 'Geometria'
export const FONT_SIZE_SMALL: number = 12
export const FONT_FAMILY_LEITURA: string = 'Leitura News'
export const FONT_SIZE_REGULAR: number = 15
export const MEDIA_BREAKPOINT_TABLET: number = 991
export const MEDIA_BREAKPOINT_DESKTOP: number = 1200

export const FOCUS_BACKGROUND_COLOR: string = '#d1d1d1';

