// a library to wrap and simplify api calls
import apisauce from 'apisauce'

export default class API {
  constructor(baseURL = process.env.REACT_APP_URL_API) {
    this.baseURL = baseURL
    this.api = this.create()
  }
  create() {
    const api = apisauce.create({
      // base URL is read from the "constructor"
      baseURL: this.baseURL,
      // here are some default headers
      headers: {
        Accept: 'application/json',
      },
      // 10 second timeout...
      timeout: 10240,
    })
    // api from apisauce
    return api
  }

  getStatus() {
    return this.api.get('/status')
  }

  getVersion() {
    return this.api.get('/version')
  }
}
