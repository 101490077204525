import { media, style } from 'typestyle'
import { NestedCSSProperties } from 'typestyle/lib/types';
import { FONT_FAMILY_GEOMETRIA, FONT_FAMILY_LEITURA } from '../../constants';

export const helpScreenWrapperClass = style({
    display: 'block',
    position: 'fixed',
    width: '100%',
    height: '100%',
    left: 0,
    top: 0,
    zIndex: 1000,
    transition: '500ms',
    opacity: 0,

    $debugName: 'HelpScreen'
}, media({
    maxWidth: 960
}, {
    display: 'none'
}));

export const helpScreenOverlayClass = style({
    display: 'block',
    position: 'absolute',
    width: '100%',
    height: '100%',
    boxSizing: 'border-box',
    left: 0,
    top: 0,
    padding: '15px',
    zIndex: 1,
    textAlign: 'center'
});

export const helpScreenTopContentClass = style({
    margin: '40px auto 0',
    display: 'inline-block',
    padding: '20px'
});

export const helpScreenSkipButtonClass = style({
    border: 'none',
    background: 'transparent',
    fontFamily: FONT_FAMILY_GEOMETRIA,
    fontSize: '12px',
    fontWeight: 400,
    textTransform: 'uppercase',
    textDecoration: 'underline',
    cursor: 'pointer',
    padding: 0,
    margin: 0,
    color: '#ffffff'
});

export const helpScreenTitleClass = style({
    fontFamily: FONT_FAMILY_LEITURA,
    fontSize: '40px',
    fontWeight: 400,
    color: '#ffffff'
});

export const helpScreenPaginatorClass = style({
    display: 'inline-flex',
    border: 'solid 1px #ffffff',
    borderLeft: 'none',
    borderRight: 'none',
    listStyle: 'none',
    padding: '20px 0',
    margin: '30px 0',
    flexDirection: 'row',
    flexWrap: 'nowrap'
});

export const helpScreenPaginatorItemClass = style({
    width: '50px',
    flexShrink: 0,
    flexGrow: 0,
    textAlign: 'center',

    $nest: {
        '> a': {
            fontFamily: FONT_FAMILY_LEITURA,
            fontSize: '20px',
            lineHeight: '25px',
            fontWeight: 'normal',
            color: '#ffffff'
        }
    }
});

export const helpScreenPaginatorItemActiveClass = style({
    $nest: {
        '> a': {
            fontSize: '30px'
        }
    }
});

const helpScreenPaginatorNextPrevStyle: NestedCSSProperties = {
    display: 'block',
    width: '25px',
    height: '25px'
};

const helpScreenPaginatorNextPrevBtnStyle: NestedCSSProperties = {
    display: 'block',
    width: '25px',
    height: '25px',
    cursor: 'pointer',
    borderRadius: '50%',
    // border: 'solid 1px #ffffff',
    position: 'relative'
};

export const helpScreenPaginatorPrevClass = style({
    ...helpScreenPaginatorNextPrevStyle,
    marginRight: '30px',

    $nest: {
        '> a': {
            ...helpScreenPaginatorNextPrevBtnStyle
        }
    }
});

export const helpScreenPaginatorNextClass = style({
    ...helpScreenPaginatorNextPrevStyle,
    marginLeft: '30px',

    $nest: {
        '> a': {
            ...helpScreenPaginatorNextPrevBtnStyle
        }
    }
});

export const helpScreenStepTitleClass = style({
    color: 'white',
    fontSize: '18px',
    fontWeight: 400,
    fontFamily: FONT_FAMILY_GEOMETRIA,
    textAlign: 'center'
});

export const helpScreenContentClass = style({
    $debugName: 'helpScreenContent',

    position: 'absolute',
    display: 'block',
    minWidth: '80px',
    minHeight: '30px',
    color: '#ffffff',
    textAlign: 'left',
    fontFamily: FONT_FAMILY_GEOMETRIA,
    fontSize: '18px',
    lineHeight: '22.64px',
    fontWeight: 400,

    $nest: {
        '*': {
            color: '#ffffff'
        },
        'p': {
            fontFamily: FONT_FAMILY_GEOMETRIA,
            fontSize: '18px',
            lineHeight: '22.64px',
            fontWeight: 400,
            margin: '0 0 15px 0'
        }
    }
});

export const helpScreenArrowWrapperClass = style({
    $debugName: 'helpScreenArrowWrapper'
});

export const closeButtonClass = style({
    position: 'absolute',
    width: '50px',
    height: '50px',
    overflow: 'hidden',
    cursor: 'pointer',
    right: '10px',
    top: '10px',
    padding: '10px'
});

export const floatingElementClass = style({
    background: 'rgba(0, 0, 0, .7)',
    boxShadow: 'black 0px 0px 6px 2px',
    borderRadius: '1px'
});