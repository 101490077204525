export const NAME = String('filter').toUpperCase() // same as the  global state

const types = {
  TRY: `[${NAME}]/TRY`,

  LIST_SUCCESS: `[${NAME}]/LIST_SUCCESS`,
  LIST_SUCCESS_REQUESTED: `[${NAME}]/LIST_SUCCESS_REQUESTED`,

  CLEAN_LIST: `[${NAME}]/CLEAN_LIST`,

  UPDATE_LIST: `[${NAME}]/UPDATE_LIST`,
  UPDATE_LIST_COUNTS: `[${NAME}]/UPDATE_LIST_COUNTS`,

  FAILED: `[${NAME}]/FAILED`,
}

export default types
