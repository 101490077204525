import API from './api.config'

/**
 * GET
 **/

export default class API_GET extends API {
  constructor(baseURL = process.env.URL_API) {
    super(baseURL)
  }

  getFilters(all) {
    return this.api.get(`/filters${all ? '/all' : ''}`)
  }

  getSelectedArtworks(ids = []) {
    return this.api.get(`/images/?ids=${ids.join(',')}`)
  }

  // from Strapi
  getCuratedJourneys() {
    return this.api.get(`/curated-journeys`)
  }
  getPage(name = '') {
    let url = 'home-pages/1'

    switch (name) {
      case 'about':
        url = 'basic-pages/1'
        break
      case 'partners':
        url = 'basic-pages/2'
        break
      case 'textiles':
        url = 'basic-pages/3'
        break
      default:
        url = 'home-pages/1'
    }
    
    return this.api.get(`/${url}`)
  }
}
