export const encodeIds = (arrayOfIdsOrId: string[] | string): string => {
  return encodeURIComponent(
    btoa(
      Array.isArray(arrayOfIdsOrId) ? arrayOfIdsOrId.join(',') : arrayOfIdsOrId
    )
  )
}

export const decodeIds = (encoded: string): string[] => {
  if (encoded) return atob(decodeURIComponent(encoded)).split(',')
  return []
}

export const decodeHTMLString = (encodedHTMLString: string): string => {
  encodedHTMLString = encodedHTMLString ? encodedHTMLString : ''
  return htmlDecode(
    encodedHTMLString
      .replaceAll('&lt;', '<')
      .replaceAll('&gt;', '>')
      .replaceAll('&#39;', "'")
      .replaceAll('&amp;', '&')
      .replaceAll('&quot;', '"')
  )
}

export const htmlDecode = (input: string) => {
  var doc = new DOMParser().parseFromString(input, 'text/html')
  return doc.documentElement.textContent || ''
}
