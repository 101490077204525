const time = () => {
  const time = new Date()
  return time.toLocaleString('en-US', {
    timeZone: process.env.REACT_APP_TIME_ZONE,
  })
}

export const addError = (errors, action) => {
  const payload = action.payload

  const errorData = {
    name: payload.name,
    message: { error: payload.error, time: time() },
  }

  const errorId = errors.findIndex(error => error.message.time === time())

  if (errorId === -1) errors.push(errorData)
  else errors[errorId] = errorData
  return errors
}

export const onClean = (list, action) => {
  const payload = action.payload
  list.forEach((element, elementIndex) => {
    if (payload) list[elementIndex].isOpen = false
    element.options.forEach((option, optionIndex) => {
      list[elementIndex].options[optionIndex].isSelected = false
      option?.children?.forEach((child, childIndex) => {
        list[elementIndex].options[optionIndex].children[
          childIndex
        ].isSelected = false
      })
    })
  })
  return { list, selected: [] }
}

export const updateFiltersCounts = (list, selected, action) => {
  const payload = action?.payload?.filter(element => element.visible !== false)

  payload &&
    list.forEach((listElement, index) => {
      listElement.options.forEach((option, key) => {
        list[index].options[key].count =
          payload[index]?.options[key]?.count || 0

        selected.forEach((select, selectIndex) => {
          if (select.value === option.value)
            selected[selectIndex].count = option.count
        })
      })
    })

  return { list, selected }
}

export const addedList = action => {
  const list = action.payload.filter(element => element.visible !== false)
  return list
}

export const removeFilterFields = data => {
  data.map(
    element =>
      (element.options = element.options.filter(
        option => option.value !== 'Paintings' && option.value !== 'Photographs'
      ))
  )
  return data
}

export const updateFilters = (list, selected, action) => {
  if (typeof action.payload === 'number') {
    const key = action.payload
    list[key].isOpen = !list[key].isOpen
    return { list }
  }
  /*Verify if exist one hidden filter*/
  const current = action.payload
  const fOptions = list[current.parentId]
    ? list[current.parentId].options
    : null

  if (fOptions && current.currentId !== undefined && !current.isRange) {
    /*
     * Settings for filter with one level
     */
    fOptions[current.currentId].isSelected =
      !fOptions[current.currentId].isSelected

    if (!fOptions[current.currentId].isSelected) {
      fOptions[current.currentId]?.children?.map(ele => {
        ele.isSelected = false
        //if (!ele.isSelected) removeFilter(ele.value)
      })
    }

    /*
     *Adding Selected values to top level
     * */
    const index = selected.findIndex(
      el => el.value === fOptions[current.currentId].value
    )
    if (index === -1) {
      selected.push({
        value: fOptions[current.currentId].value,
        count: fOptions[current.currentId].count,
        current,
      })
    } else {
      selected = selected.filter(
        ele => ele.current.head !== selected[index].value
      )
      selected.splice(index, 1)
    }
    //
  } else if (current.currentId === undefined && !current.isRange) {
    const childrens =
      fOptions && fOptions[current.headId]
        ? fOptions[current.headId].children
        : null

    const childId =
      childrens?.findIndex(child => child.value === current.value) !== undefined
        ? childrens?.findIndex(child => child.value === current.value)
        : -1

    const child = (childrens && childrens[childId]) || {
      isSelected: false,
      value: '',
    }
    child.isSelected = !child.isSelected
    /*
     *Adding Selected values to child level
     * */
    const index = selected.findIndex(el => el.value === child.value)
    index === -1
      ? selected.push({
          value: child.value,
          count: child.count,
          current,
        })
      : selected.splice(index, 1)

    //
  } else if (fOptions && current.isRange) {
    /*
     * Settings for range filters
     */

    const index = selected.findIndex(el => el.current.name === current.name)
    if (
      !list[current.parentId].isSelected ||
      list[current.parentId].value !== current.value
    ) {
      list[current.parentId].isSelected = true
      list[current.parentId].value = current.value

      if (list[current.parentId].isSelected) {
        list[current.parentId].options.forEach((_, index) => {
          list[current.parentId].options[index].isSelected = false
          current.count.forEach(count => {
            if (index === count) {
              list[current.parentId].options[index].isSelected = true
            }
          })
        })
      } else {
        list[current.parentId].options.forEach((_, index) => {
          list[current.parentId].options[index].isSelected = false
          current.defaultSelected.forEach(count => {
            if (index === count) {
              list[current.parentId].options[index].isSelected = true
            }
          })
        })
      }
      
      /*
       *Adding Selected values to dates range
       * */
      if (list[current.parentId].isSelected) {
        index === -1
          ? selected.push({
              value: current.value,
              count: current.count,
              current,
            })
          : (selected[index] = {
              value: current.value,
              count: current.count,
              current,
            })
      } else {
        list[current.parentId].isSelected = false
      }
    } else {
      list[current.parentId].isSelected = false
      selected.splice(index, 1)

      list[current.parentId].options.forEach((_, index) => {
        list[current.parentId].options[index].isSelected = false
        current.defaultSelected.forEach(count => {
          if (index === count) {
            list[current.parentId].options[index].isSelected = true
          }
        })
      })
      // selected by default
    }
  }
  return { list, selected }
}
