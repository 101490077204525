import { cssRule, media, style } from 'typestyle'
import {
  DESKTOP_PAGE_LEFT_RIGHT_MARGINS_PX,
  FONT_FAMILY_LEITURA,
} from '../../constants'
import { homeBG } from '../../assets'
import { homeMobileBG } from '../../assets'

export const homepageContentClass = style({
  $nest: {
    section: {
      boxSizing: 'border-box',
      textAlign: 'center',
      paddingLeft: DESKTOP_PAGE_LEFT_RIGHT_MARGINS_PX + 'px',
      paddingRight: DESKTOP_PAGE_LEFT_RIGHT_MARGINS_PX + 'px',
    },
  },
})

export const anchorColumn = style({
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  flexDirection: 'column',
  marginTop: '3rem',
  $nest: {
    span: {
      marginBottom: '1rem',
    },
    button: {
      boxShadow: '1px 1px 3px 3px #00000012',
    },
  },
})

export const mobileOnlyClass = style(
  {
    display: 'none',
  },
  media(
    { maxWidth: 960 },
    {
      display: 'block',
      paddingBottom: '50px',
      $nest: {
        a: {
          display: 'block',
          marginBottom: '2px',
        },
      },
    }
  )
)

export const section1Class = style(
  {
    minHeight: '100vh',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
  },
  media(
    { maxWidth: 960 },
    {
      $nest: {
        p: {
          fontSize: '14px',
          lineHeight: '18px',
        },
        a: {
          fontSize: '12px',
        },
      },
    }
  ),
  media(
    { maxWidth: 400 },
    {
      display: 'block',
      paddingTop: '60px',
      // For smaller phones to make sure logo does not run into menu
      //marginTop: '10vh',
    }
  )
)

export const section2Class = style(
  {
    height: '70vh',
    minHeight: '600px',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
  },
  media(
    { maxWidth: 960 },
    {
      minHeight: '300px',
      height: '50vh',
      $nest: {
        h1: {
          fontSize: '20px',
          fontWeight: 400,
        },
      },
    }
  )
)

export const section3Class = style(
  {
    padding: '0 !important',
  },
  media(
    { maxWidth: 960 },
    {
      $nest: {
        h1: {
          fontSize: '20px',
          fontWeight: 400,
        },
        p: {
          fontSize: '14px',
          lineHeight: '18px',
        },
      },
    }
  )
)

export const section4Class = style(
  {
    background: '#E0E0E0',
    paddingTop: '100px',
    paddingBottom: '100px',
    $nest: {
      h1: {
        marginBottom: '0',
      },
      p: {
        maxWidth: '500px',
        marginLeft: 'auto',
        marginRight: 'auto',
        marginBottom: '25px',
      },
    },
  },
  media(
    { maxWidth: 960 },
    {
      paddingTop: '40px',
      paddingBottom: '50px',
      $nest: {
        h1: {
          fontSize: '20px',
          fontWeight: 400,
        },
        p: {
          fontSize: '14px',
          lineHeight: '18px',
        },
      },
    }
  )
)

export const screenCenteredContentClass = style({
  maxWidth: '570px',
  textAlign: 'center',
  $nest: {
    p: {
      textAlign: 'justify',
    },
  },
})

export const interwovenBigLogoClass = style({
  margin: '4vh 0',
})

export const homepageH1 = style({
  maxWidth: '960px',
  margin: '0 auto',
  display: 'block',
})

export const sliderWrapperClass = style({
  width: '100%',
  margin: '0 0 45px 0',
  $nest: {
    'ul.slick-dots': {
      display: 'block',
      textAlign: 'center',
      whiteSpace: 'nowrap',
      listStyle: 'none',
      padding: 0,
      margin: '0',
      $nest: {
        li: {
          padding: 0,
          margin: '0 10px',
          display: 'inline-block',
          $nest: {
            a: {
              fontFamily: FONT_FAMILY_LEITURA,
              fontSize: '15px',
              fontWeight: 400,
            },
            '&.slick-active': {
              $nest: {
                a: {
                  fontSize: '20px',
                },
              },
            },
          },
        },
      },
    },
    '.slick-arrow': {
      display: 'block',
      position: 'absolute',
      top: '50%',
      zIndex: 100,
      transform: 'translateY(-50%)',
      $nest: {
        '&.slick-prev': {
          left: DESKTOP_PAGE_LEFT_RIGHT_MARGINS_PX + 'px',
        },
        '&.slick-next': {
          right: DESKTOP_PAGE_LEFT_RIGHT_MARGINS_PX + 'px',
        },
      },
    },
  },
})

export const slideImagesWrapperClass = style({
  display: 'flex',
  flexDirection: 'row',
  flexWrap: 'nowrap',
  justifyContent: 'center',
  overflow: 'hidden',
  margin: '45px 0 0 0',
})

export const slideImageWrapperClass = style({
  flex: '1 1 0px',
  minWidth: '200px',
  padding: '0 1.4vw',
  marginBottom: '30px',
})

export const slideImageClass = style({
  display: 'block',
  maxWidth: '100%',
  height: 'auto',
  maxHeight: '320px',
  boxShadow: '0px 15px 20px rgba(0, 0, 0, 0.15)',
  margin: '0 auto',
})

export const homeBackgroundClass = style(
  {
    position: 'absolute',
    width: '100%',
    height: '100%',
    left: 0,
    top: 0,
    backgroundImage: `url(${homeBG})`,
    backgroundRepeat: 'repeat',
    backgroundPosition: 'top center',
    backgroundSize: 'contain',
    opacity: 1,
    //filter: 'blur(2px)',
  },
  media(
    { maxWidth: 960 },
    {
      backgroundImage: `url(${homeMobileBG})`,
    }
  )
)

export const exploreAnchorLinkClassName = style(
  {
    marginTop: '3rem',
    display: 'inline-block',
    fontSize: '14px',
  },
  media(
    { maxWidth: 960 },
    {
      display: 'none',
    }
  )
)

export const exploreAnchorIconClassName = style({
  boxShadow: '1px 1px 3px 3px #00000012',
  display: 'flex',
  margin: '1rem auto 0',
  width: '32px',
  height: '32px',
  borderRadius: '50%',
  justifyContent: 'center',
  alignContent: 'center',
  alignItems: 'center',
  background: 'transparent',
  color: 'black',

  $nest: {
    '&:hover': {
      color: 'white',
      background: 'black',
    },
  },
})

export const exploreLinkClassName = style(
  {
    borderBottom: '1px solid #AEAEAE',
    fontSize: '14px',
  },
  media(
    { maxWidth: 960 },
    {
      fontSize: '12px',
    }
  )
)

cssRule('.slick-slide[aria-hidden="true"]', {
  visibility: 'hidden',
})

cssRule('.slick-slide[aria-hidden="false"]', {
  visibility: 'visible',
})

cssRule('.slick-slide', {
  visibility: 'hidden',
})

cssRule('.slick-slide.slick-active', {
  visibility: 'visible',
})

export const slickTrackVisibilityFixClassName = style({
  $nest: {
    '.slick-slide': {
      visibility: 'visible',
    },
  },
})
