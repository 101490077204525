import { all, call, put, takeLatest } from 'redux-saga/effects'
import type from './journey.types'
import Service from '../../../services'

const Ser = Service()
const apiGet = new Ser.API_GET(process.env.REACT_APP_URL_API_STRAPI)

export function* tryJourneyList() {
  try {
    yield put({ type: type.TRY })

    const response = yield apiGet.getCuratedJourneys()

    if (!response.ok) throw new Error(response.problem)

    const payload = yield response.data

    yield put({ type: type.LIST_SUCCESS, payload })
  } catch (error) {
    yield put({
      type: type.FAILED,
      payload: { name: type.LIST_SUCCESS, error: error.message },
    })
  }
}

export function* watchJourneyList() {
  yield takeLatest(type.LIST_SUCCESS_REQUESTED, tryJourneyList)
}

export function* journeySagas() {
  yield all([call(tryJourneyList), watchJourneyList()])
}
