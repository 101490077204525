import { createSelector } from 'reselect'
import { NAME } from './filter.types'

export const selectFilter = state => state[NAME.toLowerCase()]

export const selecFilterLoading = createSelector(
  [selectFilter],
  filter => filter.isLoading
)

export const selectFilterList = createSelector(
  [selectFilter],
  filter => filter.list
)

export const selectFiltersSelected = createSelector(
  [selectFilter],
  filter => filter.selected
)
