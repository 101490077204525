import {
    arrowOnlyLeft,
    arrowOnlyRight,
    helpCursor
} from '../../assets';
import { safeAreaTop } from './helpScreen.utils';

export type HighlightPosition = [
    positionX: number,
    positionY: number,
    width: number,
    height: number
]

export type ArrowPosition = [
    positionX: number,
    positionY: number
];

type CursorPositionAbsolute = [
    positionX: number,
    positionY: number,
    absolutePosition: true
]

export type CursorPosition = [
    positionX: number,
    positionY: number,
] | CursorPositionAbsolute;

export class HelpScreenCanvas {
    private canvas: HTMLCanvasElement
    private ctx: CanvasRenderingContext2D;
    private _width: number = 0;
    private _height: number = 0;
    private centerX: number = 0;
    private arrowWidth: number = 1.5;
    private highlightPosition: HighlightPosition | null = null;
    private arrowPosition: ArrowPosition | null = null;
    private cursorPosition: CursorPosition | null = null;
    private arrowLeftElement: HTMLImageElement | null = null;
    private arrowRightElement: HTMLImageElement | null = null;
    private cursorElement: HTMLImageElement | null = null;

    public onLoad: ((canvasInstance: HelpScreenCanvas) => void) | null = null;
    public ready: boolean = false;

    constructor(canvas: HTMLCanvasElement) {
        const ctx = canvas.getContext('2d');

        if (!ctx) throw 'Error: No canvas 2d context';

        this.canvas = canvas;
        this.ctx = ctx;

        const leftArrowImg = document.createElement('img');
        const rightArrowImg = document.createElement('img');
        const cursorImg = document.createElement('img');

        leftArrowImg.setAttribute('src', arrowOnlyLeft);
        rightArrowImg.setAttribute('src', arrowOnlyRight);
        cursorImg.setAttribute('src', helpCursor);

        leftArrowImg.onload = () => {
            this.arrowLeftElement = leftArrowImg;
            loadCallback();
        };

        rightArrowImg.onload = () => {
            this.arrowRightElement = rightArrowImg;
            loadCallback();
        };

        cursorImg.onload = () => {
            this.cursorElement = cursorImg;
            loadCallback();
        };

        const loadCallback = () => {
            if (
                this.onLoad
                && this.arrowLeftElement
                && this.arrowRightElement
                && this.cursorElement
            ) {
                this.onLoad(this);
            }
        };
    }

    private fitCanvas() {
        this._width = window.innerWidth;
        this._height = window.innerHeight;
        this.centerX = this._width / 2;
        this.canvas.width = this._width;
        this.canvas.height = this._height;
    }

    private fillCanvas() {
        this.ctx.clearRect(0, 0, this._width, this._height);
        this.ctx.fillStyle = 'rgba(0, 0, 0, 0.7)';
        this.ctx.fillRect(0, 0, this._width, this._height);
    }

    private drawHighlight() {
        if (this.highlightPosition) {
            this.ctx.globalCompositeOperation = 'destination-out';
            this.ctx.filter = 'blur(2px)';
            this.ctx.fillStyle = 'rgba(255, 255, 255, 1)';
            this.ctx.fillRect(...this.highlightPosition);
            this.ctx.globalCompositeOperation = 'source-over';
            this.ctx.filter = 'none';
        }
    }

    private drawArrow() {
        if (!this.arrowPosition || !this.arrowLeftElement || !this.arrowRightElement) {
            return;
        }

        this.ctx.fillStyle = 'rgb(255, 255, 255)';
        this.ctx.fillRect(
            this.centerX - (this.arrowWidth / 2),
            safeAreaTop,
            this.arrowWidth,
            this.arrowPosition[1] + (this.arrowWidth / 2) - safeAreaTop
        );

        if (this.arrowPosition[0] < this.centerX) {
            this.ctx.fillRect(
                this.arrowPosition[0],
                this.arrowPosition[1] - (this.arrowWidth / 2),
                this.centerX - this.arrowPosition[0],
                this.arrowWidth
            );

            this.ctx.drawImage(
                this.arrowLeftElement,
                this.arrowPosition[0],
                this.arrowPosition[1] - (this.arrowLeftElement.height / 2)
            );
        }
        else if (this.arrowPosition[0] > this.centerX) {
            this.ctx.fillRect(
                this.centerX,
                this.arrowPosition[1] - (this.arrowWidth / 2),
                this.arrowPosition[0] - this.centerX,
                this.arrowWidth
            );

            this.ctx.drawImage(
                this.arrowRightElement,
                this.arrowPosition[0] - this.arrowRightElement.width,
                this.arrowPosition[1] - (this.arrowRightElement.height / 2)
            );
        }   
    }

    private drawCursor() {
        if (!this.cursorPosition || !this.cursorElement || !this.highlightPosition) {
            return;
        }

        this.ctx.drawImage(
            this.cursorElement,
            0, 0,
            this.cursorElement.width, 
            this.cursorElement.height,
            this.cursorPosition[0] + (this.cursorPosition[2] ? 0 : this.highlightPosition[0]),
            this.cursorPosition[1] + (this.cursorPosition[2] ? 0 : this.highlightPosition[1]),
            25, 30
        );
    }

    public set(
        highlightPosition: HighlightPosition | null,
        arrowPosition: ArrowPosition | null,
        cursorPosition: CursorPosition | null
    ) {
        this.highlightPosition = highlightPosition && [...highlightPosition];
        this.arrowPosition = arrowPosition && [arrowPosition[0], Math.max(arrowPosition[1], 30)];
        this.cursorPosition = cursorPosition && [...cursorPosition];
    }

    public update() {
        this.fitCanvas();
        this.fillCanvas();
        this.drawHighlight();
        this.drawArrow();
        this.drawCursor();
    }

    public get height() {
        return this._height;
    }

    public get width() {
        return this._width;
    }
}
