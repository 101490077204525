import { initialState } from './artwork.reducer'

const time = () => {
  let time = new Date()
  return time.toLocaleString('en-US', {
    timeZone: process.env.REACT_APP_TIME_ZONE,
  })
}

export const addError = (errors, action) => {
  const payload = action.payload

  const errorData = {
    name: payload.name,
    message: { error: payload.error, time: time() },
  }

  const errorId = errors.findIndex(error => error.message.time === time())

  if (errorId === -1) errors.push(errorData)
  else errors[errorId] = errorData
  return errors
}

export const setSelected = action => {
  return action.payload.map(rawData => ({
    title: rawData.title,
    id: rawData.id,
    img: rawData.image_url,
  }))
}

export const setList = action => {
  const payload = action.payload
  let list = initialState.list
  const totalPages = Math.ceil(
    payload.stats['@odata.count'] / payload.stats.top
  )

  list = {
    ...payload,
    pagination: {
      totalPages,
      skip: payload.stats.skip,
      top: payload.stats.top,
    },
  }
  return list
}

export const addNewPage = (list, action) => {
  const payload = action.payload

  const totalPages = Math.ceil(
    payload.stats['@odata.count'] / payload.stats.top
  )

  list = {
    ...payload,
    items: shuffle(payload.items),
    //items: payload.items,
    pagination: {
      totalPages,
      skip: payload.stats.skip,
      top: payload.stats.top,
    },
  }
  return list
}

export const shuffle = array => {
  let currentIndex = array.length,
    randomIndex

  // While there remain elements to shuffle...
  while (currentIndex !== 0) {
    // Pick a remaining element...
    randomIndex = Math.floor(Math.random() * currentIndex)
    currentIndex--

    // And swap it with the current element.
    ;[array[currentIndex], array[randomIndex]] = [
      array[randomIndex],
      array[currentIndex],
    ]
  }

  return array
}

export const deleteSelected = (selected, action) => {
  const payload = action.payload
  const cloneSelected = [...selected]
  cloneSelected.splice(payload.index, payload.howmany)
  return cloneSelected
}

export const addSelected = (selected, action) => {
  const id = action?.payload?.id || null

  const updateSelectedId = selected.findIndex(artwork => artwork.id === id)

  if (updateSelectedId === -1) {
    id && selected.push(action.payload)
  } else selected[updateSelectedId] = action.payload

  return selected
}

export function sleep(ms = 2000) {
  return new Promise(resolve => setTimeout(resolve, ms))
}
