import { useCallback, useEffect, useRef } from 'react'
import Controls from '../controls/controls.component'

import GridView from '../gridView/gridView.component'
import Graph from '../graph/graph.component'
import ErrorBoundary from '../errorBoundary/errorBoundary.component'

const Graph3d = ({
  data,
  current,
  closedControls,
  handleNodeClick,
  handleMouseEventsGridView,
  toggleGrid,
  show,
  isMobile,
  closeFilters,
  onClickCard,
  isFilterChanged,
  filtersList,
  onEngineStop = () => {},
  onNodeHover = () => {},
}) => {
  const fgRef = useRef(null)

  //let isScrolled = 0
  let posZ = 0
  let posX = 0
  let posY = 0
  let angle = 0
  //const group = arr => arr[Math.floor(Math.random() * arr.length)]

  // set random data included current selected and filter de current
  //
  useEffect(() => changeView(), [current.id, show])

  useEffect(() => {
    const keyPressEvent = event => {
      let movementName = null

      switch (event.code) {
        case 'ArrowUp':
          movementName = 'Pan Up'
          break

        case 'ArrowRight':
          movementName = 'Pan Right'
          break

        case 'ArrowDown':
          movementName = 'Pan Down'
          break

        case 'ArrowLeft':
          movementName = 'Pan Left'
          break

        case 'KeyR':
          rotate('left')
          break

        case 'Equal':
          movementName = 'Zoom In'
          break

        case 'Minus':
          movementName = 'Zoom Out'
          break
        default:
          movementName = null
          break
      }

      if (movementName && fgRef?.current) {
        const camera = fgRef.current.cameraPosition()

        posZ = camera.z
        posY = camera.y
        posX = camera.x

        movement(movementName)
      }
    }

    document.addEventListener('keydown', keyPressEvent)

    return () => {
      document.removeEventListener('keydown', keyPressEvent)
    }
  }, [])

  const changeView = () => {
    const gridView = document.querySelector('#gridView') || null
    const graph3d = document.querySelector('#graph3d') || null

    if (graph3d && gridView) {
      if (show === 'grid') {
        graph3d.style.display = 'none'
        gridView.style.display = 'block'
      } else if (show === 'network') {
        graph3d.style.display = 'block'
        gridView.style.display = 'none'

        const canvas = graph3d.querySelector('canvas') || null

        if (canvas) {
          canvas.setAttribute(
            'aria-label',
            'This is a 3D view of the related art pieces in canvas. If you need the content to be accessible, please use the grid view option instead.'
          )
        }
      }
    }
  }

  const handleControlsClick = name => {
    if (fgRef?.current) {
      const camera = fgRef.current.cameraPosition()
      posZ = camera.z
      posY = camera.y
      posX = camera.x

      if (name === 'Rotation') rotate('left')
      else movement(name)
    }
  }

  const movement = name => {
    switch (name) {
      case 'Zoom In':
        zoomInOut('in')
        return
      case 'Zoom Out':
        zoomInOut('out')
        return
      case 'Pan Up':
        posY -= 50
        break
      case 'Pan Down':
        posY += 50
        break
      case 'Pan Left':
        posX -= 50
        break
      case 'Pan Right':
        posX += 50
        break
      case 'Grid View':
      case 'Network View':
        changeView()
        //setShowGridView(!showGridView)
        toggleGrid()
        return
      case 'Reset':
        //posX = 0
        //posY = 0
        //posZ = 400
        //fgRef.current.cameraPosition({ x: posX, y: posY, z: posZ })
        //fgRef.current.zoomToFit(100, -500)
        if (fgRef?.current) {
          fgRef.current.cameraPosition(
            { x: 200, y: 150, z: 210 },
            { x: 0, y: 0, z: 0 },
            1000
          )
        }
        return
      default:
        return
    }
    const newPos = { x: posX, y: posY, z: posZ }
    if (fgRef?.current) {
      fgRef.current.cameraPosition(
        { ...newPos },
        { posX, posY, posZ },
        //{ x: 0, y: 0, z: 0 },
        1000
      )
    }
  }

  const handleNodeHover = node => {
    onNodeHover(node)
  }

  const zoomInOut = direction => {
    const distance = (direction === 'in' ? -1 : 1) * 20
    const distRatio = 1 + distance / Math.hypot(posX, posY, posZ)

    if (fgRef?.current) {
      fgRef.current.cameraPosition(
        {
          x: posX * distRatio,
          y: posY * distRatio,
          z: posZ * distRatio,
        }, // new position
        //{ posX, posY, posZ }, // lookAt ({ x, y, z })
        { x: 0, y: 0, z: 0 }, // lookAt ({ x, y, z })
        1000 // ms transition duration
      )
    }
  }

  const handleOnEngineStop = useCallback(() => {
    //console.log('handleOnEngineStop')
    onEngineStop()

    const coords = { x: 200, y: 250, z: 210 }

    fgRef?.current?.cameraPosition({ ...coords }, { x: 0, y: 0, z: 0 }, 1000)
  }, [fgRef])

  const rotate = useCallback(
    direction => {
      let timer = 0
      const distance = 400
      const interval = setInterval(() => {
        timer++
        if (direction === 'left' || direction === 'right') {
          fgRef?.current?.cameraPosition({
            x: distance * Math.sin(angle),
            z: distance * Math.cos(angle),
          })
          if (direction === 'left') {
            angle += Math.PI / 300
          } else if (direction === 'right') {
            angle -= Math.PI / 300
          }
        } else {
          fgRef?.current?.cameraPosition({
            y: distance * Math.sin(angle),
            z: distance * Math.cos(angle),
          })
          if (direction === 'up') {
            angle += Math.PI / 300
          } else if (direction === 'down') {
            angle -= Math.PI / 300
          }
        }
        if (timer > 300) clearInterval(interval)
      }, 10)
    },
    [fgRef]
  )
  return (
    <>
      <div id='graph3d'>
        <ErrorBoundary>
          <Graph
            ref={fgRef}
            data={data}
            show={show}
            current={current}
            handleNodeClick={handleNodeClick}
            handleNodeHover={handleNodeHover}
            handleOnEngineStop={handleOnEngineStop}
            isMobile={isMobile}
            filtersList={filtersList}
            isFilterChanged={isFilterChanged}
          />
        </ErrorBoundary>
      </div>
      <div id='gridView'>
        <GridView
          onMouseEvent={handleMouseEventsGridView}
          rawRelatedArtworks={[...data]}
          isMobile={isMobile}
          current={current}
          closeFilters={closeFilters}
          onClickCard={onClickCard}
        />
      </div>
      {show === 'network' && closedControls && (
        <Controls onClick={handleControlsClick} />
      )}
    </>
  )
}

export default Graph3d
