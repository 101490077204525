import { createContext, useState } from 'react'

const FiltersStateContext = createContext({
  currentActiveFilters: [],
  addActiveFilters: () => {},
  clearFilters: () => {},
})

function removeUnSelected(array) {
  const result = []
  let pos = 0
  let elemPos = 0

  array.forEach(elem => {
    if (Array.isArray(elem.options)) {
      result.push({
        name: elem.name,
        options: [],
      })
      elem?.options.forEach(option => {
        if (option.isSelected) {
          result[pos]?.options.push({ value: option.value, children: [] })

          option?.children?.forEach(child => {
            if (child?.isSelected) {
              result[pos]?.options[elemPos]?.children?.push({
                value: child.value,
              })
            }
          })
          ++elemPos
        }
      })
      ++pos
    }
  })
  return result
}

const FiltersStateProvider = ({ children }) => {
  const [currentActiveFilters, setCurrentActiveFilters] = useState([])
  const addActiveFilters = array => {
    setCurrentActiveFilters(removeUnSelected(array))
  }
  const clearFilters = () => setCurrentActiveFilters([])

  return (
    <FiltersStateContext.Provider
      value={{ currentActiveFilters, addActiveFilters, clearFilters }}
    >
      {children}
    </FiltersStateContext.Provider>
  )
}

const withFiltersState = Child => props =>
  (
    <FiltersStateContext.Consumer>
      {context => <Child {...props} context={context} />}
    </FiltersStateContext.Consumer>
  )

export { FiltersStateProvider, withFiltersState, FiltersStateContext }
