const time = () => {
  const time = new Date()
  return time.toLocaleString('en-US', {
    timeZone: process.env.REACT_APP_TIME_ZONE,
  })
}

export const addError = (errors, action) => {
  const payload = action.payload

  const errorData = {
    name: payload.name,
    message: { error: payload.error, time: time() },
  }

  const errorId = errors.findIndex(error => error.message.time === time())

  if (errorId === -1) errors.push(errorData)
  else errors[errorId] = errorData
  return errors
}

export const bubbleSort = (arr, key = null) => {
  if (Array.isArray(arr)) {
    const len = arr.length
    for (let i = len; i > 0; i--) {
      for (let j = len - i; j > 0; j--) {
        if (key && arr[j][key] < arr[j - 1][key]) {
          swap(arr, j, j - 1)
        } else if (arr[j] < arr[j - 1]) {
          swap(arr, j, j - 1)
        }
      }
    }
    return arr
  }
  return 'It is not an array'
}

const swap = (arr, firstIndex, lastIndex) => {
  let temp = arr[firstIndex]
  arr[firstIndex] = arr[lastIndex]
  arr[lastIndex] = temp
}
