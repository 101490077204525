import type from './artwork.types'
import * as utils from './artwork.utils'

export const initialState = {
  current: {
    show: 'network',
    item: { id: '' },
    related: {
      results: [],
      filters: [],
      stats: {},
    },
  },
  list: {
    pagination: {
      totalPages: 0,
      skip: 0,
      top: 0,
    },
    stats: {
      '@odata.count': 0,
      top: 100,
      skip: '0',
    },
    items: [],
  },
  selected: [],
  errors: [
    //{ name: 'type', message: { error: '', time: '' } }
  ],
  isLoading: false,
}

const artworkReducer = (state = initialState, action) => {
  switch (action.type) {
    // cases
    case type.TRY:
      return { ...state, isLoading: true }

    case type.SET_RELATED:
      return {
        ...state,
        current: { ...action.payload, show: state.current.show },
        isLoading: false,
      }

    case type.CLEAR_RELATED:
      return {
        ...state,
        current: initialState.current,
        //current: { ...initialState.current, show: state.current.show },
        isLoading: false,
      }

    case type.CHANGE_VIEW:
      return {
        ...state,
        isLoading: false,
        current: { ...state.current, show: action.payload },
      }
    case type.LIST_SUCCESS: {
      const list = utils.setList(state.list, action)
      return { ...state, isLoading: false, list }
    }

    case type.ADD_SELECTED: {
      const selected = utils.addSelected(state.selected, action)
      return { ...state, selected, isLoading: false }
    }

    case type.LIST_ADD_PAGE: {
      const list = utils.addNewPage(state.list, action)
      return { ...state, list, isLoading: false }
    }

    case type.LIST_RESET_PAGE: {
      return {
        ...state,
        list: {
          ...initialState.list,
          pagination: {
            ...initialState.list.pagination,
          },
          items: [],
        },
      }
    }

    case type.SET_SELECTED: {
      const selected = utils.setSelected(action)
      return { ...state, selected, isLoading: false }
    }

    case type.DELETE_SELECTED:
      const deleted = utils.deleteSelected(state.selected, action)
      return { ...state, selected: [...deleted], isLoading: false }

    case type.CLEAR_SELECTED:
      return { ...state, selected: [], isLoading: false }

    case type.FAILED:
      const errors = utils.addError(state.errors, action)
      return { ...state, errors, isLoading: false }

    default:
      return state
  }
}

export default artworkReducer
