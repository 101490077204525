import { createSelector } from 'reselect'
import { NAME } from './artwork.types'

export const selectArtwork = state => state[NAME.toLowerCase()]

export const selectArtworkLoading = createSelector(
  [selectArtwork],
  artwork => artwork.isLoading
)

export const selectCurrentRelated = createSelector(
  [selectArtwork],
  artwork => artwork.current
)

export const show = createSelector(
  [selectCurrentRelated],
  current => current.show
)

export const selectArtworkList = createSelector(
  [selectArtwork],
  artwork => artwork.list.items
)

export const selectArtworkListFiltersCounts = createSelector(
  [selectArtwork],
  artwork => artwork.list.filters
)

export const selectArtworkListStats = createSelector(
  [selectArtwork],
  artwork => artwork.list.stats
)

export const selectArtworkListCount = createSelector(
  [selectArtworkListStats],
  artwork => artwork['@odata.count']
)

export const selectArtworkListPagination = createSelector(
  [selectArtwork],
  artwork => artwork.list.pagination
)

export const selectArtworkListNumPage = createSelector(
  [selectArtworkListPagination],
  pagination => pagination.pageNum
)

export const selectArtworkSelected = createSelector(
  [selectArtwork],
  artwork => artwork.selected
)
