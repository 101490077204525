import React, { createRef } from 'react'
import {
  helpScreenWrapperClass,
  helpScreenOverlayClass,
  helpScreenTopContentClass,
  helpScreenPaginatorClass,
  helpScreenTitleClass,
  helpScreenStepTitleClass,
  helpScreenPaginatorPrevClass,
  helpScreenPaginatorItemClass,
  helpScreenPaginatorNextClass,
  helpScreenPaginatorItemActiveClass,
  helpScreenContentClass,
  closeButtonClass,
  floatingElementClass,
} from './helpScreen.style'
import { HelpScreenCanvas, HighlightPosition } from './helpScreen.canvas'
import { helpSteps, StepConfig } from './helpScreen.steps'
import { classes } from 'typestyle'
import {
  applyMarginsToSquarePosition,
  getContentPosition,
  getArrowPosition,
} from './helpScreen.utils'
import {
  arrowLeftLightCircle,
  arrowRightLightCircle,
  closeIconLight,
} from '../../assets'
import { TabArea } from '../../tabArea.service'

interface HelpScreenProps {
  onSkip?: () => void
  onClose?: () => void
}

interface HelpScreenState {
  currentStepIndex: number;
  canvasReady: boolean;
}

const closeSpeed = 500;

class HelpScreen extends React.PureComponent<HelpScreenProps, HelpScreenState> {
  private wrapperRef = createRef<HTMLDivElement>();
  private canvasRef = createRef<HTMLCanvasElement>();
  private contentRef = createRef<HTMLDivElement>();
  private canvasAPI: HelpScreenCanvas | null = null;
  private tabArea: TabArea | null = null;
  private topContentBackground: boolean = false;

  public state: HelpScreenState = {
    currentStepIndex: 0,
    canvasReady: false,
  }

  constructor(props: HelpScreenProps) {
    super(props);

    this.skip = this.skip.bind(this);
    this.handleClickPrev = this.handleClickPrev.bind(this);
    this.handleClickNext = this.handleClickNext.bind(this);
    this.handleResizeWindow = this.handleResizeWindow.bind(this);
  }

  componentDidMount() {
    if (this.canvasRef.current) {
      this.canvasAPI = new HelpScreenCanvas(this.canvasRef.current)
      this.canvasAPI.onLoad = canvasAPI => {
        this.setState({
          canvasReady: true,
        })

        if (this.wrapperRef.current) {
          this.tabArea = new TabArea(this.wrapperRef.current)
        }
        window.addEventListener('resize', this.handleResizeWindow)
      }
    }

    const el = document.querySelector<HTMLDivElement>(`.${helpScreenWrapperClass}`);

    if (el) {
      setTimeout(() => {
        el.style.opacity = '1';
      }, 10);
    }
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.handleResizeWindow)

    if (this.tabArea) {
      this.tabArea.restore()
    }
  }

  private handleResizeWindow() {
    this.canvasAPI?.update()
  }

  private handleClickPrev(): void {
    const prevIndex = this.state.currentStepIndex - 1

    if (prevIndex > -1) {
      this.setState({
        currentStepIndex: prevIndex,
      })
    }
  }

  private handleClickNext(): void {
    const nextIndex = this.state.currentStepIndex + 1

    if (nextIndex < helpSteps.length) {
      this.setState({
        currentStepIndex: nextIndex,
      })
    } else if (nextIndex >= helpSteps.length) {
      this.skip()
    }
  }

  private draw(): [StepConfig, HighlightPosition] {
    const currentStep = helpSteps[this.state.currentStepIndex];
    const highlightPosition = applyMarginsToSquarePosition(
      currentStep.highlightPosition(),
      currentStep.highlightMargin
    );

    this.topContentBackground = Boolean(currentStep.topContentBackground);

    setTimeout(() => {
      if (this.canvasAPI) {
        const arrowPosition = currentStep.hasArrow && this.contentRef.current
            ? getArrowPosition(this.contentRef.current, currentStep)
            : null;

        this.canvasAPI.set(
          highlightPosition,
          arrowPosition,
          Array.isArray(currentStep.cursor)
            ? currentStep.cursor
            : currentStep.cursor()
        );
        this.canvasAPI.update();
        
      }
    }, 10)

    return [currentStep, highlightPosition];
  }

  private keyboardInteraction(
    keyboardEvent: React.KeyboardEvent,
    callback: (() => void) | undefined
  ) {
    const interactionKeyCodes: string[] = ['Enter', 'Space'];

    if (callback && interactionKeyCodes.indexOf(keyboardEvent.code) != -1) {
      keyboardEvent.preventDefault();
      callback();
    }
  }

  private skip() {
    const onSkip = this.props.onSkip;
    const el = document.querySelector<HTMLDivElement>(`.${helpScreenWrapperClass}`);

    if (el) {
      el.style.opacity = '0';
    }

    if (onSkip) {
      setTimeout(() => {
        onSkip.call(onSkip);
      }, closeSpeed);
    }

  }

  private renderSteps() {
    const { currentStepIndex } = this.state;
    const [currentStep, highlightPosition] = this.draw()

    return (
      <div className={helpScreenOverlayClass}>
        <div className={classes(helpScreenTopContentClass, this.topContentBackground && floatingElementClass)}>
          <a
            className={classes(closeButtonClass, this.topContentBackground && floatingElementClass)}
            onClick={() => this.skip()}
            onKeyPress={e => this.keyboardInteraction(e, this.skip)}
            aria-label='Close tutorial screen'
            tabIndex={0}
          >
            <img src={closeIconLight} alt='Close tutorial screen' />
          </a>
          <div className={helpScreenTitleClass}>User Tips</div>
          <div>
            <ul className={helpScreenPaginatorClass}>
              <li className={helpScreenPaginatorPrevClass}>
                <a
                  onClick={this.handleClickPrev}
                  onKeyPress={e =>
                    this.keyboardInteraction(e, this.handleClickPrev)
                  }
                  tabIndex={0}
                  aria-label='Go to next step'
                >
                  <img src={arrowLeftLightCircle} />
                </a>
              </li>
              {helpSteps.map((helpStep, index) => (
                <li
                  key={index}
                  className={classes(
                    helpScreenPaginatorItemClass,
                    index === currentStepIndex &&
                      helpScreenPaginatorItemActiveClass
                  )}
                >
                  <a
                    onClick={() => this.setState({ currentStepIndex: index })}
                    onKeyPress={e =>
                      this.keyboardInteraction(e, () =>
                        this.setState({ currentStepIndex: index })
                      )
                    }
                    tabIndex={0}
                    aria-label={'Go to step ' + (index + 1)}
                  >
                    {String(index + 1).padStart(2, '0')}
                  </a>
                </li>
              ))}
              <li className={helpScreenPaginatorNextClass}>
                <a
                  onClick={this.handleClickNext}
                  onKeyPress={e =>
                    this.keyboardInteraction(e, this.handleClickNext)
                  }
                  tabIndex={0}
                  aria-label='Go to previous step'
                >
                  <img src={arrowRightLightCircle} />
                </a>
              </li>
            </ul>
          </div>
          <div className={helpScreenStepTitleClass}>{currentStep.title}</div>
        </div>
        <div
          ref={this.contentRef}
          className={helpScreenContentClass}
          style={getContentPosition(
            highlightPosition,
            currentStep.contentPosition,
            currentStep.contentWidth,
            this.canvasAPI?.height || window.innerHeight,
            this.canvasAPI?.width || window.innerWidth
          )}
        >
          {currentStep.content}
        </div>
      </div>
    )
  }

  render() {
    return (
      <div ref={this.wrapperRef} className={helpScreenWrapperClass}>
        <canvas ref={this.canvasRef} />
        {this.state.canvasReady ? this.renderSteps() : ''}
      </div>
    )
  }
}

export default HelpScreen
