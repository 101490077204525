import React from 'react'
import feauturesStyles from './feautures.module.scss'

type FeauturesProps = {
  amount?: number
  loading?: boolean
}
const Feautures: React.FC<FeauturesProps> = ({
  children,
  amount = 0,
  loading,
}) => (
  <div className={feauturesStyles.wrapper}>
    <p className={feauturesStyles.text}>
      <span className={feauturesStyles.amount}>
        {loading ? 'loading' : amount}
      </span>{' '}
      Artworks Found
    </p>
    <div>{children}</div>
  </div>
)

export default Feautures
