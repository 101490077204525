import { useState, useEffect } from 'react'

const useModal = () => {
  const [isShowing, setIsShowing] = useState(false)

  useEffect(() => {
    document.addEventListener('keydown', onKeyPressed)
    return () => {
      document.removeEventListener('keydown', onKeyPressed)
    }
  }, [])

  function toggle() {
    setIsShowing(!isShowing)
  }

  const onKeyPressed = e => {
    if (e.keyCode === 27) setIsShowing(false)
  }

  return {
    isShowing,
    toggle,
  }
}

export default useModal
