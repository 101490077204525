import { useEffect, useState } from 'react'
import { fromEvent } from 'rxjs'
import { map, throttleTime } from 'rxjs/operators'

export default function useMousePosition(time = 100) {
  const [x, setX] = useState(null)
  const [y, setY] = useState(null)

  useEffect(() => {
    const sub = fromEvent(document, 'mousemove')
      .pipe(
        throttleTime(time),
        map(event => [event.clientX, event.clientY])
      )
      .subscribe(([newX, newY]) => {
        setX(newX)
        setY(newY)
      })

    return () => {
      sub.unsubscribe()
    }
  }, [time])

  const getPos = el => {
    // yay readability
    for (
      var lx = 0, ly = 0;
      el != null;
      lx += el.offsetLeft, ly += el.offsetTop, el = el.offsetParent
    );
    setX(lx)
    setY(ly)
    return { x: lx, y: ly }
  }

  const elementDrag = () => {
    const e = window.event
    const cx = x - e.clientX
    const cy = y - e.clientY

    return { cx, cy }
  }

  return {
    mouseX: x,
    mouseY: y,
    elementDrag,
    getPos,
  }
}
