import { createBrowserHistory } from 'history'
import { create } from 'apisauce'
import axios from 'axios'

type SelectedArtwork = {
  id: string
  title: string
  url: string
}

type SaveJourneyArtworkParams = {
  __component: 'artwork-grouping.artwork'
  Title: string
  ArtworkID: string
  Image_url: string
  Description: string | null
}

type SaveJourneyParams = {
  Title: string
  Description: string
  short_description: string
  curator_name: string
  curator_email: string
  journey_url: string
  published_at: string | null
  Artworks: SaveJourneyArtworkParams[]
}

export const parseSelectedArtworks = (
  rawSelectedArtworks: any
): SelectedArtwork[] => {
  const result: SelectedArtwork[] = []

  if (rawSelectedArtworks && Array.isArray(rawSelectedArtworks)) {
    rawSelectedArtworks.forEach(rawSelectedArtwork => {
      if (rawSelectedArtwork.id && rawSelectedArtwork.img) {
        result.push({
          id: String(rawSelectedArtwork.id),
          title: String(rawSelectedArtwork.title),
          url: String(rawSelectedArtwork.img),
        })
      }
    })
  }

  return result
}

export const getUrlField = (): string => {
  const history = createBrowserHistory()
  return history.location.pathname + history.location.search
}

const endpointAPI: string = 'https://interwoven-admin.map-india.org'
const urlAPI: string = '/curated-journeys'

const saveAPI = create({
  baseURL: endpointAPI,
  headers: {
    Accept: 'application/json',
  },
  timeout: 10240,
})

export const saveJourneyRequest = (token: string, params: SaveJourneyParams) =>
  new Promise<string>((resolve, reject) => {
    saveAPI
      .post(urlAPI, params, {
        headers: {
          Authorization: 'Bearer ' + token,
        },
      })
      .then(response => {
        if (response.ok) {
          resolve('Created curated journey')
        } else {
          reject(response.problem)
        }
      })
      .catch(() => {
        reject('Unknown error')
      })
  })

export const getAuthTokenRequest = (password: string) =>
  new Promise<string>((resolve, reject) => {
    axios
      .post('https://interwoven-admin.map-india.org/auth/local', {
        identifier: 'info@pastpresentfuture.com',
        password,
      })
      .then(response => {
        resolve(response.data.jwt as string)
      })
      .catch(error => {
        reject(error.response)
      })
  })
