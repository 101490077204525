import React, { createRef } from 'react'
import { Link } from 'react-router-dom'
import Slider, { Settings as SliderSettings } from 'react-slick'
import 'slick-carousel/slick/slick.css'
import Content from '../../components/content/content.component'
import Anchor from '../../components/anchor/anchor.component'
import {
  exploreAnchorIconClassName,
  exploreAnchorLinkClassName,
  exploreLinkClassName,
  homepageContentClass,
  homepageH1,
  screenCenteredContentClass,
  mobileOnlyClass,
  section1Class,
  section2Class,
  section3Class,
  section4Class,
  slideImageClass,
  slideImagesWrapperClass,
  slideImageWrapperClass,
  sliderWrapperClass,
  slickTrackVisibilityFixClassName,
} from './home-page.style'
import { padNumber, parseSliderData } from './home-page.utils'
import MainMenu from '../../components/main-menu/main-menu.component'

import { connect } from 'react-redux'
import { createStructuredSelector } from 'reselect'
import { actions, selectors } from '../../state/modules'
import { Helmet } from 'react-helmet-async'
import Control from '../../components/control/control.component'
import { classes } from 'typestyle'
import { replaceImageName } from '../../components/inmersiveView/inmersiveView.utils'
import { scrollToId } from '../../components/anchor/anchor.utils'
import { MAPCircleLogo, poweredByMicrosoft } from '../../assets'
import ImagePreview from '../../components/imagePreview/imagePreview.component'
import { homeBG } from '../../assets'

export type HomeSliderImage = {
  alt: string
  src: string
}

export type CuratedJourneyForSlider = {
  id?: number
  title: string
  description: string
  short_description: string
  journey_url: string
  images: HomeSliderImage[]
}

interface HomePageState {
  sliderData?: CuratedJourneyForSlider[]
  hasMainLogo?: boolean
}

interface HomeProps {
  loadPage: (name: string) => {}
  pages: any
  journeyList?: []
  journeyLoading?: boolean
  loadJourneyList?: any
  //resetPagination: () => {}
}

const addFixClassToSlickTrack = () => {
  const slickTrackElement = document.querySelector('.slick-track')

  if (slickTrackElement) {
    slickTrackElement.classList.add(slickTrackVisibilityFixClassName)
  }
}
const removeFixClassToSlickTrack = () => {
  const slickTrackElement = document.querySelector('.slick-track')

  if (slickTrackElement) {
    slickTrackElement.classList.remove(slickTrackVisibilityFixClassName)
  }
}

let currentHomeSlider: Slider | null = null

class HomePage extends React.PureComponent<HomeProps, HomePageState> {
  private sliderSettings: SliderSettings = {
    customPaging: (i: number) => <a>{padNumber(i + 1, 2)}</a>,
    prevArrow: <Control icon={'icon-left-arrow'} />,
    nextArrow: <Control icon={'icon-right-arrow'} />,
    dots: true,
    infinite: true,
    speed: 900,
    slidesToShow: 1,
    slidesToScroll: 1,
    easing: 'easeOutElastic',
    className: sliderWrapperClass,
    beforeChange: () => addFixClassToSlickTrack,
    swipeEvent: () => addFixClassToSlickTrack,
    afterChange: () => removeFixClassToSlickTrack,
    onInit: () =>
      setTimeout(() => {
        const clonedSlicks = this.sliderContainerRef.current
          ?.querySelector('.slick-track')
          ?.querySelectorAll('.slick-cloned')

        if (!clonedSlicks) return

        Array.prototype.forEach.call(
          clonedSlicks,
          (clonedSlick: HTMLDivElement) => {
            Array.prototype.forEach.call(
              clonedSlick.querySelectorAll('a'),
              (clonedA: HTMLLinkElement) => {
                clonedA.setAttribute('tabindex', '-1')
              }
            )
          }
        )

        const slickDotsListElement = document.querySelector('ul.slick-dots')
        const dotElements = slickDotsListElement
          ? slickDotsListElement.querySelectorAll<HTMLLinkElement>('a')
          : null

        if (dotElements) {
          Array.prototype.forEach.call(
            dotElements,
            (dotElement: HTMLLinkElement, index) => {
              dotElement.setAttribute('tabindex', '0')
              dotElement.addEventListener('keydown', event => {
                if (currentHomeSlider) {
                  if (['Enter', ' '].indexOf(event.key) !== -1) {
                    currentHomeSlider.slickGoTo(index)
                  }
                }
              })
            }
          )
        }
      }, 500),
  }
  private bigImageWapperRef = createRef<HTMLImageElement>()
  private sliderContainerRef = createRef<HTMLDivElement>()
  private slider: Slider | null = null
  private scrollEvent: (event: Event) => void = (event: Event) => {}

  state: HomePageState = {
    hasMainLogo: false,
  }

  componentDidMount() {
    this.props.loadPage('home')
    this.props.loadJourneyList()

    const hash = window.location.hash.slice(1)
    if (hash) {
      scrollToId(hash)
    }

    //this.setState({
    //sliderData: parseSliderData(this.props.journeyList),
    //})
    const self = this

    this.scrollEvent = (event: Event) => {
      self.checkLogo()
    }
    //this.props.loadFiltersList()
    document.addEventListener('scroll', this.scrollEvent)
  }

  componentWillUnmount() {
    document.removeEventListener('scroll', this.scrollEvent)
  }

  checkLogo() {
    const bigImageWrapperElement = this.bigImageWapperRef.current
    const bigImageElement = bigImageWrapperElement
      ? bigImageWrapperElement.querySelector('img')
      : null

    if (bigImageElement) {
      if (
        bigImageElement.getBoundingClientRect().top +
          bigImageElement.offsetHeight <
        0
      ) {
        if (!this.state?.hasMainLogo) {
          this.setState({
            hasMainLogo: true,
          })
        }
      } else {
        if (this.state?.hasMainLogo) {
          this.setState({
            hasMainLogo: false,
          })
        }
      }
    }
  }

  hasMainLogoFn(): boolean {
    return Boolean(this.state && this.state.hasMainLogo)
  }

  render() {
    return (
      <>
        <Helmet>
          <meta name='robots' content='noindex' />
        </Helmet>

        <Content
          fullScreen
          backgroundImage={homeBG}
          className={homepageContentClass}
        >
          <MainMenu mainLogo={this.hasMainLogoFn()} />
          <section className={section1Class} role='contentinfo'>
            <div className={screenCenteredContentClass}>
              <div className={mobileOnlyClass}>
                <a
                  target='_blank'
                  href='https://map-india.org/'
                  aria-label="External link to MAP's website"
                  rel='noreferrer'
                >
                  <img
                    src={MAPCircleLogo}
                    height='40'
                    alt='MAP'
                    aria-label='This is the official logo of MAP'
                  />
                </a>
                <a
                  target='_blank'
                  href='https://www.microsoft.com/en-us/'
                  aria-label="External link to Microsoft's website"
                  rel='noreferrer'
                >
                  <img
                    src={poweredByMicrosoft}
                    height='35'
                    alt='Powered by Microsoft'
                    aria-label='This is the official logo of Microsoft'
                  />
                </a>
              </div>
              <div
                ref={this.bigImageWapperRef}
                dangerouslySetInnerHTML={{
                  __html: this.props.pages['home'].top_content,
                }}
              ></div>
              <Anchor
                to='home-section-2'
                ariaLabel='Explore'
                className={exploreAnchorLinkClassName}
              >
                EXPLORE
                <span
                  className={classes(
                    'icon-down-arrow',
                    exploreAnchorIconClassName
                  )}
                />
              </Anchor>
            </div>
          </section>
          <section
            className={section2Class}
            id='home-section-2'
            role='contentinfo'
            dangerouslySetInnerHTML={{
              __html: this.props.pages['home'].center_content,
            }}
          ></section>
          <hr className='hidden' id='journey-slider' />

          <section
            className={section3Class}
            ref={this.sliderContainerRef}
            id='curated-journeys-section'
          >
            <Slider
              ref={slider => {
                this.slider = slider
                currentHomeSlider = slider
              }}
              {...this.sliderSettings}
            >
              {parseSliderData(this.props.journeyList).map((data, i) => (
                <div
                  key={i}
                  role='slider'
                  aria-valuemin={1}
                  aria-valuemax={this.state.sliderData?.length || 0}
                  aria-valuenow={i + 1}
                  aria-valuetext={`Slide ${i + 1}`}
                >
                  <h1 className={homepageH1}>{data.title}</h1>
                  <p>
                    {(data.short_description !== 'null'
                      ? data.short_description
                      : data.description
                    ).replace(/^(.{150}[^\s]*).*/, '$1')}
                  </p>
                  <Link
                    className={exploreLinkClassName}
                    to={data.journey_url}
                    onFocus={e => {
                      // this.slider?.slickGoTo(i);
                    }}
                  >
                    Explore
                  </Link>
                  <div className={slideImagesWrapperClass}>
                    {data.images.map((img, i) => (
                      <div className={slideImageWrapperClass} key={i}>
                        <ImagePreview
                          src={replaceImageName(img.src)}
                          alt={img.alt}
                          className={slideImageClass}
                        />
                        {
                          //<img
                          //className={slideImageClass}
                          //src={replaceImageName(img.src)}
                          //alt={img.alt}
                          ///>
                        }
                      </div>
                    ))}
                  </div>
                </div>
              ))}
            </Slider>
          </section>
          <section className={section4Class}>
            <div
              dangerouslySetInnerHTML={{
                __html: this.props.pages['home'].custom_journey_content,
              }}
            />
            <Link
              to='/artworks'
              //onClick={this.props.resetPagination}
              className={exploreLinkClassName}
            >
              Explore
            </Link>
          </section>
        </Content>
      </>
    )
  }
}
const mapStateToProps = createStructuredSelector({
  pages: selectors.page.selectPages,
  journeyList: selectors.journey.selectJourneyList,
  journeyLoading: selectors.journey.selectJourneyLoading,
})

const mapDispatchToProps = (dispatch: any) => ({
  loadPage: (name: string) => dispatch(actions.page.setPage(name)),
  loadJourneyList: () => dispatch(actions.journey.setJourneyList()),
  //resetPagination: () => dispatch(actions.artwork.resetPagination()),
})

export default connect(mapStateToProps, mapDispatchToProps)(HomePage)
