import Slider from 'rc-slider'
import 'rc-slider/assets/index.css'
import { useEffect, useState, MouseEvent } from 'react'
import rangeStyles from './customRange.module.scss'

//const createSliderWithTooltip = Slider.createSliderWithTooltip
//const Range = createSliderWithTooltip(Slider.Range)
const { Range } = Slider

interface Opt {
  label: string
  value: number
}
interface ICustomRange {
  min: number
  max: number
  itemId: number
  name: string
  options: Opt[] | any
  onClick?: any
  defaultSelected?: [number, number]
  selected?: [number, number]
  isSelected?: boolean
}

const Message: React.FC<{
  selectedValues: number[]
  options: Opt[]
}> = ({ selectedValues, options }) => {
  const leftText = options[selectedValues[0]].label
  const rightText = options[selectedValues[1]].label
  return (
    <div>
      <b>From </b>
      {leftText !== rightText ? (
        <>
          {leftText} <b>to</b> {rightText}
        </>
      ) : (
        leftText
      )}
    </div>
  )
}

const CustomRange: React.FC<ICustomRange> = ({
  min = 0,
  max,
  options,
  itemId,
  name,
  onClick,
  // by defaul all are selected from min to max
  defaultSelected,
  selected,
  isSelected,
}) => {
  const [isMoving, setIsMoving] = useState(false)
  const [rangeValue, setRangeValue] = useState({
    lowerBound: { value: 0, label: '' },
    upperBound: { value: 0, label: '' },
    defaultSelected: defaultSelected || [min, max],
    selected,
    min,
    max,
  })

  useEffect(() => {
    setIsMoving(true)
  }, [])

  useEffect(() => {
    setRangeValue({
      ...rangeValue,
      lowerBound: options[rangeValue.defaultSelected[0]],
      upperBound: options[rangeValue.defaultSelected[1]],
    })
  }, [options])

  const onSliderChange = (val: any) => {
    setIsMoving(true)

    setRangeValue({
      ...rangeValue,
      lowerBound: options[val[0]],
      upperBound: options[val[1]],
      selected: val,
      min: val[0],
      max: val[1],
    })
  }

  const handleClick = (e: MouseEvent) => {
    e.preventDefault()
    const value =
      rangeValue.lowerBound.label !== rangeValue.upperBound.label
        ? `${rangeValue.lowerBound.label} - ${rangeValue.upperBound['label']}`
        : rangeValue.lowerBound.label

    //setIsMoving(!isSelected ? true : false)
    //
    setIsMoving(false)

    onClick &&
      onClick({
        value,
        ...rangeValue,
        head: name,
        headId: itemId,
        isRange: true,
      })
  }

  const selectedValues =
    isSelected || isMoving ? [rangeValue.min, rangeValue.max] : [min, max]

  return (
    <div className={rangeStyles.selectedRange}>
      <Range
        min={min}
        max={max || 2}
        value={selectedValues}
        defaultValue={rangeValue.defaultSelected}
        onChange={onSliderChange}
        trackStyle={[
          {
            color: 'black',
            background: '#333333',
            //boxShadow: '0px 0px 0px 5px red',
          },
        ]}
        handleStyle={[
          {
            borderColor: 'black',
            boxShadow: '0px 0px 0px 5px rgba(0,0,0, .08)',
          },
          {
            borderColor: 'black',
            boxShadow: '0px 0px 0px 5px rgba(0,0,0, .08)',
          },
        ]}
      />

      <ul>
        <li className={rangeStyles.info}>
          <Message options={options} selectedValues={selectedValues}></Message>

          <button
            className={isSelected ? rangeStyles.btnActive : ''}
            onClick={e => handleClick(e)}
          >
            SET
          </button>
        </li>
      </ul>
    </div>
  )
}

export default CustomRange
