import { useState } from 'react'
import ImagePlaceholder from '../imagePlaceholder/imagePlaceholder.component'
import imagePreviewStyles from './imagePreview.style.module.scss'

const ImagePreview = ({ src, alt = '', className = '' }) => {
  const [loaded, setLoaded] = useState(false)

  const handleImageLoad = e => {
    setLoaded(true)
  }

  return (
    <div className={imagePreviewStyles.wrapper}>
      {!loaded && (
        <ImagePlaceholder
          style={{ position: 'absolute', let: 0, top: 0, zIndex: 1 }}
        />
      )}
      <img className={className} onLoad={handleImageLoad} alt={alt} src={src} />
    </div>
  )
}

export default ImagePreview
