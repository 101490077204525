import { forwardRef, useState, memo } from 'react'
import ImagePlaceholder from '../imagePlaceholder/imagePlaceholder.component'
import { replaceImageName } from '../inmersiveView/inmersiveView.utils'
import cardStyles from './card.module.scss'

const Card = forwardRef(
  (
    {
      onClick = () => {},
      PrimaryTitle,
      primary_image,
      Markings,
      Dated,
      artist,
      Dimensions,
      ObjectNumber,
      Description,
      Medium,
      image_url,
      id,
      showShadow,
      imagePreviewStyles = {},
      style = {},
      className = '',
      ...otherProps
    },
    ref
  ) => {
    const [loaded, setLoaded] = useState(false)
    const current = {
      id,
      Medium,
      PrimaryTitle,
      Dated,
      artist,
      Dimensions,
      ObjectNumber,
      primary_image,
      image_url,
      class: otherProps.class,
      ...otherProps,
    }

    const handleImage = e => {
      setLoaded(true)
      setTimeout(() => {
        e.target.style.transition = '.5s ease'
        e.target.style.opacity = 1
        e.target.style.height = 'auto'
      }, 50)
    }

    return (
      <article
        className={`${cardStyles.card} ${
          showShadow ? cardStyles.shadow : ''
        } ${className}`}
        style={{ ...style }}
        ref={ref}
      >
        {image_url && (
          <>
            <button
              className={cardStyles.button}
              data-name='image'
              onClick={e => {
                e.preventDefault()
                onClick(current, e)
              }}
              aria-label={
                PrimaryTitle ||
                Description ||
                otherProps.title ||
                'Art image button'
              }
            >
              {!loaded && (
                <ImagePlaceholder style={{ ...imagePreviewStyles }} />
              )}
              <img
                loading='lazy'
                className={`${cardStyles.image}`}
                style={{ opacity: 0, height: 0 }}
                onLoad={handleImage}
                src={replaceImageName(image_url, 'mid')}
                alt={
                  PrimaryTitle || Description || otherProps.title || 'Art image'
                }
              />
            </button>

            <button
              className={cardStyles.info}
              data-name='info'
              onClick={e => onClick(current, e)}
              tabIndex={-1}
              aria-label='Art information'
            >
              i
            </button>
          </>
        )}
      </article>
    )
  }
)

export default memo(Card)
