import React from 'react';
import { wrapperClass } from './wrapper.style';

const Wrapper: React.FC = ({children}) => {
    return (
        <div className={ wrapperClass }>
            { children }
        </div>
    )
};

export default Wrapper;
