import React, { FC, useState } from 'react'
import {
  logoMapGray,
  placeholderShareLeft,
  placeholderShareRight,
} from '../../assets'
import Control from '../control/control.component'
import {
  shareJourneyBodyClass,
  shareJourneyContainerClass,
  shareJourneyLeftClass,
  shareJourneyMapLogoClass,
  shareJourneyPreviewClass,
  shareJourneyPreviewWrapperClass,
  shareJourneyRowClass,
  shareJourneyTextClass,
  shareJourneyTitleClass,
  shareJourneySocialLinksClass,
  shareJourneyFormClass,
  shareJourneyInputErrorClass,
  shareJourneySubmitDisabledClass,
  shareJourneyFormErrorMessageClass,
  shareJourneyFormSuccessMessageClass,
} from './shareScreen.style'
import axios from 'axios'
import { decodeIds } from '../../utils'
import { current } from '../gridView/gridView.style'

interface ShareScreenProps {
  title: string
  url: string
  emailForm?: boolean
  emailSubject?: string
  emailText?: string
}

const ShareScreen: FC<ShareScreenProps> = ({
  url,
  emailForm = false,
  title,
  emailSubject = '',
  emailText = '',
}) => {
  const [emailValue, changeEmailValue] = useState<string>('')
  const [formHasBeenTouched, changeFormHasBeenTouched] =
    useState<boolean>(false)
  const [formIsSubmitted, changeFormIsSubmitted] = useState<boolean>(false)
  const [formIsSubmittedError, changeFormIsSubmittedError] =
    useState<boolean>(false)

  const journeyLink: string =
    'https://interwoven.map-india.org/journey' + window.location.search

  const journeyIds: string[] = decodeIds(journeyLink.split('?q=')[1])

  const minIdsCount: number = 3
  const idsAreValid: boolean = journeyIds.length >= minIdsCount
  const emailIsValid: boolean =
    !!emailValue.length &&
    ((splittedEmailForAtVerification: string[]) =>
      !!splittedEmailForAtVerification[1] &&
      !!splittedEmailForAtVerification[1].length &&
      ((splittedEmailForDomainVerification: string[]) =>
        !!splittedEmailForDomainVerification[1] &&
        !!splittedEmailForDomainVerification[1].length)(
        splittedEmailForAtVerification[1].split('.')
      ))(emailValue.split('@'))

  const onChangeInputEmailHandler: React.ChangeEventHandler<
    HTMLInputElement
  > = event => {
    changeFormHasBeenTouched(event.target.value.length > 0)
    changeEmailValue(event.target.value)
  }

  const onSubmitHandler: React.FormEventHandler = event => {
    event.preventDefault()
    event.stopPropagation()

    if (!formIsSubmitted && idsAreValid && emailIsValid) {
      console.log('send')
      axios
        .post('https://interwoven-admin.map-india.org/user-journeys', {
          user_email: emailValue,
          journey_link: journeyLink,
        })
        .then(response => {
          changeFormIsSubmitted(true)
        })
        .catch(error => {
          changeFormIsSubmittedError(true)
        })
    }
  }

  return (
    <div className={shareJourneyContainerClass}>
      <div className={shareJourneyBodyClass}>
        <div className={shareJourneyTitleClass}>{title}</div>
        <div className={shareJourneyRowClass}>
          <div className={shareJourneyLeftClass}>
            <div className={shareJourneyPreviewWrapperClass}>
              <div className={shareJourneyPreviewClass}>
                <img src={placeholderShareLeft} alt='Left' />
              </div>
              <img
                className={shareJourneyMapLogoClass}
                src={logoMapGray}
                alt='MAP'
              />
            </div>
            <div className={shareJourneyTextClass}>
              Select a platform below to share on social media or email.
              <ul className={shareJourneySocialLinksClass}>
                <li>
                  {console.log('url', encodeURIComponent(url))}
                  <Control
                    icon='icon-twitter'
                    socialIcon
                    name='Share on Twitter'
                    onClick={() =>
                      window.open(
                        `https://twitter.com/intent/tweet?url=${encodeURIComponent(
                          url
                        )}&text=MAP and Microsoft present INTERWOVEN`
                      )
                    }
                  />
                </li>
                <li>
                  <Control
                    icon='icon-facebook'
                    socialIcon
                    name='Share on Facebook'
                    onClick={() =>
                      window.open(
                        `https://www.facebook.com/sharer/sharer.php?u=${encodeURIComponent(
                          url
                        )}&quote=MAP and Microsoft present INTERWOVEN`
                      )
                    }
                  />
                </li>
                <li>
                  <Control
                    icon='icon-mail'
                    socialIcon
                    name='Share via Mail'
                    onClick={() =>
                      window.open(
                        `mailto:?subject=${emailSubject}&body=${emailText} ${encodeURIComponent(
                          url
                        )}`
                      )
                    }
                  />
                </li>
              </ul>
            </div>
          </div>
          {emailForm && (
            <div className={shareJourneyLeftClass}>
              <div className={shareJourneyPreviewWrapperClass}>
                <div className={shareJourneyPreviewClass}>
                  <img src={placeholderShareRight} alt='Right' />
                </div>
              </div>
              <div className={shareJourneyTextClass}>
                Share your journey with MAP curators to contribute to global
                textiles research.
                <form
                  className={shareJourneyFormClass}
                  onSubmit={onSubmitHandler}
                >
                  <input
                    type='email'
                    placeholder='Enter Email'
                    value={emailValue}
                    className={
                      formHasBeenTouched && !emailIsValid
                        ? shareJourneyInputErrorClass
                        : ''
                    }
                    disabled={formIsSubmitted}
                    onChange={onChangeInputEmailHandler}
                  />
                  <button
                    type='submit'
                    children='Submit'
                    className={
                      formIsSubmitted || !idsAreValid || !emailIsValid
                        ? shareJourneySubmitDisabledClass
                        : ''
                    }
                  />
                  {!idsAreValid && (
                    <div className={shareJourneyFormErrorMessageClass}>
                      You need at least {minIdsCount} artwork
                      {minIdsCount !== 1 && 's'} to share your custom journey to
                      curators.
                    </div>
                  )}
                  {formIsSubmitted && (
                    <div className={shareJourneyFormSuccessMessageClass}>
                      Thank you for submitting your journey
                    </div>
                  )}
                  {formIsSubmittedError && (
                    <div className={shareJourneyFormErrorMessageClass}>
                      An error has occurred
                    </div>
                  )}
                </form>
              </div>
            </div>
          )}
        </div>
      </div>
    </div>
  )
}

export default ShareScreen
