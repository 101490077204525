import { useEffect, useRef } from 'react'
import CardList from '../cardList/cardList.component'
import Card from '../card/card.component'
import { replaceImageName } from '../inmersiveView/inmersiveView.utils'
import * as gridStyles from './gridView.style'
import useMousePosition from '../../hooks/useMousePosition.hook'

interface GridViewProps {
  rawRelatedArtworks: any
  current: any
  onMouseEvent?: any
  closeFilters: boolean
  onClickCard?: any
  isMobile?: boolean
}

const GridView: React.FC<GridViewProps> = ({
  rawRelatedArtworks,
  current,
  closeFilters,
  onMouseEvent,
  onClickCard,
  isMobile,
}) => {
  const cardImageRef = useRef(null)
  const { getPos } = useMousePosition()

  useEffect(() => {
    const image = cardImageRef.current || { style: { position: {} } }

    if (image) {
      setTimeout(() => {
        image.style.position = 'fixed'
      }, 1000)
    }
  }, [cardImageRef])

  const handleClickCard = (data: any) => {
    if (cardImageRef && cardImageRef.current) {
      const currentCard = cardImageRef.current || { offsetWidth: 0 }

      window.scroll({
        top: 0,
        behavior: 'smooth',
      })

      const currentCardPosition = getPos(currentCard)

      const minWidth =
        window.innerWidth - window.innerWidth / (closeFilters ? 4 : 5)
      //const minWidth = currentCard.offsetWidth * (closeFilters ? 1.9 : 2)

      const verticalPos = 0
      //containerRef.current.scrollTop -
      //currentCard.scrollHeight +
      //currentCard.offsetHeight

      const direction = currentCardPosition.x > minWidth ? -1 : 1 // - left / + right
      const columnCard = document.querySelector('.card') || { clientWidth: 0 }
      const columnCardWidth = columnCard.clientWidth - 20

      onClickCard &&
        onClickCard(data, {
          current: currentCard,
          position: currentCardPosition,
          vertical: verticalPos,
          minWidth,
          direction,
          columnCardWidth,
        })
    }
  }
  //change
  return (
    rawRelatedArtworks &&
    Array.isArray(rawRelatedArtworks) && (
      <div
        className={`${closeFilters ? gridStyles.closed : ''} ${
          gridStyles.gridViewContainerClass
        }`}
      >
        {!isMobile && (
          <div className={gridStyles.gridViewLeft}>
            <Card
              {...current}
              style={{ width: closeFilters ? '22.5%' : '18.5%' }}
              ref={cardImageRef}
              className={gridStyles.current}
              onClick={(curr: any) => handleClickCard(curr)}
            />
            {/*Hidden image*/}
            <img
              style={{ opacity: 0 }}
              src={replaceImageName(current.image_url)}
              alt={current.Title}
            />
          </div>
        )}
        <div className={gridStyles.gridViewRight}>
          {isMobile && (
            <div style={{ textAlign: 'center' }}>
              <Card
                {...current}
                ref={cardImageRef}
                onClick={(curr: any) => handleClickCard(curr)}
                className={gridStyles.currentMobile}
              />
              <img
                style={{ opacity: 0, height: 0 }}
                src={replaceImageName(current.image_url)}
                alt={current.Title}
              />
            </div>
          )}
          <CardList
            onClickCard={onClickCard}
            artworks={rawRelatedArtworks}
            paddingTop={isMobile ? '0' : '70px'}
            breakpointColumns={{
              default: closeFilters ? 4 : 3,
              960: 3,
              500: 2,
            }}
          ></CardList>
        </div>
      </div>
    )
  )
}

export default GridView
