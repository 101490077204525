import React, { createRef, useEffect } from 'react'
import { classes } from 'typestyle'
import {
  backgroundClass,
  contentClass,
  contentFullScreenClass,
  innerClass,
  innerXSpacedClass,
  topLineClass,
} from './content.style'

interface ContentProps {
  fullScreen?: boolean
  xSpacing?: boolean
  topLine?: boolean
  backgroundImage?: string
  background?: React.ReactNode
  className?: string
}

const Content: React.FC<ContentProps> = ({
  children,
  fullScreen = false,
  xSpacing = false,
  topLine = false,
  backgroundImage,
  background,
  className,
}) => {
  const wrapperRef = createRef<HTMLDivElement>()

  useEffect(() => {
    if (backgroundImage && wrapperRef.current) {
      const wrapperElement = wrapperRef.current
      const scrollEvent = () => {
        wrapperElement.style.backgroundPositionY =
          window.scrollY / -(window.innerWidth < 965 ? 12 : 2) + 'px'
      }

      //scrollEvent();
      document.addEventListener('scroll', scrollEvent)

      return () => {
        document.removeEventListener('scroll', scrollEvent)
      }
    }
  }, [backgroundImage])

  const allContent = (
    <>
      {background && <div className={backgroundClass}>{background}</div>}
      {topLine && <hr className={topLineClass} />}
      <div className={classes(innerClass, xSpacing && innerXSpacedClass)}>
        {children}
      </div>
    </>
  )

  return (
    <div
      ref={wrapperRef}
      className={classes(
        contentClass,
        fullScreen && contentFullScreenClass,
        className
      )}
      {...(backgroundImage
        ? {
            style: {
              backgroundImage: `url("${backgroundImage}")`,
            },
          }
        : {})}
      children={allContent}
    />
  )
}

export default Content
