import { style } from 'typestyle';
import { DESKTOP_MAIN_MENU_HEIGHT_PX, DESKTOP_PAGE_LEFT_RIGHT_MARGINS_PX } from '../../constants';

export const contentClass = style({
    $debugName: 'CONTENT',
    padding: `${DESKTOP_MAIN_MENU_HEIGHT_PX}px 0 0 0`,
    minHeight: '100vh',
    backgroundRepeat: 'repeat',
    backgroundPosition: 'top center',
    backgroundAttachment: 'fixed',
    backgroundSize: 'contain',
    position: 'relative',

    $nest: {
        '&::after': {
            content: `''`,
            display: 'block',
            width: '100%',
            height: '1px',
            margin: '-1px 0 0 0',
            pointerEvents: 'none'
        }
    }
});

export const innerClass = style({
    $debugName: 'CONTENT_INNER',
    display: 'block',
    position: 'relative',
    margin: 0,
    padding: 0,
    minHeight: `calc(100vh - ${DESKTOP_MAIN_MENU_HEIGHT_PX}px)`
});

export const innerXSpacedClass = style({
    marginLeft: `${DESKTOP_PAGE_LEFT_RIGHT_MARGINS_PX}px`,
    marginRight: `${DESKTOP_PAGE_LEFT_RIGHT_MARGINS_PX}px`
});

export const backgroundClass = style({
    $debugName: 'CONTENT_BACKGROUND',
    minHeight: '100%',
    width: '100%',
    position: 'absolute',
    zIndex: -1,
    pointerEvents: 'none'
});

export const topLineClass = style({
    position: 'absolute',
    display: 'block',
    height: '1px',
    backgroundColor: '#C7C7C7',
    border: 'none',
    margin: 0,
    padding: 0,
    top: `${DESKTOP_MAIN_MENU_HEIGHT_PX}px`,
    left: `${DESKTOP_PAGE_LEFT_RIGHT_MARGINS_PX}px`,
    width: `calc(100% - ${DESKTOP_PAGE_LEFT_RIGHT_MARGINS_PX * 2}px)`
});

// FULLSCREEN MODE

export const contentFullScreenClass = style({
    $debugName: 'CONTENT_FULLSCREEN',
    padding: 0,
    $nest: {
        [`.${innerClass}`]: {
            minHeight: '100vh'
        }
    }
});