import { all, call } from 'redux-saga/effects'
import { sagas } from './modules'

export default function* rootSaga() {
  yield all([
    call(sagas.artwork.artworkSagas),
    call(sagas.filter.filterSagas),
    call(sagas.journey.journeySagas),
    call(sagas.page.pageSagas),
  ])
}
