import { all, put, takeLatest } from 'redux-saga/effects'
import type from './filter.types'
import Service from '../../../services'
import { removeFilterFields } from './filter.utils'

const Ser = Service()
const apiGet = new Ser.API_GET()

export function* tryFilterList(action) {
  try {
    yield put({ type: type.TRY })
    const response = yield apiGet.getFilters(action.payload)
    if (!response.ok) throw new Error(response.problem)

    let payload = yield response.data

    if (!action.payload) {
      payload = removeFilterFields(payload)
    }
    yield put({ type: type.LIST_SUCCESS, payload })
  } catch (error) {
    yield put({
      type: type.FAILED,
      payload: {
        name: type.LIST_SUCCESS,
        error: error.message,
      },
    })
  }
}

export function* watchFilterList() {
  yield takeLatest(type.LIST_SUCCESS_REQUESTED, tryFilterList)
}

export function* filterSagas() {
  yield all([
    //call(tryFilterList),
    //call(tryCurrentWeather),
    watchFilterList(),
  ])
}
