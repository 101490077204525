import type from './filter.types'

export const setListFilters = all => ({
  type: type.LIST_SUCCESS_REQUESTED,
  payload: all,
})

export const updateListFilters = filter => ({
  type: type.UPDATE_LIST,
  payload: filter,
})

export const updateListFiltersCounts = filters => ({
  type: type.UPDATE_LIST_COUNTS,
  payload: filters,
})

export const cleanFilters = cleanAll => ({
  type: type.CLEAN_LIST,
  payload: cleanAll,
})

export const filtersFailed = error => ({
  type: type.FAILED,
  payload: error,
})
