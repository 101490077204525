import type from './artwork.types'

export const setRelatedArtworks = (id, body = []) => ({
  type: type.SET_RELATED_REQUESTED,
  payload: { id, body },
})

export const clearRelated = () => ({
  type: type.CLEAR_RELATED,
})

export const changeView = name => ({
  type: type.CHANGE_VIEW,
  payload: name,
})

export const setListArtwork = artworks => ({
  type: type.LIST_SUCCESS_REQUESTED,
  payload: artworks,
})

export const addListPage = payload => ({
  type: type.LIST_ADD_PAGE_REQUESTED,
  payload,
})

export const resetPagination = () => ({
  type: type.LIST_RESET_PAGE,
})

export const addArtwork = artwork => ({
  type: type.ADD_SELECTED,
  payload: artwork,
})

export const deleteSelected = fromTo => ({
  type: type.DELETE_SELECTED,
  payload: fromTo,
})

export const clearSelected = () => ({
  type: type.CLEAR_SELECTED,
})

export const setSelectedArtworks = ids => ({
  type: type.SET_SELECTED_REQUESTED,
  payload: ids,
})

export const artworkFailed = error => ({
  type: type.FAILED,
  payload: error,
})
