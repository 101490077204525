import { useLocation } from 'react-router'

export const useQuery = (): URLSearchParams => {
  return new URLSearchParams(useLocation().search)
}

export const defaultMuseumFiltersHeader = [
  {
    name: 'Museum',
    options: [
      {
        value: 'MAP',
      },
      {
        value: 'CHICAGO',
      },
      {
        value: 'CLEVELAND',
      },
      {
        value: 'COOPERHEWITT',
      },
      {
        value: 'HARVARD',
      },
      {
        value: 'MET',
      },
      {
        value: 'MIA',
      },
      {
        value: 'PARIS',
      },
      {
        value: 'RISD',
      },
      {
        value: 'ROM',
      },
      {
        value: 'SMITHSONIAN',
      },
      {
        value: 'VA',
      },
      {
        value: 'WEAVERS',
      }
    ],
  },
]
