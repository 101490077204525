import { createContext, useState } from 'react'

const ArtworkContext = createContext({
  currentArtworks: [],
  addCurrent: () => {},
  clearArtworks: () => {},
})

function removeDuplicates(array) {
  const result = []
  for (let i = 0; i < array.length; i++) {
    let exists = false
    for (let j = 0; j < result.length; j++) {
      if (array[i]?.img === result[j]?.img) {
        exists = true
        break
      }
    }
    if (!exists) {
      result.push(array[i])
    }
  }
  return result
}

const ArtworkProvider = ({ children }) => {
  const [currentArtworks, setCurrentArtworks] = useState([])
  const addCurrent = current => {
    current.img &&
      setCurrentArtworks(oldArray => {
        const filterd = removeDuplicates([...oldArray, current])
        return filterd
      })
  }

  const clearArtworks = () => setCurrentArtworks([])

  return (
    <ArtworkContext.Provider
      value={{ currentArtworks, addCurrent, clearArtworks }}
    >
      {children}
    </ArtworkContext.Provider>
  )
}

const withArtwork = Child => props =>
  (
    <ArtworkContext.Consumer>
      {context => <Child {...props} context={context} />}
    </ArtworkContext.Consumer>
  )

export { ArtworkProvider, withArtwork, ArtworkContext }
