import { decodeHTMLString } from '../../utils'
import { capitalizeFirstLetter, empty } from '../modal/modal.utils'

export default function ListItem({ name, value }) {
  return !empty(value) ? (
    <>
      <dt>{name}</dt>
      <dd>{decodeHTMLString(capitalizeFirstLetter(value))}</dd>
    </>
  ) : null
}
