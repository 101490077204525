import { style, media } from 'typestyle'
import {
  DESKTOP_PAGE_LEFT_RIGHT_MARGINS_PX,
  FONT_FAMILY_GEOMETRIA,
  FONT_FAMILY_LEITURA,
  FONT_SIZE_SMALL,
} from '../../constants'

export const artworkContentClass = style({
  background: '#C4C4C4',
})

export const line = style(
  {
    borderLeft: '1px solid #E0E0E0',
    paddingLeft: '1.3rem',
    marginLeft: '.5rem',
  },

  media(
    { maxWidth: 960 },
    {
      paddingLeft: '.5rem',
    }
  )
)

export const onSlide = style(
  {
    border: 'none',
    borderBottom: '1px solid #E0E0E0',
    margin: '0 0 0.5rem',
    padding: '0.5rem 0',
  },
  media(
    { maxWidth: 960 },
    {
      paddingBottom: '0',
    }
  )
)

export const icon = style(
  {
    fontSize: '0.7rem',
    margin: '0',
    marginRight: '10px',
  },

  media(
    { maxWidth: 960 },
    {
      alignSelf: 'center',
      fontSize: '1.2rem',
      marginRight: '2px',
    }
  )
)

export const group = style(
  media(
    { maxWidth: 900 },
    {
      display: 'block!important',
      margin: '0 0.2rem!important',

      $nest: {
        li: {
          marginTop: 0,
        },
      },
    }
  )
)

export const header = style(
  { display: 'flex' },
  media(
    { maxWidth: 960 },
    {
      flexDirection: 'column',
      alignItems: 'flex-start!important',
    }
  )
)

export const headerCollapsed = style(
  {
    $nest: {
      h1: {
        overflow: 'hidden',
        textOverflow: 'ellipsis',
        whiteSpace: 'nowrap',
      },
      h2: {
        overflow: 'hidden',
        textOverflow: 'ellipsis',
        whiteSpace: 'nowrap',
      },
    },
  },
  media(
    { maxWidth: 960 },
    { $nest: { h1: { width: '70vw' }, div: { width: '70vw' } } }
  )
)

export const button = style({
  textTransform: 'uppercase',
  fontSize: '12px',
  alignSelf: 'baseline',
  border: 'none',
  background: 'none',
  cursor: 'pointer',
  textDecoration: 'underline',
  marginBottom: '1rem',
  paddingLeft: 0,
  fontFamily: FONT_FAMILY_GEOMETRIA,
  color: '#464646',
  $nest: {
    '&:hover': {
      textDecoration: 'none',
    },
  },
})

export const topBottomWrapperClass = style({
  display: 'flex',
  flexDirection: 'column',
  flexWrap: 'nowrap',
  overflow: 'hidden',
})

export const topContainerClass = style({
  margin: '0 0 40vh 0',
})

export const controls = style(
  {
    listStyleType: 'none',
    margin: 0,
    padding: 0,
    display: 'flex',
    $nest: {
      li: {
        marginLeft: '1rem',
        display: 'inline-block',
        $nest: {
          '&:first-child': {
            marginLeft: 0,
          },
        },
      },
    },
  },
  media(
    { maxWidth: 960 },
    {
      display: 'flex',
      $nest: {
        li: {
          display: 'none',
          $nest: {
            '&:last-child': {
              display: 'block',
              marginLeft: 0,
            },
          },
        },
      },
    }
  )
)

export const artworkTitleClass = style(
  {
    display: 'flex',
    justifyContent: 'space-between',
    position: 'relative',
    lineHeight: '120%',
    fontSize: '1.9rem',
    textTransform: 'capitalize',
    color: 'black',
    fontFamily: FONT_FAMILY_LEITURA,
    fontStyle: 'normal',
    fontWeight: 'normal',
    alignItems: 'center',
  },
  media(
    { maxWidth: 960 },
    {
      fontSize: '14px',
      paddingTop: '5px',
      paddingBottom: '5px',
      $nest: {
        ul: {
          paddingLeft: '0.5rem',
        },
      },
    }
  )
)

export const artworkDescriptionClass = style(
  {
    borderTop: '1px solid #E0E0E0',
    borderBottom: '1px solid #E0E0E0',
    margin: '20px 0 5px 0',
    padding: '2px 0 0 0',
    display: 'block',
    flexDirection: 'row',
    alignItems: 'center',
    marginTop: '1rem',
  },

  media(
    { maxWidth: 960 },
    {
      display: 'flex',
      flexDirection: 'column',
      margin: '10px 0 5px 0',
      padding: '5px 0',
    }
  )
)

export const artworkDescriptionToggleClass = style(
  {
    $debugName: 'artworkDescriptionClass',
    padding: '0 15px 0 0',
    flexShrink: 0,
    flexGrow: 0,
    textTransform: 'uppercase',
    fontFamily: FONT_FAMILY_GEOMETRIA,
    fontSize: '12px',
    lineHeight: '18px',
    boxSizing: 'content-box',
    alignSelf: 'baseline',
    width: '100%',
    $nest: {
      img: {
        margin: '0 10px 0 0',
      },
    },
  },

  media({ maxWidth: 960 }, { padding: 0 })
)

export const artworkDescriptionToggleButtonClass = style(
  {
    $debugName: 'artworkDescriptionToggleButtonClass',
    border: 'none',
    background: 'none',
    cursor: 'pointer',
    display: 'flex',
    width: '100%',
    color: 'black',
    padding: '10px 0',

    $nest: {
      h1: {
        fontSize: '12px',
        lineHeight: '15px',
        textAlign: 'left',
        padding: 0,
        margin: 0,
        marginRight: 10,
        width: '178px',
        //flex: '1 1 115px',
        fontFamily: FONT_FAMILY_GEOMETRIA,
        textTransform: 'uppercase',
        whiteSpace: 'nowrap',
      },
      div: {
        flex: '1 1 70%',
        textAlign: 'left',
      },
      h2: {
        fontSize: '15px',
        lineHeight: '15px',
        fontFamily: FONT_FAMILY_LEITURA,
        fontWeight: 'normal',
        padding: 0,
        margin: 0,
      },
    },
  },

  media(
    { maxWidth: 960 },
    {
      display: 'flex',
      flexDirection: 'row-reverse',
      justifyContent: 'space-between',
      alignItems: 'center',
      fontSize: FONT_SIZE_SMALL,
      width: '100%',
      overflow: 'hidden',

      padding: '7px 0',
      $nest: {
        h1: {
          marginBottom: '0.1rem',
          flex: '1 1 100%',
          fontSize: 12,
        },
        div: {
          flex: '1 1 100%',
          fontSize: FONT_SIZE_SMALL,
        },
        h2: {
          marginTop: 0,
          fontSize: FONT_SIZE_SMALL,
        },
        img: {
          marginRight: -10,
        },
      },
    }
  )
)

export const artworkDescriptionDetailClass = style(
  {
    fontFamily: FONT_FAMILY_LEITURA,
    fontWeight: 400,
    lineHeight: '130%',
    fontSize: '15px',
    overflow: 'auto',
    maxHeight: '20vh',
    margin: '.8rem 0',
  },

  media(
    { maxWidth: 960 },
    {
      alignSelf: 'baseline',
      paddingRight: '3rem',
      marginLeft: 0,
      width: '107%',
    }
  )
)

export const artworkDescriptionDetailCollapsedClass = style({
  height: '18px',
  whiteSpace: 'nowrap',
})

export const journeyInfoClass = style({
  position: 'relative',
  bottom: 0,
  width: '100%',
  backgroundColor: 'transparent',
})

export const bottomContentClass = style(
  {
    $debugName: 'DESCRIPTION_CONTAINER',
    background: 'linear-gradient(#FFFFFF, #FAFAFA)',
    position: 'fixed',
    zIndex: 100,
    bottom: 0,
    left: 0,
    width: '100%',
    padding: `1.2rem ${DESKTOP_PAGE_LEFT_RIGHT_MARGINS_PX}px 0 ${DESKTOP_PAGE_LEFT_RIGHT_MARGINS_PX}px`,
    maxHeight: '65vh',
  },

  media({ maxWidth: 960 }, { padding: `12px 0.7rem 10px` })
)
