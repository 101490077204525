import React, { Fragment } from 'react'
import {
  closeSaveFormButtonClass,
  submitButton,
  submitButtonContainer,
  saveForm,
  saveFormField,
  saveFormImage,
  saveFormImageSelected,
  saveFormImagesLine,
  savePopupClass,
  savePopupHead,
  saveWrapperClass,
} from './saveJourneyAdminOnly.style'
import { createStructuredSelector } from 'reselect'
import { selectors } from '../../state/modules'
import { connect } from 'react-redux'
import {
  getAuthTokenRequest,
  getUrlField,
  parseSelectedArtworks,
  saveJourneyRequest,
} from './saveJourneyAdminOnly.utils'
import { classes } from 'typestyle'

interface SaveJourneyAdminOnlyProps {
  rawSelectedArtworks?: any
}

export interface SaveJourneyAdminOnlyState {
  isVisible: boolean
  isLoading: boolean
  fieldTitle: string
  fieldShortDescription: string
  fieldLongDescription: string
  fieldNameOfCurator: string
  fieldEmailOfCurator: string
  fieldsArtworkDescription: { [artworkId: string]: string }
  selectedArtworkId: string | null
  authToken: string | null
  password: string
}

class SaveJourneyAdminOnly extends React.PureComponent<
  SaveJourneyAdminOnlyProps,
  SaveJourneyAdminOnlyState
> {
  public state: SaveJourneyAdminOnlyState = {
    isVisible: false,
    isLoading: false,
    fieldTitle: '',
    fieldLongDescription: '',
    fieldShortDescription: '',
    fieldNameOfCurator: '',
    fieldEmailOfCurator: '',
    fieldsArtworkDescription: {},
    selectedArtworkId: null,
    authToken: null,
    password: '',
  }

  private keyboardEvent: ((event: KeyboardEvent) => void) | null = null
  private artworkUrl: string = ''

  componentDidMount() {
    const self = this

    document.addEventListener(
      'keydown',
      (this.keyboardEvent = event => {
        const { keyCode, shiftKey } = event
        const { isVisible, isLoading } = self.state

        if (shiftKey && keyCode === 83 && !isVisible) {
          self.setState({
            isVisible: true,
          })
        } else if (keyCode === 27 && isVisible && !isLoading) {
          self.setState({
            isVisible: false,
          })
        }
      })
    )
  }

  componentWillUnmount() {
    if (this.keyboardEvent) {
      document.removeEventListener('keydown', this.keyboardEvent)
    }
  }

  private async login() {
    if (this.state.password.length) {
      this.setState({
        isLoading: true,
      })

      try {
        const authToken: string = await getAuthTokenRequest(this.state.password)

        this.setState({
          authToken,
          password: '',
          isLoading: false,
        })
      } catch (error) {
        this.setState({
          isLoading: false,
        })
      }
    }
  }

  private async saveJourney() {
    const {
      fieldTitle,
      fieldShortDescription,
      fieldLongDescription,
      fieldNameOfCurator,
      fieldEmailOfCurator,
      fieldsArtworkDescription,
      authToken,
    } = this.state

    if (!authToken) return

    this.setState({ isLoading: true })

    try {
      const successMessage = await saveJourneyRequest(authToken, {
        Title: fieldTitle,
        Description: fieldLongDescription,
        short_description: fieldShortDescription,
        curator_name: fieldNameOfCurator,
        curator_email: fieldEmailOfCurator,
        published_at: null,
        journey_url: this.artworkUrl,
        Artworks: parseSelectedArtworks(this.props.rawSelectedArtworks).map(
          selectedArtwork => ({
            __component: 'artwork-grouping.artwork',
            Title: selectedArtwork.title,
            ArtworkID: selectedArtwork.id,
            Image_url: selectedArtwork.url,
            Description: fieldsArtworkDescription[selectedArtwork.id] || null,
          })
        ),
      })

      this.setState({
        fieldTitle: '',
        fieldShortDescription: '',
        fieldLongDescription: '',
        isLoading: false,
        isVisible: false,
        selectedArtworkId: null,
        fieldsArtworkDescription: {},
      })

      alert(successMessage)
    } catch (errorMessage) {
      this.setState({ isLoading: false })

      alert(errorMessage)
    }
  }

  private header = (title: string) => (
    <div className={savePopupHead}>{title}</div>
  )

  private closeButton = () => (
    <div
      className={closeSaveFormButtonClass}
      onClick={event => {
        event.preventDefault()
        event.stopPropagation()
        this.setState({ isVisible: false })
      }}
    >
      <span></span>
      <span></span>
    </div>
  )

  private submitButton = (text: string, action: () => void) => (
    <div className={submitButtonContainer}>
      <button
        className={submitButton}
        type='button'
        onClick={event => {
          event.preventDefault()
          action()
        }}
        disabled={this.state.isLoading}
      >
        {text}
      </button>
    </div>
  )

  render() {
    const {
      isVisible,
      isLoading,
      fieldTitle,
      fieldLongDescription,
      fieldShortDescription,
      fieldNameOfCurator,
      fieldEmailOfCurator,
      fieldsArtworkDescription,
      selectedArtworkId,
      authToken,
    } = this.state

    const { rawSelectedArtworks } = this.props
    const selectedArtworks = parseSelectedArtworks(rawSelectedArtworks)
    this.artworkUrl = getUrlField()

    const getArtworkNumber = (id: string): number => {
      for (let i = 0; i < selectedArtworks.length; i++) {
        if (selectedArtworks[i].id === selectedArtworkId) {
          return i + 1
        }
      }

      return 0
    }

    const validSelectedArtworkId = (): string | null => {
      for (let i = 0; i < selectedArtworks.length; i++) {
        if (selectedArtworks[i].id === selectedArtworkId) {
          return selectedArtworkId
        }
      }

      return null
    }

    return (
      isVisible && (
        <div className={saveWrapperClass}>
          <div className={savePopupClass}>
            {authToken ? (
              <Fragment>
                {this.header('Create Curated Journey')}
                {this.closeButton()}
                <div className={saveForm}>
                  <FormField
                    name='Title'
                    value={fieldTitle}
                    onChange={fieldTitle => this.setState({ fieldTitle })}
                    disabled={isLoading}
                  />

                  <FormField
                    large
                    name='Short Description'
                    value={fieldShortDescription}
                    onChange={fieldShortDescription =>
                      this.setState({ fieldShortDescription })
                    }
                    disabled={isLoading}
                  />
                  {/* Journey Description */}
                  <FormField
                    large
                    name='Long description'
                    value={fieldLongDescription}
                    onChange={fieldLongDescription =>
                      this.setState({ fieldLongDescription })
                    }
                    disabled={isLoading}
                  />
                  <FormField
                    name='Name of Curator'
                    value={fieldNameOfCurator}
                    onChange={fieldNameOfCurator =>
                      this.setState({ fieldNameOfCurator })
                    }
                    disabled={isLoading}
                  />
                  <FormField
                    name='Email of Curator'
                    value={fieldEmailOfCurator}
                    onChange={fieldEmailOfCurator =>
                      this.setState({ fieldEmailOfCurator })
                    }
                    disabled={isLoading}
                  />
                  <FormField
                    name='Artwork Url'
                    value={this.artworkUrl}
                    readOnly={true}
                  />
                  <hr />
                  <FormField name='Selected Artworks'>
                    <div className={saveFormImagesLine}>
                      {selectedArtworks.map((selectedArtwork, index) => (
                        <div
                          className={classes(
                            saveFormImage,
                            selectedArtworkId === selectedArtwork.id &&
                              saveFormImageSelected
                          )}
                          key={index}
                          onClick={() => {
                            this.setState({
                              selectedArtworkId: selectedArtwork.id,
                            })
                          }}
                          style={{
                            backgroundImage: `url('${selectedArtwork.url}')`,
                          }}
                        />
                      ))}
                    </div>
                  </FormField>
                  {selectedArtworkId && validSelectedArtworkId() ? (
                    <FormField
                      large
                      name={`Artwork #${getArtworkNumber(
                        selectedArtworkId
                      )} description`}
                      value={fieldsArtworkDescription[selectedArtworkId] || ''}
                      onChange={fieldArtworkDescription =>
                        this.setState({
                          fieldsArtworkDescription: {
                            ...fieldsArtworkDescription,
                            [selectedArtworkId]: fieldArtworkDescription,
                          },
                        })
                      }
                      disabled={isLoading}
                    />
                  ) : (
                    <FormField
                      large
                      name={`Artwork description`}
                      value={'(Choose one)'}
                      disabled={true}
                    />
                  )}
                  <hr />
                  {this.submitButton('Save', () => this.saveJourney())}
                </div>
              </Fragment>
            ) : (
              <Fragment>
                {this.header('Login - Create Curated Journey')}
                {this.closeButton()}
                <div className={saveForm}>
                  <FormField
                    name='Enter password'
                    type={'password'}
                    value={this.state.password}
                    onChange={password => this.setState({ password })}
                    disabled={isLoading}
                  />
                </div>
                {this.submitButton('Login', () => this.login())}
              </Fragment>
            )}
            <Fragment></Fragment>
          </div>
        </div>
      )
    )
  }
}

const mapStateToProps = createStructuredSelector({
  rawSelectedArtworks: selectors.artwork.selectArtworkSelected,
})

interface FormFieldDefaultProps {
  children?: never
  name: string
  large?: boolean
  value: string
  onChange?: (newValue: string) => void
  disabled?: boolean
  readOnly?: boolean
  type?: 'text' | 'password'
}

interface FormFieldWithCustomFieldProps {
  children: React.ReactNode
  name?: string
  large?: never
  value?: never
  onChange?: never
  disabled?: never
  readOnly?: never
  type?: never
}

type FormFieldProps = FormFieldDefaultProps | FormFieldWithCustomFieldProps

const FormField: React.FC<FormFieldProps> = ({
  name,
  large,
  value,
  onChange,
  disabled,
  readOnly,
  type = 'text',
  children,
}) => {
  const onChangeHandle = (
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    onChange && onChange(event.target.value)
  }

  return (
    <div className={saveFormField}>
      <label htmlFor={name}>{name}</label>
      {children ? (
        children
      ) : large ? (
        <textarea
          name={name}
          value={value}
          onChange={onChangeHandle}
          disabled={disabled}
          readOnly={readOnly}
        />
      ) : (
        <input
          name={name}
          type={type}
          value={value}
          onChange={onChangeHandle}
          disabled={disabled}
          readOnly={readOnly}
        />
      )}
    </div>
  )
}

export default connect(mapStateToProps)(SaveJourneyAdminOnly)
