import { createSelector } from 'reselect'
import { NAME } from './page.types'

export const selectPage = state => state[NAME.toLowerCase()]

export const selectPageLoading = createSelector(
  [selectPage],
  page => page.isLoading
)

export const selectPages = createSelector([selectPage], page => page.list)
