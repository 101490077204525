import { all, put, takeLatest } from 'redux-saga/effects'
import type from './artwork.types'
import Service from '../../../services'

const Ser = Service()
const apiPost = new Ser.API_POST()
const apiGet = new Ser.API_GET()

export function* tryArtworkList(action) {
  try {
    yield put({ type: type.TRY })
    const { body, top, skip } = action.payload
    const response = yield apiPost.getArtworks(body, top, skip)
    if (!response.ok) throw new Error(response.problem)

    const payload = yield response.data
    //yield sleep(100)
    yield put({ type: type.LIST_SUCCESS, payload })
    // process finished
  } catch (error) {
    yield put({
      type: type.FAILED,
      payload: {
        name: type.LIST_SUCCESS,
        error: error.message,
      },
    })
  }
}

export function* watchArtworkList() {
  yield takeLatest(type.LIST_SUCCESS_REQUESTED, tryArtworkList)
}

export function* tryArtworkListAddPage(action) {
  try {
    yield put({ type: type.TRY })
    const { body, top, skip } = action.payload
    const response = yield apiPost.getArtworks(body, top, skip)
    if (!response.ok) throw new Error(response.problem)

    const payload = yield response.data
    yield put({ type: type.LIST_ADD_PAGE, payload })
    // process finished
  } catch (error) {
    yield put({
      type: type.FAILED,
      payload: {
        name: type.LIST_ADD_PAGE,
        error: error.message,
      },
    })
  }
}

export function* watchArtworkListAddPage() {
  yield takeLatest(type.LIST_ADD_PAGE_REQUESTED, tryArtworkListAddPage)
}

export function* tryRelatedArtworks(action) {
  try {
    yield put({ type: type.TRY })
    const { id, body } = action.payload
    const response = yield apiPost.getRelatedArtworks(id, body)
    if (!response.ok) throw new Error(response.problem)

    const payload = yield response.data

    yield put({ type: type.SET_RELATED, payload })
    // process finished
  } catch (error) {
    yield put({
      type: type.FAILED,
      payload: {
        name: type.SET_RELATED,
        error: error.message,
      },
    })
  }
}

export function* watchRelatedArtworks() {
  yield takeLatest(type.SET_RELATED_REQUESTED, tryRelatedArtworks)
}

export function* trySelectedArtworks(action) {
  try {
    yield put({ type: type.TRY })
    const response = yield apiGet.getSelectedArtworks(action.payload)
    if (!response.ok) throw new Error(response.problem)

    const payload = yield response.data
    yield put({ type: type.SET_SELECTED, payload })
    // process finished
  } catch (error) {
    yield put({
      type: type.FAILED,
      payload: {
        name: type.SET_SELECTED,
        error: error.message,
      },
    })
  }
}

export function* watchSelectedArtworks() {
  yield takeLatest(type.SET_SELECTED_REQUESTED, trySelectedArtworks)
}

export function* artworkSagas() {
  yield all([
    //call(tryArtworkList),
    //call(tryRelatedArtworks),
    watchRelatedArtworks(),
    watchArtworkList(),
    watchSelectedArtworks(),
    watchArtworkListAddPage(),
  ])
}
