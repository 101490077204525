import type from './page.types'

export const setPage = (key = 'home') => ({
  type: type.PAGE_SUCCESS_REQUESTED,
  payload: key,
})

export const pageFailed = error => ({
  type: type.FAILED,
  payload: error,
})
