import type from './journey.types'

export const setJourneyList = journeyList => ({
  type: type.LIST_SUCCESS_REQUESTED,
  payload: journeyList,
})

export const journeyFailed = error => ({
  type: type.FAILED,
  payload: error,
})
