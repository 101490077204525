import React, {
  forwardRef,
  useEffect,
  useMemo,
  useCallback,
  useState,
} from 'react'
import { ForceGraph3D } from 'react-force-graph'
import * as THREE from 'three'
import { Interaction } from 'three.interaction'
//import { InteractionManager } from 'three.interactive'
import SpriteText from 'three-spritetext'
import { renderToString } from 'react-dom/server'
import { groupProperties, debounce } from './graph.utils'
import './graph.css'
import Modal from '../modal/modal.component'
import { replaceImageName } from '../inmersiveView/inmersiveView.utils'

import { getSelectedFilters } from '../filters/filters.utils'

const Graph = React.memo(
  forwardRef((props, ref) => {
    const {
      data,
      current,
      isMobile,
      handleNodeClick,
      handleNodeHover,
      filtersList,
      handleOnEngineStop = () => {},
    } = props
    //let nodeHovered = null
    //let nodeClicked = false
    const [dimensions, setDimensions] = useState({
      height: window.innerHeight,
      width: window.innerWidth,
    })
    let isMounted = true

    useEffect(() => {
      if (isMounted) {
        isMounted = false
        //if (ref?.current) ref.current.pauseAnimation()
        window.addEventListener('resize', debouncedHandleResize)
      }
      return () => {
        window.removeEventListener('resize', debouncedHandleResize)
        isMounted = false
      }
    }, [])

    const debouncedHandleResize = debounce(function handleResize() {
      setDimensions({
        height: window.innerHeight,
        width: window.innerWidth,
      })
    }, 1000)

    const ele = data =>
      renderToString(
        <Modal
          onlyImage
          style={{ opacity: 1 }}
          showAddCustomJourneyButton
          node={data}
        />
      )

    const selectData = useCallback(
      (_data, _current, _filtersSelected) => {
        /*
         * Excluding current data and merge all data, putting first the current element
         */
        const isFiltered = groupProperties(_data, _current, _filtersSelected)
        const selected = isFiltered.selected
        //console.log('gData_', _data, _filtersSelected)
        //console.log('selected', isFiltered)

        return {
          nodes: selected.data.map((node, id) => ({
            id: node.id,
            value: id,
            name: node.isParent || id === 0 || isMobile ? null : ele(node),
            //name: null,
            img: replaceImageName(node.image_url),
            group: node.target,
            isParent: node.isParent,
            h: node.height,
            w: node.width,
            groupsNumber:
              selected.counts.find(gr => gr.target === node.id) || null,
            label: node?.tag
              ? isMobile
                ? node.tag
                    .replace(/^(.{10}[^\s]*).*/, '$1')
                    .replace(/&/g, 'and')
                : node.tag.replace(/^(.{20}[^\s]*).*/, '$1')
              : null,
            ...node,
          })),
          links: selected.data
            .filter(ele => ele.id !== current.id)
            .map((ele, id) => ({
              tag: ele.tag || null,
              source: ele.id,
              target: ele.target,
              isParent: ele.isParent,
              groupsNumber: selected.counts.find(gr => gr.target === ele.id),
              color: '#878787',
              value: id,
            })),
        }
      },
      [current]
    )

    const zoom = node => {
      // Aim at node from outside it
      const distance = 70
      const distRatio = 1 + distance / Math.hypot(node.x, node.y, node.z)

      ref.current.cameraPosition(
        { x: node.x * distRatio, y: node.y * distRatio, z: node.z * distRatio }, // new position
        node, // lookAt ({ x, y, z })
        3000 // ms transition duration
      )
    }

    const gData = useMemo(() => {
      return selectData(data, current, getSelectedFilters(filtersList))
    }, [current, data, selectData, filtersList])

    return (
      <>
        <ForceGraph3D
          ref={ref}
          graphData={gData}
          //graphData={curretData}
          //nodeRelSize={10}
          showNavInfo={false}
          nodeVisibility={showing => showing.id}
          onNodeClick={node => {
            handleNodeClick(node)
            //nodeClicked = true
          }}
          onNodeHover={node => {
            //console.log('nodehover', node)
            //handleNodeHover(node)
          }}
          height={
            (dimensions.height || window.innerHeight) - (isMobile ? 100 : 170)
          }
          //height={dimensions.height}
          width={dimensions.width || '`100vw`'}
          //nodeCanvasObject={paintRing}
          //nodeThreeObjectExtend={paintRing}
          //extraRenderers={extraRenderers}
          onNodeRightClick={zoom}
          backgroundColor='#ECECEC'
          //nodeOpacity={0.5}
          linkOpacity={0.6}
          enableNodeDrag={true}
          onNodeDragEnd={node => {
            node.fx = node.x
            node.fy = node.y
            node.fz = node.z
          }}
          linkAutoColorBy={d => d.color}
          dagMode='radialin'
          autoPauseRedraw={true}
          //linkDirectionalParticles={100}
          // dagMode='zout'
          numDimensions={3}
          //warmUpTicks={100}
          //cooldownTicks={isMobile ? 35 : 100}
          cooldownTicks={100}
          dagLevelDistance={70}
          onEngineStop={handleOnEngineStop}
          //linkDirectionalParticles={true}
          linkThreeObjectExtend={true}
          linkThreeObject={link => {
            // extend link with text sprite

            let sprite = new SpriteText(``)

            if (link.isParent && !link?.groupsNumber?.length) {
              sprite = new SpriteText(link.tag.toUpperCase())

              sprite.textHeight = 1.5
              sprite.backgroundColor = 'white'
              sprite.color = 'black'
              sprite.padding = 1.5
            }
            return sprite
          }}
          linkDirectionalParticleSpeed={0.5}
          linkDirectionalArrowRelPos={0.8}
          linkPositionUpdate={(sprite, { start, end }) => {
            const middlePos = Object.assign(
              ...['x', 'y', 'z'].map(c => ({
                [c]: start[c] + (end[c] - start[c]) / 2, // calc middle point
              }))
            )

            // Position sprite
            Object.assign(sprite.position, middlePos)
          }}
          //nodeThreeObjectExtend={true}

          nodeThreeObject={node => {
            const scene = new THREE.Scene()
            const group = new THREE.Group()

            const imgTexture = new THREE.TextureLoader().load(
              `${
                current.id === node.id
                  ? replaceImageName(node.img, 'mid')
                  : replaceImageName(node.img)
              }`,
              () => {
                if (current.id === node.id) {
                  sprite.scale.set(
                    100 * (imgTexture.image.width / imgTexture.image.height),
                    100
                  )
                } else {
                  sprite.scale.set(
                    14 * (imgTexture.image.width / imgTexture.image.height),
                    14
                  )
                }
              }
            )

            const material = new THREE.SpriteMaterial({
              map: imgTexture,
              //transparent: true,
              //color: 0xffffff,
              //sizeAttenuation: false,
              //premultipliedAlpha: true,
              //useScreenCoordinates: false,
              //dithering: false,
            })

            material.needsUpdate = true
            material.precision = 'highp'
            material.depthWrite = false
            //material.color.set(0x0066cc)

            const sprite = new THREE.Sprite(material)

            if (
              node.isParent &&
              node.id !== current.id &&
              node.groupsNumber &&
              node.label
            ) {
              if (node.label[node.label.length - 1] === ',')
                node.label = node.label.slice(0, -1)

              //console.log(node.label, node.groupsNumber.length)
              const text = `${node.label} (${node.groupsNumber.length})`
              const spriteText = new SpriteText(text)
              const ratio = window?.devicePixelRatio || 1 * 2
              spriteText.textHeight = Math.floor(ratio * 0.7)
              spriteText.color = 'black'
              spriteText.padding = [7, 3]
              spriteText.fontSize = 300
              spriteText.fontFace = isMobile ? 'Arial' : 'geometria'
              spriteText.borderRadius = 2.5
              spriteText.backgroundColor = 'white'
              group.add(spriteText)
            } else {
              group.add(sprite)
            }

            scene.add(group)

            if (!node.isParent && current.id !== node.id && ref?.current) {
              const scaleFactor = 1.7
              const renderer = ref.current.renderer()
              const camera = ref.current.camera()
              //const interactionManager = new InteractionManager(
              //renderer,
              //camera,
              //renderer.domElement
              //)
              //interactionManager.add(group)
              //console.log('interactionManager', interactionManager)
              //group.addEventListener('mouseover', event => {
              //console.log('hola')
              //event.target.scale.x *= scaleFactor
              //})
              const interaction = new Interaction(renderer, scene, camera)
              group.on('mouseover', function (ev) {
                this.scale.x *= scaleFactor
                this.scale.y *= scaleFactor
              })
              group.on('mouseout', function (ev) {
                this.scale.x /= scaleFactor
                this.scale.y /= scaleFactor
              })
            }

            return scene
          }}
          //nodeThreeObjectExtend={true}
          //
        />
      </>
    )
  }),
  (prevProps, nextProps) => {
    return (
      prevProps?.current?.id === nextProps?.current?.id &&
      prevProps.isFilterChanged === nextProps.isFilterChanged &&
      prevProps.isMobile === nextProps.isMobile &&
      prevProps.show === nextProps.show
    )
  }
)

export default Graph
