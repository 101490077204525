import { useState, MouseEvent } from 'react'
import controlStyles from './control.styles.module.scss'

interface ICurrentProps {
  name?: string | null
  showLabel?: boolean
  socialIcon?: boolean
  icon?: string
  onClick?: any
  disabled?: boolean
  hidden?: boolean
  withBorder?: boolean
  className?: string
  title?: string
}

const Control: React.FC<ICurrentProps> = ({
  name,
  icon = 'icon-add',
  socialIcon,
  showLabel,
  onClick,
  disabled,
  hidden,
  withBorder,
  className = '',
  title = '',
}) => {
  const [isActive, setIsActive] = useState(false)
  const handleMouse = (e: MouseEvent<HTMLButtonElement>) => {
    e.preventDefault()
    if (e.type === 'mouseover') {
      setIsActive(true)
    } else {
      setIsActive(false)
    }
  }
  return (
    <div
      className={`${controlStyles.wrapper} ${className} ${
        socialIcon ? controlStyles.socialIconWrapper : ''
      }`}
    >
      {showLabel && (
        <label className={isActive ? controlStyles.active : ''}>{name}</label>
      )}
      <button
        onMouseOver={handleMouse}
        onMouseOut={handleMouse}
        onClick={(e: MouseEvent<HTMLButtonElement>) => {
          e.preventDefault()
          onClick && onClick(name, e)
        }}
        className={`${icon} ${disabled ? controlStyles.disabled : ''}  ${
          hidden ? controlStyles.hidden : ''
        } ${withBorder ? controlStyles.border : ''} ${
          socialIcon ? controlStyles.socialIcon : ''
        } `}
        disabled={disabled}
        title={title}
      />
    </div>
  )
}

export default Control
