import { loading, logo, homeBG as image } from '../../assets'
import loadingStyles from './loading.styles.module.scss'
import React, { forwardRef } from 'react'
import { replaceImageName } from '../inmersiveView/inmersiveView.utils'

interface ICurrentProps {
  height?: string
  showLogo?: boolean
  small?: boolean
  showBackground?: boolean
  showMicrosoftMessage?: boolean
  preview?: any
  backgroundColor?: string
  style?: object
}

const Loading: React.ComponentType<ICurrentProps> = React.memo(
  forwardRef(
    (
      {
        height = 'auto',
        showLogo,
        small,
        showBackground,
        showMicrosoftMessage,
        preview,
        backgroundColor = '#ececec',
        style = {},
      }: ICurrentProps,
      ref?: React.Ref<HTMLDivElement>
    ) => {
      const handleLoad = () => {
        const currentImage = document.querySelector<HTMLElement>(
          `.${loadingStyles.preview}`
        ) || { style: { opacity: 0, transform: '' } }

        if (currentImage) {
          currentImage.style.opacity = 1
        }
      }
      const prev = preview?.img || preview?.image_url

      return (
        <div
          className={loadingStyles.loading}
          ref={ref}
          style={{
            backgroundColor: backgroundColor,
            height: height,
            backgroundImage: showBackground ? `url(${image})` : ``,
            backgroundSize: 'cover',
            ...style,
          }}
        >
          {/*blocking interactions*/}
          <button className={loadingStyles.btnWrapper} disabled>
            {showLogo && (
              <img
                alt='INTERWOVEN'
                className={loadingStyles.logo}
                src={logo}
                width={!small ? 380 : 180}
              />
            )}

            {prev && (
              <img
                alt='Loading site...'
                onLoad={handleLoad}
                className={loadingStyles.preview}
                src={replaceImageName(prev)}
              />
            )}

            <div className={loadingStyles.wrapper}>
              <img alt='Loading site...' src={loading} height={20} />
              Loading...
            </div>

            {showMicrosoftMessage && (
              <p>
                Microsoft AI is sourcing MAP and partner databases to set up
                your journey
              </p>
            )}
          </button>
        </div>
      )
    }
  ),

  (prevProps, nextProps) => {
    const validation =
      prevProps.preview?.img === nextProps.preview?.img &&
      prevProps.preview?.image_url === nextProps.preview?.image_url

    return validation
  }
)

export default Loading
