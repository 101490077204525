import { ArtworkProvider } from './artworkContext.context'
import { FilterProvider } from './artworkFilters.context'
import { FiltersStateProvider } from './artworkFilstersState.context'

interface Props {
  children: JSX.Element[] | JSX.Element
}
const GeneralContext: React.FC<Props> = ({ children }) => {
  return (
    <ArtworkProvider>
      <FilterProvider>
        <FiltersStateProvider>{children}</FiltersStateProvider>
      </FilterProvider>
    </ArtworkProvider>
  )
}

export default GeneralContext
